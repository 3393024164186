import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../loader/Loader";
import AxiosController from "../../authentication/AxiosController";
import { Button, Pagination, Table } from "react-bootstrap";
import IconsGen from "../../icons_generator/IconsGen";
import FinanceSearchComponent from "./FinanceSearchComponent";
import PaginationComponent from "../../reuseable/PaginationComponent";

const FinanceSuppliers = () => {
  const { slug, lang } = useParams();
  const { t: _ } = useTranslation("global");
  const box_head_dir = lang === "en" ? "ltr" : "rtl";
  const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState([]);
  const [search, setSearch] = useState(false);
  const token = localStorage.getItem("token");
  const location = useLocation();
  const navigate = useNavigate();

  const pageFromUrl = new URLSearchParams(location.search).get("page") || 1;
  const [page, setPage] = useState(Number(pageFromUrl));

  const changeParam = (param, value) => {
    const urlParams = new URLSearchParams(location.search);
    urlParams.set(param, value);
    setPage(value);
    navigate(`?${urlParams.toString()}`);
  };
  const handleSearch = (searchParams) => {
    const urlParams = new URLSearchParams(location.search);
    Object.entries(searchParams).forEach(([key, value]) => {
      if (value) {
        urlParams.set(key, value);
      } else {
        urlParams.delete(key);
      }
    });
    urlParams.set("page", 1); // Reset to the first page on new search
    navigate(`?${urlParams.toString()}`);
  };

  const get_suppliers_data = useCallback(async () => {
    setLoading(true);
    try {
      const urlParams = new URLSearchParams(location.search);
      const params = Object.fromEntries(urlParams.entries()); // Pass URL search params
      const res = await AxiosController.get(`api/${slug}/${lang}/suppliers`, {
        headers: { Authorization: `Bearer ${token}` },
        params,
      });
      setAllData(res?.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [lang, slug, token, location.search]);

  useEffect(() => {
    get_suppliers_data();
  }, [get_suppliers_data]);
// console.log('-----------------------------------',allData)
  return (
    <div className="flex-grow-1 stats-component">
      <Helmet>
        <title>{_("panel.finance_suppliers")}</title>
      </Helmet>

      <div className="reports-container my-3 d-flex flex-column gap-2 px-2 position-relative">
        <Loader active={loading} />
        <Button
          className="fs-5 px-5 w-25 align-self-start"
          style={{
            backgroundColor: "rgb(89, 198, 201)",
            border: "none",
            borderRadius: "7px",
          }}
          onClick={() => setSearch(!search)}
        >
          {_("panel.search")}
        </Button>
        {search && <FinanceSearchComponent onSearch={handleSearch} />}

        <div className="reports-header d-flex flex-column flex-md-row py-2 justify-content-between flex-wrap">
          <h3 className="fs-2 mb-4" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.finance_suppliers")}
          </h3>
        </div>

        <div className="row" dir={box_head_dir}>
          <div
            className="col-12"
            style={{ position: "relative", overflowX: "auto", height: "auto" }}
          >
            <Table
              responsive
              dir={box_head_dir}
              className="reports-table text-nowrap text-center"
            >
              <thead>
                <tr className="text-center">
                  <th>#</th>
                  <th>{_("panel.date")}</th>
                  <th>{_("panel.name")}</th>
                  <th>{_("panel.quantity")}</th>
                  <th>{_("panel.total_price")}</th>
                </tr>
              </thead>
              <tbody>
                {allData && allData.data ? (
                  allData.data.map((item, index) => (
                    <tr key={index + 1} className="text-center">
                      <td>{index + 1}</td>
                      <td>{item.created_at_formatted}</td>

                      <td>
                        #{item.id} {item.name}
                      </td>
                      <td>{item.total_quantity}</td>
                      <td>{item.total_purchases}</td>
                      <td
                        onClick={() =>
                          navigate(
                            `/${slug}/${lang}/panel/financials/supplier-details/${item.id}`
                          )
                        }
                      >
                        <IconsGen icon_name={"enter"} />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8" className="text-center">
                      {_("home.no_data")}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          <PaginationComponent currentPage={page} totalPages={allData?.last_page} links={allData.links} onPageChange={(newPage) => changeParam("page", newPage)}  />
          {/* {allData["links"] && (
            <div dir={lang === "ar" ? "ltr" : "rtl"} className={`d-flex`}>
              <Pagination className="m-auto flex-wrap" dir="ltr">
                <Pagination.First onClick={() => changeParam("page", 1)} />
                <Pagination.Prev
                  onClick={() => page > 1 && changeParam("page", page - 1)}
                />
                {allData["links"].map((element, index, arr) => {
                  if (
                    index !== 0 &&
                    index !== arr.length - 1 &&
                    element["label"] !== "..."
                  ) {
                    return (
                      <Pagination.Item
                        key={index}
                        active={element["active"]}
                        onClick={() =>
                          changeParam("page", parseInt(element["label"], 10))
                        }
                      >
                        {element["label"]}
                      </Pagination.Item>
                    );
                  }
                  return null;
                })}
                <Pagination.Next
                  onClick={() =>
                    page < allData["last_page"] && changeParam("page", page + 1)
                  }
                />
                <Pagination.Last
                  onClick={() => changeParam("page", allData["last_page"])}
                />
              </Pagination>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default FinanceSuppliers;
