import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SuppliersTableRow from "../SuppliersTableRow";
import AxiosController from "../../../authentication/AxiosController";
import Loader from "../../../loader/Loader";

const get_warehouses = (slug, lang, token, handleWarehouses, handleLoader) => {
  AxiosController.get(`api/${slug}/${lang}/get-secrets/`, {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      type: "yalla_shop",
    },
  })
    .then((res) => {
      handleWarehouses(res.data);  
      handleLoader(false);
    })
};

const YallaShopKeys = () => {
  const { slug, lang } = useParams();  

  const token = localStorage.getItem("token"); 
  const [_] = useTranslation("global"); 
  const [suppliers, handleSuppliers] = useState([]); 
  const [loading, handleLoader] = useState(false);    

  useEffect(() => {
    get_warehouses(slug, lang, token, handleSuppliers, handleLoader);
  }, [slug, lang]);

  return (
    <div
      className="position-relative tables-container"
      style={{ minHeight: "100px", width: "100%" }}
    >
      <Loader active={loading} />  {/* Show loader while data is being fetched */}
      <div
        className="tables-container d-flex flex-column px-2"
        style={{ position: "relative", flexGrow: 3 }}
      >
        <div className="footer d-flex flex-column flex-grow-1">
          {suppliers ? (
            <SuppliersTableRow
              payments_list={suppliers}  
              handleLoader={handleLoader} 
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default YallaShopKeys;
