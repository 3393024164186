import React from 'react';
import {  Row, Col, Card, Button, Badge } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend, Title } from 'chart.js';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend, Title);

const LandingPageOverview = () => {

  const {lang } = useParams();  
  const [_] = useTranslation("global");

  // Mock data for the line chart
  const lineChartData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
      {
        label: 'Sales',
        data: [1200, 1900, 3000, 5000, 2400, 3500, 4200, 3000, 4900, 5300, 7000, 8000],
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        fill: true,
      },
    ],
  };

  const lineChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Sales Over Time',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div
    dir={lang === "en" ? "ltr" : "rtl"}
      className="d-flex flex-column flex-lg-column my-2 gap-3 position-relative"
      style={{ width: "100%" }}
    >
      <Helmet>
        <title>{_("panel.landing_page_overview")}</title>
      </Helmet>
      <div
        className={`header d-flex ${
          lang === "en" ? "flex-row" : "flex-row"
        } gap-3 align-items-center justify-content-between`}
      >
        <h3 className="fs-1" style={{ color: "rgba(89, 198, 201, 1)" }}>
          {_("panel.landing_page_overview")}
        </h3>
 
      </div>
      <Row className="mb-4">
        <Col className='gy-3' xs="auto">
          <Button variant="outline-secondary">{_("panel.last_30_days")}</Button>
        </Col>
        <Col className='gy-3' xs="auto">
          <Button variant="outline-secondary">{_("panel.all_channels")}</Button>
        </Col>
        <Col className='gy-3' xs="auto">
          <Badge className='p-2' pill bg="success">
            {_("panel.live_visitors", { count: 3 })}
          </Badge>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col className='gy-3' lg={3}>
          <Card>
            <Card.Body>
              <Card.Title>{_("panel.online_store_sessions")}</Card.Title>
              <Card.Text><strong>2,009</strong></Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col className='gy-3' lg={3}>
          <Card>
            <Card.Body>
              <Card.Title>{_("panel.total_sales")}</Card.Title>
              <Card.Text><strong>AED 2,996.98</strong></Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col className='gy-3' lg={3}>
          <Card>
            <Card.Body>
              <Card.Title>{_("panel.total_orders")}</Card.Title>
              <Card.Text><strong>11</strong></Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col className='gy-3' lg={3}>
          <Card>
            <Card.Body>
              <Card.Title>{_("panel.conversion_rate")}</Card.Title>
              <Card.Text><strong>0.5%</strong></Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col className='gy-3'>
          <Card>
            <Card.Body>
              <Card.Title>{_("panel.sales_over_time")}</Card.Title>
              <Line data={lineChartData} options={lineChartOptions} />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col className='gy-3' lg={6}>
          <Button variant="outline-secondary" className="w-100">
            {_("panel.orders_to_fulfill", { count: 4 })}
          </Button>
        </Col>
        <Col className='gy-3' lg={6}>
          <Button variant="outline-secondary" className="w-100">
            {_("panel.payments_to_capture", { count: 11 })}
          </Button>
        </Col>
      </Row>

      <Row>
        <Col className='gy-3'>
          <Card>
            <Card.Body>
              <Card.Title>{_("panel.discover_more_ecombo ")}</Card.Title>
              <p>{_("panel.browse_features")}</p>
              <Button className='border-0 py-2' variant="primary">{_("panel.explore_now")}</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default LandingPageOverview;
