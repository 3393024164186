import React, { useEffect, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../../loader/Loader";
import AxiosController from "../../../authentication/AxiosController";
import { format } from "date-fns";
import DateRangePickerComponent from "../../../date/DateRangePickerComponent";

const get_warehouses = (slug, lang, token, handleWarehouses) => {
  AxiosController.get(`api/${slug}/${lang}/get-warehouses/`, {
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      handleWarehouses(res.data);
    })
    .catch((e) => {});
};
const get_suppliers = (slug, lang, token, handleWarehouses) => {
  AxiosController.get(`api/${slug}/${lang}/get-suppliers/`, {
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      handleWarehouses(res.data);
    })
    .catch((e) => {});
};
const SearchComponent = () => {
  const [_] = useTranslation("global");
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");
  const location = useLocation();
  const Navigate = useNavigate();

  const urlParams = new URLSearchParams(location.search);

  const [productCode, setProductCode] = useState("");
  const [sku, setSku] = useState("");
  const [supplier, setSupplier] = useState("");
  const [warehouse, setWarehouse] = useState("");
  const [status, setStatus] = useState("");
  const [isCash, setIsCash] = useState(0);
  const [startDate, handleStartDate] = useState("");
  const [endDate, handleEndDate] = useState("");
  const [loading, handleLoader] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
 const [state, setState] = useState({
    selection: {
      startDate: new Date(),
      endDate:new Date(),
      key: 'selection'
    }
  });
  const resetHandler = () => {
    setProductCode("");
    setSku("");
    setWarehouse("");
    setStatus("");
    setIsCash("");
    handleLoader("");
    setSuppliers([]);
    setWarehouses([]);
    setSupplier("");
    Navigate("");
    get_suppliers(slug, lang, token, setSuppliers);
    get_warehouses(slug, lang, token, setWarehouses);
  };
  const changeParam = () => {
    urlParams.set("product_code", productCode);
    urlParams.set("sku", sku);
    urlParams.set("supplier", supplier);
    urlParams.set("warehouse", warehouse);
    urlParams.set("status", status);
    urlParams.set("is_cash", isCash);
    urlParams.set("start_date", startDate);
    urlParams.set("end_date", endDate);
    Navigate(`?${urlParams.toString()}`);
  };

  const handleApply = (event, picker) => {
    const { startDate, endDate } = state.selection;
    const formattedStartDate = new Date(startDate.setHours(0, 0, 0, 0));
    const formattedEndDate = new Date(endDate.setHours(0, 0, 0, 0));

   
    const apiStartDate = format(formattedStartDate, "yyyy-MM-dd");
    const apiEndDate = format(formattedEndDate, "yyyy-MM-dd");
    handleStartDate(apiStartDate);
    handleEndDate(apiEndDate);
   
    // const startDate = picker.startDate.format("YYYY-MM-DD");
    // const endDate = picker.endDate.format("YYYY-MM-DD");
    // handleStartDate(startDate);
    // handleEndDate(endDate);
  };
  useEffect(() => {
    handleLoader(true);
    get_suppliers(slug, lang, token, setSuppliers);
    get_warehouses(slug, lang, token, setWarehouses);
    handleLoader(false);
  }, []);
  return (
    <div className="w-100 d-flex flex-column gap-1 mt-2 position-relative gap-3 ">
      <Loader active={loading} />
      <div className="inputs-row d-flex w-100 gap-2 flex-column  flex-md-row">
        <Col>
          <Form.Group
            className=" flex-grow-1"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Control
              type="text"
              placeholder={_("panel.code")}
              onInput={(e) => {
                setProductCode(e.target.value);
              }}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group
            className=" flex-grow-1"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Control
              type="text"
              placeholder={"SKU"}
              onInput={(e) => {
                setSku(e.target.value);
              }}
            />
          </Form.Group>
        </Col>
      </div>
      <div className="inputs-row d-flex w-100 gap-2 flex-column  flex-md-row">
        <Col>
          <Form.Group className=" flex-grow-1">
            <Form.Select
              onChange={(e) => {
                setSupplier(e.target.value);
              }}
              value={supplier}
            >
              <option value={""} disabled>
                الموردين
              </option>
              {suppliers.map((supplier) => {
                return <option value={supplier.id}>{supplier.name}</option>;
              })}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className=" flex-grow-1">
            <Form.Select
              onChange={(e) => {
                setIsCash(e.target.value);
              }}
              value={isCash}
            >
              <option value={0} disabled>
                طريقة الدفع
              </option>
              <option value={1}>{_("panel.cash")}</option>
              <option value={-1}>{_("panel.postponed")}</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className=" flex-grow-1">
            <Form.Select
              onChange={(e) => {
                setWarehouse(e.target.value);
              }}
              value={warehouse}
            >
              <option value={""} disabled>
                المخازن
              </option>
              {warehouses.map((warehouse) => {
                return (
                  <option selected={false} value={warehouse.id}>
                    {warehouse.title}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        </Col>
      </div>
      <div className="inputs-row d-flex w-100 gap-2 flex-column  flex-md-row">
        <Col>
          <Form.Group className=" flex-grow-1">
            <Form.Select
              onChange={(e) => {
                setStatus(e.target.value);
              }}
              value={status}
            >
              <option value={""} disabled>
                الحالة
              </option>
              <option value={"new"}>{_("panel.new")}</option>
              <option value={"sell"}>{_("panel.sell")}</option>
              <option value={"damaged"}>{_("panel.damaged")}</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group
            className=" flex-grow-1"
            controlId="exampleForm.ControlInput1"
          >
             <DateRangePickerComponent
                  state={state}
                  setState={setState}
                  applyDateRange={() => handleApply()}
                />
            {/* <DateRangePicker onApply={handleApply}>
              <input type="text" className="form-control" />
            </DateRangePicker> */}
          </Form.Group>
        </Col>
      </div>
      <div className="action-inputs">
        <input
          className=" px-5 align-self-end py-2"
          style={{
            backgroundColor: "rgb(243, 168, 55)",
            border: "none",
            borderRadius: "7px",
            color: "white",
            textAlign: "center",
          }}
          onClick={() => {
            changeParam();
          }}
          type="submit"
          value={_("panel.search")}
        />
        <Button
          style={{ backgroundColor: "rgb(23, 43, 77)" }}
          variant="dark"
          className="d-inline"
          onClick={resetHandler}
        >
          {_("panel.reset")}
        </Button>
      </div>
    </div>
  );
};

export default SearchComponent;
