import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Loader from "../../../loader/Loader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AxiosController from "../../../authentication/AxiosController";
import StatisticsSearchComponent from "./search components/StatisticsSearchComponent";
import { Button, Table } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { format } from "date-fns";
import DateRangePickerComponent from "../../../date/DateRangePickerComponent";

const MostFrequentBuyers = () => {
  const { slug, lang } = useParams();

  const [_] = useTranslation("global");
  const box_head_dir = lang === "en" ? "ltr" : "rtl";
  const token = localStorage.getItem("token");
  const [loading, handleLoader] = useState(false);
  const [data, handleData] = useState([]);
  // const location = useLocation();
  // const urlParams = new URLSearchParams(location.search);
  // const [search, handleSearch] = useState(false);
  // const [marketerId, setMarketerId] = useState("");
  // const [courierId, setCourierId] = useState("");
  // const [productId, setProductId] = useState("");
  // const [startDate, setStartDate] = useState("");
  // const [endDate, setEndDate] = useState("");

  // useEffect(() => {
  //   setMarketerId(urlParams.get("marketer_id") || "");
  //   setCourierId(urlParams.get("courier_id") || "");
  //   setProductId(urlParams.get("product_id") || "");
  //   setStartDate(urlParams.get("start_date") || "");
  //   setEndDate(urlParams.get("end_date") || "");
  // }, [urlParams]);

  const get_most_frequent_buyers = (startDate, endDate) => {
    handleLoader(true);
    AxiosController.get(`api/${slug}/${lang}/reports/most-frequent-buyers`, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        start_date: startDate,
        end_date: endDate,
        // marketer_id: marketerId,
        // courier_id: courierId,
        // product_id: productId,
      },
    })
      .then((res) => {
        handleData(res?.data.clients);
        handleLoader(false);
      })
      .catch((e) => {});
  };

  useEffect(() => {
    get_most_frequent_buyers();
  }, []);

  const [state, setState] = useState({
    selection: {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  });
  const applyDateRange = () => {
    const { startDate, endDate } = state.selection;
    const formattedStartDate = new Date(startDate.setHours(0, 0, 0, 0));
    const formattedEndDate = new Date(endDate.setHours(0, 0, 0, 0));

    // Format dates to YYYY-MM-DD for API
    const apiStartDate = format(formattedStartDate, "yyyy-MM-dd");
    const apiEndDate = format(formattedEndDate, "yyyy-MM-dd");

    get_most_frequent_buyers(apiStartDate, apiEndDate);
  };

  return (
    <div className="flex-grow-1 stats-component">
      <Helmet>
        <title>{_("panel.mostFrequentBuyers")}</title>
      </Helmet>

      <div className="reports-container my-3 d-flex flex-column gap-2 px-2 position-relative ">
        <Loader active={loading} />
        <div className="overflow-auto reports-header d-flex flex-column flex-md-row py-2 justify-content-between flex-wrap">
          <h3 className="fs-2" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.mostFrequentBuyers")}
          </h3>
          <DateRangePickerComponent
            state={state}
            setState={setState}
            applyDateRange={() => applyDateRange()}
          />
        </div>

        <div className="row" dir={box_head_dir} style={{ height: "1200px" }}>
          <div
            className="col-12  gy-4 mt-4   "
            style={{
              position: "relative",
              overflowY: "auto",
              height: "100%",
            }}
          >
            <div className="container  ">
              <Table responsive dir={box_head_dir}>
                <thead>
                  <tr className="text-center">
                    <th
                      scope="col"
                      style={{ fontSize: "16px", padding: "10px" }}
                    >
                      #
                    </th>
                    <th
                      scope="col"
                      style={{ fontSize: "16px", padding: "10px" }}
                    >
                      {_("panel.customer_name")}
                    </th>
                    <th
                      scope="col"
                      style={{ fontSize: "16px", padding: "10px" }}
                    >
                      {_("panel.phone")}
                    </th>

                    <th
                      scope="col"
                      style={{ fontSize: "16px", padding: "10px" }}
                    >
                      {_("panel.marketers")}
                    </th>

                    {/* <th
                          scope="col"
                          style={{ fontSize: "16px", padding: "10px" }}
                          
                        >
                           
                          {_("panel.products")} 
                        </th> */}
                    <th
                      scope="col"
                      style={{ fontSize: "16px", padding: "10px" }}
                    >
                      {_("panel.total_price")}
                    </th>
                    <th
                      scope="col"
                      style={{ fontSize: "16px", padding: "10px" }}
                    >
                      {_("panel.total_delivered")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 &&
                    data?.map((item, index) => (
                      <tr key={index} className="text-center">
                        <th
                          style={{ fontSize: "16px", padding: "10px" }}
                          scope="row"
                        >
                          {index + 1}
                        </th>{" "}
                        <td style={{ fontSize: "16px", padding: "10px" }}>
                          {item?.customer_name}
                        </td>
                        <td style={{ fontSize: "16px", padding: "10px" }}>
                          {item?.phone_1}
                        </td>
                        <td style={{ fontSize: "16px" }}>
                          <ul>
                            {item.marketers.map((marketer, mIndex) => (
                              <p key={mIndex} className="mb-4">
                                <span className="fw-bold ">
                                  # {marketer.marketer_id}{" "}
                                </span>
                                <br />
                                {marketer.first_name} {marketer.last_name}
                              </p>
                            ))}
                          </ul>
                        </td>
                        {/* <td style={{ fontSize: "16px", padding: "10px" }}>
                              <ul>
                                {item?.products.map((product, idx) => (
                                  <p key={idx}  className="mb-4"><span className="fw-bold "># {product.product_id} </span><br/>{product.title}</p>
                                ))}
                              </ul>
                            </td> */}
                        <td style={{ fontSize: "16px", padding: "10px" }}>
                          {item?.total_price.toLocaleString()}
                        </td>
                        <td style={{ fontSize: "16px", padding: "10px" }}>
                          {item?.total_delivered}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MostFrequentBuyers;
