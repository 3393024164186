import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation, useParams } from "react-router-dom";
import IconsGen from "../../../icons_generator/IconsGen";

const MyShop = ({ direction }) => {
  const [_] = useTranslation("global");
  const { slug, lang } = useParams();
  const [isSubNavVisible, setIsSubNavVisible] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes("/panel/my-shop/")) {
      setIsSubNavVisible(true);
    } else {
      setIsSubNavVisible(false);
    }
  }, [location.pathname]);
  const handleClick = () => {
    setIsSubNavVisible(!isSubNavVisible);
  };
  return (
    <div className="links-container d-flex flex-column">
      <div
        className={`feature_link ${lang === "en" ? "feature_link_en" : ""
          } d-flex flex-row-reverse align-items-center gap-2 py-3           ${isSubNavVisible ? "active-link" : ""
          }`}
        dir={direction}
        // to={`/${slug}/${lang}/panel/my-shop`}
        onClick={handleClick}
      >
        <IconsGen icon_name={"myShop"} />
        <p>{_("panel.myShop")}</p>
      </div>
      <ul
        className={` ${isSubNavVisible ? "d-flex" : "d-none"
          }  sub-nav  flex-column gap-2 px-5 pt-3`}
      >
        <li>
          <NavLink to={`/${slug}/${lang}/panel/my-shop/my-shops`}>
            <p className="d-inline ">{_("panel.myshop")}</p>
          </NavLink>
        </li>
        <li>
          <NavLink to={`/${slug}/${lang}/panel/my-shop/create-landpage`}>
            <p className="d-inline ">{_("panel.createLandpage")}</p>
          </NavLink>
        </li>
        <li>
          <NavLink to={`/${slug}/${lang}/panel/my-shop/my-landpages`}>
            <p className="d-inline ">{_("panel.myLandpages")}</p>
          </NavLink>
        </li>
        <li>
          <NavLink to={`/${slug}/${lang}/panel/my-shop/pixel`}>
            <p className="d-inline ">{_("panel.pixel")}</p>
          </NavLink>
        </li>
        <li>
          <NavLink to={`/${slug}/${lang}/panel/my-shop/landing-page/analysis`}>
            <p className="d-inline ">{_("panel.landing_page_analysis")}</p>
          </NavLink>
        </li>
        <li>
          <NavLink to={`/${slug}/${lang}/panel/my-shop/landing-page/overview`}>
            <p className="d-inline ">{_("panel.landing_page_overview")}</p>
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default MyShop;
