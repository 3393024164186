import { useEffect, useState } from "react";
import { Stepper } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import AxiosController from "../../../../authentication/AxiosController";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { Form } from "react-bootstrap";
import Loader from "../../../../loader/Loader";

function isValidSubdomain(text) {
  const regex = /^[a-z0-9]([a-z0-9-]{0,61}[a-z0-9])?$/;
  return regex.test(text);
}
export const CreateStore = () => {
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");
  const [active, setActive] = useState(1);
  const [loader, handleLoader] = useState(false);
  const [_] = useTranslation("global");
  const [storesThemes, setStoresThemes] = useState([]);
  const [userStoresTheme, setUserStoresTheme] = useState([]);
  const [userThemeId, setUserThemeId] = useState();
  const [storeDomain, handleStoreDomain] = useState("yourdomain");
  const [icon, setIcon] = useState(null);
  const [favicon, setFavicon] = useState(null);
  const [storeName, setStoreName] = useState(null);
  const [errors, setErrors] = useState({});
  const Navigate = useNavigate();
  const handleIconChange = (e) => {
    setIcon(e.target.files[0]);
  };

  const handleFaviconChange = (e) => {
    setFavicon(e.target.files[0]);
  };

  const getStoreThemes = () => {
    AxiosController.get(`apiv2/${slug}/${lang}/builder/stores`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setStoresThemes(res.data);
        handleLoader(false);
      })
      .catch((e) => {});
  };
  const getUserStoreThemes = () => {
    AxiosController.get(`apiv2/${slug}/${lang}/builder/my-stores`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        if (res?.data?.[0]) {
          setUserStoresTheme(res?.data[0]);
          setUserThemeId(res?.data[0]?.store_id);
          handleStoreDomain(res?.data[0]?.sub_domain);
          setStoreName(res.data?.[0]?.name);
        } else {
          handleStoreDomain("subdomain");
        }
        handleLoader(false);
      })
      .catch((e) => {});
  };
  const nextStep = () =>
    setActive((current) => (current < 2 ? current + 1 : current));
  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleLoader(true);

    const formData = new FormData();
    formData.append("sub_domain", storeDomain);
    formData.append("store_name", storeName);
    formData.append("store_id", userThemeId);
    if (icon) formData.append("icon", icon);
    if (favicon) formData.append("favicon", favicon);
    if (userStoresTheme) formData.append("id", userStoresTheme?.id);
    AxiosController.post(
      `apiv2/${slug}/${lang}/builder/${
        userStoresTheme != "" ? "edit-store" : "create-store"
      }`,
      formData,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        Navigate(`/${slug}/${lang}/panel/my-shop/`);
        handleLoader(false);
      })
      .catch((e) => {
        setErrors(e.response.data);
        handleLoader(false);
      });
  };
  useEffect(() => {
    setActive(0);
    getUserStoreThemes();
    getStoreThemes();
  }, []);
  return (
    <div className="position-relative">
      <Stepper active={active} onStepClick={setActive} color="#59C6C9">
        <Stepper.Step
          label={_("panel.First step")}
          description={_("panel.selectStoreTheme")}
        >
          <div className="wrapper w-100">
            <div className="title-section">
              <h2 className="text-center ecombo-secondary">
                {_("panel.Themes")}
              </h2>
            </div>
            <div className="themes-body">
              <div className="row px-3">
                {storesThemes?.map((store) => {
                  return (
                    <div className="col-12 col-md-3 mb-2">
                      <Card
                        className={`w-100 ${
                          store?.id === userStoresTheme?.store_id
                            ? "ecombo-border"
                            : ""
                        }`}
                      >
                        <Card.Img
                          variant="top"
                          className="p-2"
                          src={store?.thumbnail}
                        />
                        <Card.Body>
                          <Card.Title className="text-ecombo">
                            {store?.name}
                          </Card.Title>
                          <Button
                            className="ecombo-btn-secondary w-100 mt-2 mb-2"
                            onClick={() => {
                              setUserThemeId(store.id);
                              nextStep();
                            }}
                          >
                            {_("panel.install")}
                          </Button>
                        </Card.Body>
                      </Card>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Stepper.Step>
        <Stepper.Step
          label={_("panel.Second step")}
          description={_("panel.fillTheData")}
        >
          <Loader active={loader} />
          <div className="wrapper w-100">
            <div className="title-section">
              <h2 className="text-center ecombo-secondary">
                {_("panel.fillTheData")}
              </h2>
            </div>
            <div className="themes-body">
              <div className="row px-3">
                <Form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label fs-5"
                    >
                      {_("panel.storeDomain")}
                    </label>
                    <div className="right-head">
                      <p>{storeDomain + ".tejarty.store"}</p>
                    </div>
                    <input
                      type="text"
                      className="form-control py-2 px-3 egp"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      value={storeDomain}
                      onInput={(e) => {
                        handleStoreDomain(
                          isValidSubdomain(e.target.value) |
                            (e.target.value == "")
                            ? e.target.value
                            : "yourdomain"
                        );
                      }}
                    />
                  </div>
                  {errors.sub_domain ? (
                    <div class="alert alert-danger" role="alert">
                      {errors.sub_domain[0]}
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label fs-5"
                    >
                      {_("panel.storeName")}
                    </label>
                    <input
                      type="text"
                      className="form-control py-2 px-3 egp"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      value={storeName}
                      onInput={(e) => {
                        setStoreName(e.target.value);
                      }}
                    />
                  </div>
                  {errors.store_name ? (
                    <div class="alert alert-danger" role="alert">
                      {errors.store_name[0]}
                    </div>
                  ) : (
                    ""
                  )}

                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label className="fs-5">{_("panel.icon")}</Form.Label>
                    <Form.Control type="file" onChange={handleIconChange} />
                  </Form.Group>
                  {errors.icon ? (
                    <div class="alert alert-danger" role="alert">
                      {errors.icon[0]}
                    </div>
                  ) : (
                    ""
                  )}
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label className="fs-5">
                      {_("panel.favicon")}
                    </Form.Label>
                    <Form.Control type="file" onChange={handleFaviconChange} />
                  </Form.Group>
                  {errors.favicon ? (
                    <div class="alert alert-danger" role="alert">
                      {errors.favicon[0]}
                    </div>
                  ) : (
                    ""
                  )}
                  <Button className="ecombo-btn w-100" type="submit">
                    {_("panel.save")}
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </Stepper.Step>
      </Stepper>
    </div>
  );
};
