import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SearchComponent from "./SearchComponent";
import { Pagination } from "react-bootstrap";
import PaymentRequestsTableRow from "./PaymentRequestsTableRow";
import AxiosController from "../../../authentication/AxiosController";
import Loader from "../../../loader/Loader";
import * as XLSX from "xlsx";
import { Button } from "react-bootstrap";
import { Helmet } from "react-helmet";

const VariantsStock = () => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const token = localStorage.getItem("token");
  const [page, setPage] = useState(1); // Updated to setPage for better readability
  const [loading, handleLoader] = useState(false);
  const [data, setData] = useState([]);
  const location = useLocation();
  const Navigate = useNavigate();
  const [search, setSearch] = useState(false);
  const urlParams = new URLSearchParams(location.search);
  const [excelData, setExcelData] = useState([]);
  const fileInputRef = useRef(null);

  const changeParam = (param, value) => {
    urlParams.set(param, value);
    setPage(value); // Update the page state when changing params
    Navigate(`?${urlParams.toString()}`);
  };

  const get_products_stock = (all = false) => {
    handleLoader(true);
    const product_id = urlParams.get("product_id") || "";
    const product_name = urlParams.get("product_name") || "";
    const sku = urlParams.get("sku") || "";
    const seller = urlParams.get("seller") || "";

    AxiosController.get(`api/${slug}/${lang}/get-variants-stocks/${page}`, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        all: all ? 1 : 0,
        seller,
        product_name,
        product_id,
        sku,
      },
    })
      .then((res) => {
        if (!all) {
          setData(res.data);
        } else {
          let worksheet = XLSX.utils.json_to_sheet(
            res.data.map((order) => ({
              "كود المنتج": order["product_code"],
              SKU: order["sku"],
              "عنوان المنتج": order["product_title"],
              المتبقي: order["left"],
              "متوسط السعر": parseFloat(order["price"])
                ? parseFloat(order["price"]).toFixed(2)
                : 0,
              "اسم التاجر": `${order["seller_first_name"]} ${order["seller_last_name"]}`,
            }))
          );
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
          XLSX.writeFile(workbook, "variants-Stock.xlsx");
        }
      })
      .catch((e) => {
        console.error(e); // Log errors for easier debugging
      })
      .finally(() => {
        handleLoader(false); // Ensure loader is turned off
      });
  };

  useEffect(() => {
    const initialPage = parseInt(urlParams.get("page")) || 1;
    setPage(initialPage);
    get_products_stock();
  }, [token, slug, lang, page, location.search]);

  return (
    <div
      className="d-flex flex-column flex-lg-column my-2 gap-3 position-relative"
      style={{ width: "100%" }}
    >
      <Helmet>
        <title>{_("panel.variantsStock")}</title>
      </Helmet>
      <Button
        className="fs-5 px-5 w-25 align-self-start"
        style={{
          backgroundColor: "rgb(89, 198, 201)",
          border: "none",
          borderRadius: "7px",
        }}
        onClick={() => setSearch(!search)}
      >
        {_("panel.search")}
      </Button>
      {search ? <SearchComponent /> : ""}
      <Loader active={loading} />
      <div
        className="tables-container d-flex flex-column px-2"
        style={{ position: "relative", flexGrow: 3 }}
      >
        <div
          className={`header d-flex ${
            lang === "en" ? "flex-row" : "flex-row"
          } gap-3 align-items-center justify-content-between`}
        >
          <h3 className="fs-1" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.variantsStock")}
          </h3>
        </div>
        <div className="footer d-flex flex-column flex-grow-1">
          {data.data ? (
            <PaymentRequestsTableRow
              payments_list={data.data}
              handleLoader={handleLoader}
            />
          ) : (
            ""
          )}
        </div>
        {data["links"] && (
          <Pagination
            className="m-auto flex-wrap"
            dir={lang === "ar" ? "ltr" : "rtl"}
          >
            <Pagination.First onClick={() => changeParam("page", 1)} />
            <Pagination.Prev
              onClick={() => page > 1 && changeParam("page", page - 1)}
            />
            {data["links"].map((element, index, arr) =>
              index !== 0 &&
              index !== arr.length - 1 &&
              element["label"] !== "..." ? (
                <Pagination.Item
                  key={index}
                  active={element["active"]}
                  onClick={() =>
                    changeParam("page", parseInt(element["label"]))
                  }
                >
                  {element["label"]}
                </Pagination.Item>
              ) : null
            )}
            <Pagination.Next
              onClick={() =>
                page < data["last_page"] && changeParam("page", page + 1)
              }
            />
            <Pagination.Last
              onClick={() => changeParam("page", data["last_page"])}
            />
          </Pagination>
        )}
      </div>
    </div>
  );
};

export default VariantsStock;
