import React, { useEffect, useState } from "react";
import { Button, Pagination, Table } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import IconsGen from "../../../icons_generator/IconsGen";
import Loader from "../../../loader/Loader";
import AxiosController from "../../../authentication/AxiosController";
import CreateAndUpdateCategory from "../../../modals/CreateAndUpdateCategory";
import { notifySuccess } from "../../../toastify/toastify";

const Category = () => {
  const [_] = useTranslation("global");
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");

  const [categoryData, handleCategory] = useState(null);
  const [page, handlePage] = useState(1);
  const [loading, handleLoader] = useState(false);

  // Modal management
  const [showModal, setShowModal] = useState(false);
  const [action, setAction] = useState("create"); // Can be 'create' or 'update'
  const [editCategory, setEditCategory] = useState(null); // Store category to edit
  const [showImageModal, setShowImageModal] = useState(false);

  const box_head_dir = lang === "en" ? "ltr" : "rtl";

  const fetchCategory = () => {
    handleLoader(true);
    AxiosController.get(
      `api/${slug}/${lang}/categories/categories-admin/${page}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        handleCategory(res.data);
        handleLoader(false);
      })
      .catch((e) => {
        handleLoader(false);
      });
  };

  const updateBanner = (categoryId, field, value) => {
    handleLoader(true);
    AxiosController.put(
      `api/${slug}/${lang}/categories/is-banner-switch/${categoryId}`,
      {},

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        fetchCategory(); // Refresh data after update
        handleLoader(false);
      })
      .catch((e) => {
        handleLoader(false);
      });
  };

  const updateAvailable = (categoryId, field, value) => {
    handleLoader(true);

    AxiosController.put(
      `api/${slug}/${lang}/categories/is-available-switch/${categoryId}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        fetchCategory(); // Refresh data after update
        handleLoader(false);
      })
      .catch((e) => {
        handleLoader(false);
      });
  };

  useEffect(() => {
    fetchCategory();
  }, [page]);

  const openCreateModal = () => {
    setAction("create");
    setEditCategory(null);
    setShowModal(true);
  };

  const openUpdateModal = (category) => {
    setAction("update");
    setEditCategory(category);
    setShowModal(true);
  };
  const openImageUpdateModal = (category) => {
    setEditCategory(category); // Set the category whose image will be updated
    setShowImageModal(true); // Show the image update modal (use same modal)
  };

  const handleAvailableDropdownChange = (categoryId, field, event) => {
    updateAvailable(categoryId, field);
  };
  const handleBannerDropdownChange = (categoryId, field, event) => {
    updateBanner(categoryId, field);
  };

  const deleteCategory = (id) => {
    AxiosController.delete(
      `api/${slug}/${lang}/categories/delete-category/${id}`,

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        fetchCategory();
        notifySuccess(_("panel.delete_successful"));

        handleLoader(false);
      })
      .catch((e) => {
        handleLoader(false);
      });
  };
  return (
    <div
      className="d-flex flex-column flex-lg-column my-2 gap-3 position-relative"
      style={{ width: "100%" }}
    >
      <CreateAndUpdateCategory
        show={showModal}
        setShowImageModal={setShowImageModal}
        setShow={setShowModal}
        showImageModal={showImageModal}
        action={action}
        editCategory={editCategory}
        fetchCategories={fetchCategory}
      />
      <Helmet>
        <title>{_("panel.category")}</title>
      </Helmet>
      <Loader active={loading} />
      <div
        className="tables-container d-flex flex-column px-2"
        style={{ position: "relative", flexGrow: 3 }}
      >
        <div
          className={`header d-flex flex-row gap-3 align-items-center justify-content-between`}
        >
          <h3 className="fs-1" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.category")}
          </h3>
          <Button
            variant="primary"
            style={{ background: "#59C6C9", color: "white", border: "none" }}
            onClick={openCreateModal}
          >
            + {_("panel.add")}
          </Button>
        </div>
        <Table
          responsive
          dir={box_head_dir}
          className="reports-table text-center py-2"
        >
          <thead>
            <tr>
              <th>#</th>
              <th>{_("panel.date")}</th>
              <th>{_("panel.product")}</th>
              <th>{_("panel.image")}</th>
              <th>{_("panel.available")}</th>
              <th>{_("panel.banners")}</th>
              <th>{_("panel.updateImage")}</th>
              <th>{_("panel.update")}</th>
              <th>{_("panel.delete")}</th>
            </tr>
          </thead>
          <tbody>
            {categoryData && categoryData.data.length > 0 ? (
              categoryData.data.map((category, index) => (
                <tr key={category.id}>
                  <td>{index + 1}</td>
                  <td>
                    {new Date(category.created_at).toLocaleDateString(lang)}
                  </td>
                  <td>
                    {category.arabic_name}
                    <br />
                    {category.english_name}
                  </td>
                  <td>
                    <img
                      height={"50px"}
                      width={"50px"}
                      src={category.svg_icon_name || category.png_img}
                      alt="category_image"
                    />
                  </td>
                  <td>
                    <select
                      className="form-control"
                      value={category.is_available.toString()}
                      onChange={(e) =>
                        handleAvailableDropdownChange(
                          category.id,
                          "is_available",
                          e
                        )
                      }
                    >
                      <option value="true">
                        {category.is_available === 1
                          ? _("panel.available")
                          : _("panel.unavailable")}
                      </option>
                      <option value="false">
                        {category.is_available === 1
                          ? _("panel.unavailable")
                          : _("panel.available")}
                      </option>
                    </select>
                  </td>
                  <td>
                    <select
                      className="form-control m-3"
                      value={category.is_banner.toString()}
                      onChange={(e) =>
                        handleBannerDropdownChange(
                          category.id,
                          "is_banner",
                          e.target.value
                        )
                      }
                    >
                      <option value="true">
                        {category.is_banner === 1
                          ? _("panel.yes")
                          : _("panel.no")}
                      </option>
                      <option value="false">
                        {category.is_banner === 1
                          ? _("panel.no")
                          : _("panel.yes")}
                      </option>
                    </select>
                  </td>

                  <td>
                    <span onClick={() => openImageUpdateModal(category)}>
                      <IconsGen icon_name={"enter"} />
                    </span>
                  </td>
                  <td>
                    <span onClick={() => openUpdateModal(category)}>
                      <IconsGen icon_name={"enter"} />
                    </span>
                  </td>
                  <td>
                    <span onClick={() => deleteCategory(category.id)}>
                      <IconsGen icon_name={"delete"} />
                    </span>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7">{_("panel.no_data")}</td>
              </tr>
            )}
          </tbody>
        </Table>
        {categoryData && (
          <Pagination className="m-auto flex-wrap pt-2">
            <Pagination.First onClick={() => handlePage(1)} />
            <Pagination.Prev
              onClick={() => (page - 1 > 0 ? handlePage(page - 1) : 1)}
            />
            {categoryData.links.map((element, index, arr) => {
              if (index === 0 || index === arr.length - 1) {
                return null;
              } else {
                return (
                  <Pagination.Item
                    key={index}
                    active={element.active}
                    onClick={() =>
                      element.label !== "..."
                        ? handlePage(parseInt(element.label))
                        : null
                    }
                  >
                    {element.label}
                  </Pagination.Item>
                );
              }
            })}
            <Pagination.Next onClick={() => handlePage(page + 1)} />
            <Pagination.Last
              onClick={() => handlePage(categoryData.last_page)}
            />
          </Pagination>
        )}
      </div>
    </div>
  );
};

export default Category;
