import React from "react";
import { Pagination } from "react-bootstrap";

const PaginationComponent = ({
  currentPage,
  totalPages,
  onPageChange,
  lang,
}) => {
  const handleClick = (page) => {
    if (page === currentPage || page < 1 || page > totalPages) return;
    onPageChange(page);
  };

  const getPaginationItems = () => {
    const items = [];
    const SIBLINGS = 1; // Number of siblings on each side

    // Add First and Prev buttons
    items.push(
      <Pagination.First
        key="first"
        onClick={() => handleClick(1)}
        disabled={currentPage === 1}
        aria-label="First page"
      />,
      <Pagination.Prev
        key="prev"
        onClick={() => handleClick(currentPage - 1)}
        disabled={currentPage === 1}
        aria-label="Previous page"
      />
    );

    // Calculate range
    let startPage = Math.max(currentPage - SIBLINGS, 1);
    let endPage = Math.min(currentPage + SIBLINGS, totalPages);

    // Always show first page
    if (startPage > 1) {
      items.push(
        <Pagination.Item
          key={1}
          onClick={() => handleClick(1)}
          aria-label="Page 1"
        >
          1
        </Pagination.Item>
      );
      if (startPage > 2) {
        items.push(<Pagination.Ellipsis key="ellipsis-start" />);
      }
    }

    // Add pages
    for (let page = startPage; page <= endPage; page++) {
      items.push(
        <Pagination.Item
          key={page}
          active={page === currentPage}
          onClick={() => handleClick(page)}
          aria-label={`Page ${page}`}
        >
          {page}
        </Pagination.Item>
      );
    }

    // Add last page
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        items.push(<Pagination.Ellipsis key="ellipsis-end" />);
      }
      items.push(
        <Pagination.Item
          key={totalPages}
          onClick={() => handleClick(totalPages)}
          aria-label={`Last page ${totalPages}`}
        >
          {totalPages}
        </Pagination.Item>
      );
    }

    // Add Next and Last buttons
    items.push(
      <Pagination.Next
        key="next"
        onClick={() => handleClick(currentPage + 1)}
        disabled={currentPage === totalPages}
        aria-label="Next page"
      />,
      <Pagination.Last
        key="last"
        onClick={() => handleClick(totalPages)}
        disabled={currentPage === totalPages}
        aria-label="Last page"
      />
    );

    return items;
  };

  return (
    <div className="d-flex mt-3">
      <Pagination
        className="m-auto flex-wrap"
        dir={"ltr"}
        aria-label="Pagination navigation"
      >
        {getPaginationItems()}
      </Pagination>
    </div>
  );
};

export default PaginationComponent;
