import "./style.css";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Alert, Button, Modal } from "react-bootstrap";
import Loader from "../../../loader/Loader";
import ProductsStockTableRow from "./ProductsStockTableRow";
import AxiosController from "../../../authentication/AxiosController";
import SearchComponent from "./SearchComponent";
import IconsGen from "../../../icons_generator/IconsGen";
import TableLabels from "./TableLabels";
const getPriceDifferenceClass = (variantPrice, supplierPrice) => {
  const priceDifference = variantPrice - supplierPrice;
  if (priceDifference > 0) return "bg-success text-white";
  if (priceDifference < 0) return "bg-danger text-white";
  return "";
};

const ProductsStockTable = ({
  ProductsStockData,
  get_products_stock_handler,
}) => {
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");
  const [_] = useTranslation("global");
  const [show, setShow] = useState(false);
  const box_head_dir = lang === "en" ? "ltr" : "rtl";
  const [loading, handleLoader] = useState(false);
  const [loading2, handleLoader2] = useState(false);
  const [search, setSearch] = useState(false);

  // Form state
  const [sku, setSku] = useState("");
  const [skuErr, setSkuErr] = useState("");
  const [supplierId, setSupplierId] = useState("");
  const [supplierIdErr, setSupplierIdErr] = useState("");
  const [warehouseId, setWarehouseId] = useState("");
  const [warehouseIdErr, setWarehouseIdErr] = useState("");
  const [quantity, setQuantity] = useState("");
  const [quantity2, setQuantity2] = useState(0);
  const [quantityErr, setQuantityErr] = useState("");
  const [price, setPrice] = useState("");
  const [priceErr, setPriceErr] = useState("");
  const [status, setStatus] = useState("");
  const [statusErr, setStatusErr] = useState("");
  const [isCashErr, setIsCashErr] = useState("");
  const [isCash, setIsCash] = useState("");
  const [stockLogId, setStockLogId] = useState("");

  const [suppliers, setSuppliers] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [success, setSuccess] = useState("");
  const [disabled, setDisabled] = useState(false);
  const get_warehouses = (slug, lang, token, handleWarehouses) => {
    AxiosController.get(`api/${slug}/${lang}/get-warehouses/`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        handleWarehouses(res.data);
      })
      .catch((e) => {});
  };

  const get_suppliers = (slug, lang, token, handleWarehouses) => {
    AxiosController.get(`api/${slug}/${lang}/get-suppliers/`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        handleWarehouses(res.data);
      })
      .catch((e) => {});
  };

  const handleClose = () => {
    // Reset all form fields
    setSku("");
    setSupplierId("");
    setWarehouseId("");
    setQuantity("");
    setPrice("");
    setStatus("");
    setIsCash("");
    setStockLogId("");
    setSuccess("");
    setShow(false);
  };

  const handleShow = (
    log_id,
    sku,
    quantity,
    price,
    supplier,
    warehouse,
    status,
    edit = false
  ) => {
    if (edit) {
      handleLoader(true);
      setStockLogId(log_id);
      setSku(sku);
      setQuantity(quantity);
      setPrice(price);
      setSupplierId(supplier);
      setWarehouseId(warehouse);
      setStatus(status);
      setQuantity2(0);
      get_warehouses(slug, lang, token, setWarehouses);
      get_suppliers(slug, lang, token, setSuppliers);
      handleLoader(false);
      setShow(true);
      setDisabled(false);
    } else {
      setQuantity2(0);
      handleLoader(true);
      get_warehouses(slug, lang, token, setWarehouses);
      get_suppliers(slug, lang, token, setSuppliers);
      handleLoader(false);
      setShow(true);
      setDisabled(true);
    }
  };

  const createStockLog = async () => {
    handleLoader2(true);
    try {
      const endpoint = stockLogId
        ? `api/${slug}/${lang}/update-or-sell-or-damaged/`
        : `api/${slug}/${lang}/new-product-variant-stock/`;

      const requestData = {
        sku: sku,
        supplier_id: supplierId,
        warehouse_id: warehouseId,
        quantity: quantity,
        left: quantity - quantity2,
        price: price,
        status: stockLogId ? isCash : "new",
        is_cash: status,
        stock_log_id: stockLogId ? stockLogId : "",
      };

      await AxiosController.post(endpoint, requestData, {
        headers: { Authorization: `Bearer ${token}` },
      });

      // Reset form and show success
      handleClose();
      setSuccess(_("panel.addedStockLog"));

      // Refresh the data
      await get_products_stock_handler();
    } catch (e) {
      // Handle errors
      const errors = e.response?.data?.errors || {};
      setSkuErr(errors.sku?.[0] || "");
      setSupplierIdErr(errors.supplier_id?.[0] || "");
      setWarehouseIdErr(errors.warehouse_id?.[0] || "");
      setQuantityErr(errors.quantity?.[0] || "");
      setPriceErr(errors.price?.[0] || "");
      setIsCashErr(errors.is_cash?.[0] || "");
    } finally {
      handleLoader2(false);
    }
  };
console.log('fullllllllllllllllllllllll data' , ProductsStockData)
  return (
    <div
      className="position-relative tables-container d-flex flex-column gap-3"
      style={{ minHeight: "100px", width: "100%" }}
    >
      <Button
        className="fs-5 px-5 w-25 align-self-start"
        style={{
          backgroundColor: "rgb(89, 198, 201)",
          border: "none",
          borderRadius: "7px",
        }}
        onClick={() => setSearch(!search)}
      >
        {_("panel.search")}
      </Button>

      {search && <SearchComponent />}

      <Button
        variant="primary"
        onClick={() => handleShow()}
        className="px-5 align-self-start"
        style={{ background: "#59C6C9", color: "white", border: "none" }}
      >
        {_("panel.add")}
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Loader active={loading2} />
        <Modal.Header closeButton className="border-0" dir={box_head_dir}>
          <Modal.Title className="flex-grow-1">
            {_("panel.addwarehouse")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body dir={box_head_dir}>
          {label_input("SKU", sku, setSku)}
          {skuErr && <Alert variant="danger">{skuErr}</Alert>}

          {label_input(_("panel.quantity"), quantity, setQuantity, !disabled)}
          {quantityErr && <Alert variant="danger">{quantityErr}</Alert>}

          {label_input(
            _("panel.damagedOrSellQ"),
            quantity2,
            setQuantity2,
            disabled
          )}

          {label_input(_("panel.price"), price, setPrice)}
          {priceErr && <Alert variant="danger">{priceErr}</Alert>}

          {stockLogId && (
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                {_("panel.qStatus")}
              </label>
              <select
                className="form-select"
                value={isCash}
                onChange={(e) => setIsCash(e.target.value)}
              >
                <option value=""></option>
                <option value="damaged">{_("panel.damaged")}</option>
                <option value="sell">{_("panel.sell")}</option>
              </select>
              {supplierIdErr && <Alert variant="danger">{supplierIdErr}</Alert>}
            </div>
          )}

          <div className="mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label">
              {_("home.suppliername")}
            </label>
            <select
              className="form-select"
              value={supplierId}
              onChange={(e) => setSupplierId(e.target.value)}
            >
              <option value={0}></option>
              {suppliers.map((supplier) => (
                <option key={supplier.id} value={supplier.id}>
                  {supplier.name}
                </option>
              ))}
            </select>
            {supplierIdErr && <Alert variant="danger">{supplierIdErr}</Alert>}
          </div>

          <div className="mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label">
              {_("panel.warehouse")}
            </label>
            <select
              className="form-select"
              value={warehouseId}
              onChange={(e) => setWarehouseId(e.target.value)}
            >
              <option value={0}></option>
              {warehouses.map((warehouse) => (
                <option key={warehouse.id} value={warehouse.id}>
                  {warehouse.title}
                </option>
              ))}
            </select>
            {warehouseIdErr && <Alert variant="danger">{warehouseIdErr}</Alert>}
          </div>

          <div className="mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label">
              {_("panel.payment")}
            </label>
            <select
              className="form-select"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value={0}></option>
              <option value={1}>{_("panel.cash")}</option>
              <option value={-1}>{_("panel.postponed")}</option>
            </select>
            {statusErr && <Alert variant="danger">{statusErr}</Alert>}
          </div>

          {isCashErr && <Alert variant="danger">{isCashErr}</Alert>}
          {success && <Alert variant="success">{success}</Alert>}
        </Modal.Body>
        <Modal.Footer className="border-0" dir={box_head_dir}>
          <Button variant="primary" onClick={createStockLog}>
            {_("panel.save")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Loader active={loading} />

      <div
        className="tables-container d-flex flex-column px-2"
        style={{ position: "relative", flexGrow: 3 }}
      >
        <div
          className={`header d-flex ${
            lang === "en" ? "flex-row" : "flex-row"
          } gap-3 align-items-center justify-content-between`}
        >
          <h3 className="fs-1" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.variantsStockHistory")}
          </h3>
        </div>
        <div className="footer d-flex flex-column flex-grow-1">
          <div
            className={`table-responsive my-3 d-flex ${
              lang === "en" ? "flex-row" : "flex-row-reversed"
            } align-items-center`}
          >
            <table className="table table-borderless text-nowrap position-relative">
              <thead>
                <TableLabels />
              </thead>
              <tbody>
                {ProductsStockData?.map((order, idx) => {
                  const {
                    thumbnail,
                    product_title,
                    product_id,
                    sku,
                    variant_seller_price,
                    supplier_price,
                    variant_sys_comm,
                    quantity,
                    left,
                    id,
                    is_cash,
                    supplier_id,
                    warehouse_id,
                  } = order;

                  const priceDifferenceClass = getPriceDifferenceClass(
                    variant_seller_price,
                    supplier_price
                  );
                  const dateSegments = order["created_at_formatted"].split(" ");
                  return (
                    <tr key={idx}>
                      {/* <td>{idx + 1}</td> */}
                      <td className="align-middle">
                        <div
                          className="form-check mt-3 d-flex justify-content-center align-items-center"
                          style={{
                            paddingLeft: "2.24rem",
                            paddingRight: "7px",
                          }}
                        >
                          <input
                            className="form-check-input"
                            style={{
                              border: "1px solid rgba(243, 168, 55, 1)",
                              transform: "translate(-0%, -50%)",
                            }}
                            type="checkbox"
                            value=""
                            id={`flexCheckDefault-${id}`}
                          />
                        </div>
                      </td>

                      <td scope="row" className="align-middle">
                        {dateSegments[0]} <br /> {dateSegments[1]}{" "}
                        {dateSegments[2]} <br /> {order["title"]} <br />{" "}
                        {order["first_name"] ?? ""} {order["last_name"] ?? ""} #
                        {order["user_id"] ?? ""}
                      </td>

                      <td className="align-middle">
                        <img
                          src={thumbnail}
                          width={"69px"}
                          className="rounded"
                        />
                        <br />
                        {product_title.substring(0, 20) + "..."} <br /> #
                        {product_id} <br /> {sku}
                      </td>

                      <td className="align-middle">{variant_seller_price}</td>
                      <td className="align-middle">{supplier_price}</td>

                      <td className={`align-middle ${priceDifferenceClass}`}>
                        {variant_seller_price - supplier_price}
                      </td>

                      <td className="align-middle">{variant_sys_comm}</td>

                      <td className="align-middle">{quantity}</td>
                      <td className="align-middle">{left}</td>

                      <td
                        onClick={() => {
                          handleShow(
                            id,
                            sku,
                            left,
                            supplier_price,
                            supplier_id,
                            warehouse_id,
                            is_cash,
                            true
                          );
                        }}
                        className="align-middle"
                      >
                        <IconsGen icon_name={"edit"} />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const label_input = (label, value, onChange, disabled = false) => {
  return (
    <div className="mb-3">
      <label htmlFor="exampleInputEmail1" className="form-label">
        {label}
      </label>
      <input
        type="text"
        disabled={disabled}
        className="form-control py-2 egp"
        id="exampleInputEmail1"
        aria-describedby="emailHelp"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};

export default ProductsStockTable;
