import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { FaMoneyBillTrendUp } from "react-icons/fa6";

const Sales = ({ direction }) => {
  const location = useLocation();
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const [isSubNavVisible, setIsSubNavVisible] = useState(false);

  useEffect(() => {
    if (location.pathname.includes("/panel/financials/")) {
      setIsSubNavVisible(true);
    } else {
      setIsSubNavVisible(false);
    }
  }, [location.pathname]);
  const handleClick = () => {
    setIsSubNavVisible(!isSubNavVisible);
  };

  return (
    <div className="links-container d-flex flex-column">
      <div
        className={`feature_link ${
          lang === "en" ? "feature_link_en" : ""
        } d-flex flex-row-reverse align-items-center gap-2 py-3 ${
          isSubNavVisible ? "active-link" : ""
        }`}
        dir={direction}
        onClick={handleClick}
      >
        <FaMoneyBillTrendUp className="icon" />

        <p>{_("panel.sales")}</p>
      </div>

      <ul
        className={`${
          isSubNavVisible ? "d-flex" : "d-none"
        } sub-nav flex-column gap-2 px-5 pt-3`}
      >
        <li>
          <NavLink to={`/${slug}/${lang}/panel/financials/sales`}>
            <p className="d-inline ">{_("panel.finance_sales")}</p>
          </NavLink>
        </li>
        <li>
          <NavLink to={`/${slug}/${lang}/panel/financials/couriers-overview`}>
            <p className="d-inline ">{_("panel.finance_couriers")}</p>
          </NavLink>
        </li>

        <li>
          <NavLink to={`/${slug}/${lang}/panel/financials/marketers-overview`}>
            <p className="d-inline ">{_("panel.finance_marketers")}</p>
          </NavLink>
        </li>

        <li>
          <NavLink to={`/${slug}/${lang}/panel/financials/sellers-overview`}>
            <p className="d-inline ">{_("panel.finance_sellers")}</p>
          </NavLink>
        </li>
        {/* supplier financials */}
        <li>
          <NavLink to={`/${slug}/${lang}/panel/financials/suppliers-overview`}>
            <p className="d-inline ">{_("panel.finance_suppliers")}</p>
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default Sales;
