import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AxiosController from "../../../authentication/AxiosController";
import ProductsStockTable from "./ProductsStockTable";
import Loader from "../../../loader/Loader";
import ProductStockDetails from "./ProductStockDetails";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import PaginationComponent from "../../../reuseable/PaginationComponent";

const VariantsHistory = () => {
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");
  const [productsStock, setProductsStock] = useState({
    data: [],
    last_page: 1,
  });
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [showProduct, setShowProduct] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(0);
  const pageFromUrl = new URLSearchParams(location.search).get("page") || 1;
  const [page, setPage] = useState(Number(pageFromUrl));
  const [_] = useTranslation("global");
  const Navigate = useNavigate();

  const urlParams = new URLSearchParams(location.search);

  const get_products_stock = async (
    slug,
    lang,
    token,
    page,
    productCode,
    sku,
    supplier,
    warehouse,
    startDate,
    endDate,
    status,
    is_cash
  ) => {
    try {
      setLoading(true);
      const response = await AxiosController.get(
        `api/${slug}/${lang}/get-variants-stocks-history/${page}`,
        {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            product_code: productCode,
            sku,
            supplier,
            warehouse,
            start_date: startDate,
            end_date: endDate,
            status,
            is_cash,
          },
        }
      );
      // Reset data and update state
      setProductsStock({
        data: response?.data?.data || [], // Make sure to access data correctly from the API response
        last_page: response?.data?.last_page || 1,
      });
    } catch (error) {
      console.error("Error fetching products stock:", error);
    } finally {
      setLoading(false);
    }
  };

  const updateUrlParam = (param, value) => {
    urlParams.set(param, value);
    setPage(value); // Update state
    Navigate(`?${urlParams.toString()}`); // Update URL
    get_products_stock_handler(); // Trigger data fetching based on updated page
  };

  const get_products_stock_handler = () => {
    const nextPage = parseInt(urlParams.get("page"), 10) || 1;
    const productCode = urlParams.get("product_code") || "";
    const sku = urlParams.get("sku") || "";
    const supplier = urlParams.get("supplier") || "";
    const warehouse = urlParams.get("warehouse") || "";
    const startDate = urlParams.get("start_date") || "";
    const endDate = urlParams.get("end_date") || "";
    const status = urlParams.get("status") || "";
    const is_cash = urlParams.get("is_cash") || "";

    get_products_stock(
      slug,
      lang,
      token,
      nextPage,
      productCode,
      sku,
      supplier,
      warehouse,
      startDate,
      endDate,
      status,
      is_cash
    );
  };

  useEffect(() => {
    get_products_stock_handler();
  }, [slug, lang, location.search]); // Removed page dependency since it's derived from location.search

  return (
    <div
      className={`table-responsive tables-container my-3 d-flex flex-column position-relative ${
        lang === "en" ? "flex-row" : "flex-row-reversed"
      } align-items-center`}
    >
      <Helmet>
        <title>{_("panel.variantsStockHistory")}</title>
      </Helmet>

      <ProductStockDetails
        productId={selectedProduct}
        showComp={showProduct}
        handleShowComp={setShowProduct}
      />

      <Loader active={loading} />

      <ProductsStockTable
        ProductsStockData={productsStock?.data}
        get_products_stock_handler={get_products_stock_handler}
      />

      <PaginationComponent
        currentPage={page}
        totalPages={productsStock.last_page}
        onPageChange={(newPage) => updateUrlParam("page", newPage)}
        lang={lang}
      />
    </div>
  );
};

export default VariantsHistory;
