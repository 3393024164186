import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../../loader/Loader";
import AxiosController from "../../../authentication/AxiosController";
import { Button, Pagination, Table } from "react-bootstrap";
import { Helmet } from "react-helmet";
import SearchComponent from "./SearchComponent";
import Manifest from "./Manifest";
import { downloadPDF } from "./print";
import { FaFilePdf } from "react-icons/fa6";

const DownloadManifest = () => {
  const { slug, lang } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const [_] = useTranslation("global");
  const box_head_dir = lang === "en" ? "ltr" : "rtl";
  const token = localStorage.getItem("token");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState({});
  const [data, setData] = useState([]);
  const [id, setId] = useState("");
  const [search, handleSearch] = useState(false);
  const [manifest, handleManifest] = useState(false);
  const [oneManifest, setOneManifest] = useState([]);

  const changeParam = (param, value) => {
    urlParams.set(param, value);
    navigate(`?${urlParams.toString()}`);
  };

  const get_all_manifest = (start_date, end_date, courier_id, user_id) => {
    setLoading(true);
    AxiosController.get(`/api/${slug}/${lang}/manifest?page=${page}`, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        start_date,
        end_date,
        courier_id,
        user_id,
      },
    })
      .then((res) => {
        setLoading(false);
        setAllData(res.data || {});
      })
      .catch(() => {
        setLoading(false);
      });
  };

const get_one_manifest = (id) => {
  setLoading(true);
  return AxiosController.get(`/api/${slug}/${lang}/manifest/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      setLoading(false);
      setOneManifest(res.data || []);
      return res.data || [];
    })
    .catch(() => {
      setLoading(false);
      setOneManifest([]);
      return [];
    });
};

const print = async (id) => {
  const manifestData = await get_one_manifest(id);
  await downloadPDF(
    manifestData,
    _("panel.total"),
    _("panel.orderContent"),
    _("panel.courierNumber"),
    _("panel.totalorders2")
  );
};

  useEffect(() => {
    const start_date = urlParams.get("start_date") || "";
    const end_date = urlParams.get("end_date") || "";
    const courier_id = urlParams.get("courier_id") || "";
    const user_id = urlParams.get("user_id") || "";
    setPage(parseInt(urlParams.get("page"), 10) || 1);
    get_all_manifest(start_date, end_date, courier_id, user_id);
  }, [token, slug, lang, page, location.search]);

  return (
    <div className="flex-grow-1 stats-component" dir={box_head_dir}>
      <Helmet>
        <title>{_("panel.downloadManifest")}</title>
      </Helmet>
      <div className="  reports-container my-3 d-flex flex-column gap-2 px-2 position-relative">
        <Loader active={loading} />
        <div className="mb-4 reports-header d-flex flex-column flex-md-row py-2 justify-content-between flex-wrap">
          <h3 className="fs-2" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.downloadManifest")}
          </h3>{" "}
          <Button
            className="fs-5 px-2 my-1  w-25"
            style={{
              backgroundColor: "rgb(243, 168, 55)",
              border: "none",
              borderRadius: "7px",
            }}
            onClick={() => {
              handleManifest(!manifest);
            }}
          >
            {_("panel.Manifest")}
          </Button>{" "}
          <Button
            className="fs-5 px-2 my-1 w-25 "
            style={{
              backgroundColor: "rgb(89, 198, 201)",
              border: "none",
              borderRadius: "7px",
            }}
            onClick={() => {
              handleSearch(!search);
            }}
          >
            {_("panel.search")}
          </Button>
        </div>

        {search && <SearchComponent />}

        <div className="row">
          <div
            className="col-lg-12  "
            style={{ position: "relative", overflowX: "auto" }}
          >
            <Table
              responsive
              dir={box_head_dir}
              className="reports-table text-nowrap text-center"
            >
              <thead>
                <tr className="text-center">
                  <th>#</th>
                  <th>{_("home.fullname")}</th>
                  <th>{_("panel.dateExpenses")}</th>
                  <th>{_("panel.updateLog")}</th>
                  <th>{_("panel.courierName")}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {allData.data &&
                  allData.data.map((item) => (
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td>
                        <span className="fw-bold"># {item.user_id}</span> <br />
                        {item.first_name} {item.last_name}
                      </td>
                      <td>{new Date(item.created_at).toLocaleString()}</td>
                      <td>{new Date(item.updated_at).toLocaleString()}</td>
                      <td>
                        <span className="fw-bold">
                          {" "}
                          {item.courier_name || "_"}
                        </span>
                        <br /># {item.courier_id}
                      </td>
                      <td>
                        {" "}
                        <button
                          onClick={() => print(item?.id)}
                          className="btn  text-nowrap"
                          style={{
                            border: "1px solid #172B4D",
                            color: "#172B4D",
                          }}
                        >
                          <FaFilePdf className="fs-3 px-1 text-danger" />
                          {_("panel.Manifest")}
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
          <div className="d-flex   align-items-center justify-content-center py-4">
            {allData.links && allData.links.length > 0 && (
              <Pagination className="m-auto flex-wrap">
                <Pagination.First onClick={() => changeParam("page", 1)} />
                <Pagination.Prev
                  onClick={() =>
                    page > 1 ? changeParam("page", page - 1) : null
                  }
                />
                {allData.links.map((element, index) => {
                  if (index === 0 || index === allData.links.length - 1)
                    return null;

                  return (
                    <Pagination.Item
                      key={index}
                      active={element.active}
                      onClick={() =>
                        element.label !== "..."
                          ? changeParam("page", parseInt(element.label))
                          : null
                      }
                    >
                      {element.label}
                    </Pagination.Item>
                  );
                })}
                <Pagination.Next
                  onClick={() =>
                    page < allData.last_page
                      ? changeParam("page", page + 1)
                      : null
                  }
                />
                <Pagination.Last
                  onClick={() => changeParam("page", allData.last_page)}
                />
              </Pagination>
            )}
          </div>
        </div>

        <hr />
        {manifest && <Manifest />}
      </div>
    </div>
  );
};

export default DownloadManifest;
