import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../loader/Loader";
import { Button, Col, Table, Form } from "react-bootstrap";
import { Helmet } from "react-helmet";
import DateRangePicker from "react-bootstrap-daterangepicker";
import AxiosController from "../../authentication/AxiosController";
import * as XLSX from "xlsx";
import IconsGen from "../../icons_generator/IconsGen";
import PaginationComponent from "../../reuseable/PaginationComponent";

const FinanceSellers = () => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const box_head_dir = lang === "en" ? "ltr" : "rtl";
  const [loading, handleLoader] = useState(false);
  const [allData, handleAllData] = useState([]);
  const [search, handleSearch] = useState(false);
  const token = localStorage.getItem("token");
  const location = useLocation();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(location.search);
  const [marketer_id, handleMarketer_id] = useState("");
  const [product_id, handleProduct_id] = useState("");
  const [days, handleDays] = useState("");
  const [userId, handleUserId] = useState("");
  const [seller_id, handleSeller_id] = useState("");
  const [startDate, handleStartDate] = useState("");
  const [endDate, handleEndDate] = useState("");
  const pageFromUrl = new URLSearchParams(location.search).get("page") || 1;
  const [page, setPage] = useState(Number(pageFromUrl));
  const changeParam = () => {
    urlParams.set("marketer_id", marketer_id);
    urlParams.set("product_id", product_id);
    urlParams.set("days", days);
    urlParams.set("user_id", userId);
    urlParams.set("seller_id", seller_id);
    urlParams.set("start_date", startDate);
    urlParams.set("end_date", endDate);
    navigate(`?${urlParams.toString()}`);
  };
  const updatePage = (param, value) => {
    urlParams.set(param, value);
    setPage(value);
    navigate(`?${urlParams.toString()}`);
  };
  const resetHandler = () => {
    handleMarketer_id("");
    handleProduct_id("");
    handleDays("");
    handleUserId("");
    handleEndDate("");
    navigate("");
  };

  const handleApply = (event, picker) => {
    const startDate = picker.startDate.format("YYYY-MM-DD");
    const endDate = picker.endDate.format("YYYY-MM-DD");
    handleStartDate(startDate);
    handleEndDate(endDate);
  };

  const get_sellers = useCallback(() => {
    handleLoader(true);

    AxiosController.get(
      `api/${slug}/${lang}/financials/sellers-overview?page=${page}`,
      {
        headers: { Authorization: `Bearer ${token}` },
        // params: {
        // start_date: startDate,
        // end_date: endDate,
        // marketer_id: marketer_id,
        // seller_id: seller_id,
        // product_id: product_id,
        // },
      }
    )
      .then((res) => {
        handleLoader(false);
        handleAllData(res?.data?.sellers);
      })
      .catch((error) => {
        handleLoader(false);
        console.error(error);
      });
  }, [lang, page, slug, token]);

  const handleSubmit = (e) => {
    e.preventDefault();
    changeParam();
    get_sellers();
  };
  useEffect(() => {
    get_sellers();
  }, [get_sellers]);

  const downloadExcel = () => {
    const formattedData = allData?.data?.map((item, index) => ({
      "#": index + 1,
      Seller: `${item.first_name} ${item.last_name}`,
      TotalBalance: item.balance_total,
      PaymentRequestsAmount: item.payment_requests_amount,
      ProductsLeft: item.products_left,
      SellerCommission: item.seller_commission,
      TotalProductValue: item.total_product_value,
    }));

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "finance_sellers");

    XLSX.writeFile(wb, "finance_sellers.xlsx");
  };
  console.log("------------------------------", allData);
  return (
    <div className="flex-grow-1 stats-component">
      <Helmet>
        <title>{_("panel.finance_sellers")}</title>
      </Helmet>

      <div className="reports-container my-3 d-flex flex-column gap-2 px-2 position-relative ">
        <Loader active={loading} />

        <div className="reports-header d-flex flex-column flex-md-row py-2 justify-content-between flex-wrap">
          <h3 className="fs-2 mb-4" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.finance_sellers")}
          </h3>
          <button
            className="btn   text-nowrap"
            style={{ border: "1px solid #172B4D", color: "#172B4D" }}
            onClick={downloadExcel}
            disabled={loading}
          >
            <IconsGen icon_name={"excel"} /> {_("panel.downloadExcel")}
          </button>
        </div>
        <div className="row" dir={box_head_dir} style={{ height: "1200px" }}>
          <div
            className="col-12"
            style={{ position: "relative", overflowX: "auto", height: "100%" }}
          >
            <Table
              responsive
              dir={box_head_dir}
              className="reports-table text-nowrap text-center"
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th>{_("panel.seller")}</th>
                  <th>{_("panel.totalBalance")}</th>
                  <th>{_("panel.payment_request")}</th>
                  <th>{_("panel.left")}</th>
                  <th>{_("panel.seller_commission")}</th>
                  <th>{_("panel.totalSellerProducts")}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {allData?.data && allData?.data.length > 0 ? (
                  allData.data.map((item, index) => (
                    <tr key={index}>
                      {" "}
                      {/* Added a key for each row */}
                      <td>{index + 1}</td>
                      <td>
                        <span className="fw-bold"># {item.id}</span>
                        <br />
                        {item.first_name}
                        {item.last_name}
                      </td>
                      <td>{Number(item.balance_total).toLocaleString()}</td>
                      <td>
                        {Number(item.payment_requests_amount).toLocaleString()}
                      </td>
                      <td>{Number(item.products_left).toLocaleString()}</td>
                      <td>{Number(item.seller_commission).toLocaleString()}</td>
                      <td>
                        {Number(item.total_product_value).toLocaleString()}
                      </td>
                      <td
                        onClick={() =>
                          navigate(
                            `/${slug}/${lang}/panel/financials/sellers-details/${item?.id}`
                          )
                        }
                      >
                        <IconsGen icon_name={"enter"} />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8" className="text-center">
                      No Data Available
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            <PaginationComponent
              currentPage={page}
              totalPages={allData?.last_page}
              links={allData?.links}

              onPageChange={(newPage) => updatePage("page", newPage)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinanceSellers;
