import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const AddProduct = ({ slug, lang }) => {
  const [_] = useTranslation("global");
  return (
    <li>
      <NavLink to={`/${slug}/${lang}/panel/products/add-product`}>
        <p className="d-inline ">{_("panel.addProduct")}</p>
      </NavLink>
    </li>
  );
};

export default AddProduct;
