import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../loader/Loader";
import { Button, Col, Table, Form } from "react-bootstrap";
import { Helmet } from "react-helmet";
import DateRangePicker from "react-bootstrap-daterangepicker";
import AxiosController from "../../authentication/AxiosController";
import * as XLSX from "xlsx";
import IconsGen from "../../icons_generator/IconsGen";

const FinanceCouriers = () => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const box_head_dir = lang === "en" ? "ltr" : "rtl";
  const [loading, handleLoader] = useState(false);
  const [allData, handleAllData] = useState([]);
  const [search, handleSearch] = useState(false);
  const token = localStorage.getItem("token");
  const location = useLocation();
  const Navigate = useNavigate();
  const urlParams = new URLSearchParams(location.search);
  const [marketer_id, handleMarketer_id] = useState("");
  const [product_id, handleProduct_id] = useState("");
  const [days, handleDays] = useState("");
  const [userId, handleUserId] = useState("");
  const [seller_id, handleSeller_id] = useState("");
  const [startDate, handleStartDate] = useState("");
  const [endDate, handleEndDate] = useState("");

  const changeParam = () => {
    urlParams.set("marketer_id", marketer_id);
    urlParams.set("product_id", product_id);
    urlParams.set("days", days);
    urlParams.set("user_id", userId);
    urlParams.set("seller_id", seller_id);
    urlParams.set("start_date", startDate);
    urlParams.set("end_date", endDate);
    Navigate(`?${urlParams.toString()}`);
  };

  const resetHandler = () => {
    handleMarketer_id("");
    handleProduct_id("");
    handleDays("");
    handleUserId("");
    handleEndDate("");
    Navigate("");
  };

  const handleApply = (event, picker) => {
    const startDate = picker.startDate.format("YYYY-MM-DD");
    const endDate = picker.endDate.format("YYYY-MM-DD");
    handleStartDate(startDate);
    handleEndDate(endDate);
  };

  const get_financials_couriers = () => {
    handleLoader(true);

    AxiosController.get(`api/${slug}/${lang}/financials/couriers-overview`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        handleLoader(false);
        handleAllData(res?.data?.couriers); 
      })
      .catch((error) => {
        handleLoader(false);
        console.error(error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    changeParam();
    get_financials_couriers();
  };

  useEffect(() => {
    get_financials_couriers();
  }, []);

  const downloadExcel = () => {
    const formattedData = allData.map((item, index) => ({
      "#": index + 1,
      "Name": item.name,
      "Net Price": item.net_price.toFixed(2),
      "Real Shipping Cost": item.real_shipping_cost,
      "Shipping Cost": item.shipping_cost,
      "Shipping Cost Diff": item.shipping_cost_diff,
      "Total Price": item.total_price.toFixed(2),
    }));

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "finance_couriers");

    XLSX.writeFile(wb, "finance_couriers.xlsx");
  };

  return (
    <div className="flex-grow-1 stats-component">
      <Helmet>
        <title>{_("panel.finance_couriers")}</title>
      </Helmet>
      {/* <Button
        className="fs-5 px-5 w-25 mb-4"
        style={{
          backgroundColor: "rgb(89, 198, 201)",
          border: "none",
          borderRadius: "7px",
        }}
        onClick={() => {
          handleSearch(!search);
        }}
      >
        {_("panel.search")}
      </Button>

      {search ? (
        <form onSubmit={handleSubmit}>
          <div className="w-100 d-flex flex-column gap-1">
              <div className="inputs-row d-flex w-100 gap-2 flex-column  flex-md-row">
              <Col>
                <Form.Group className="mb-3 flex-grow-1">
                  <Form.Control
                    type="text"
                    placeholder={_("panel.seller")}
                    onChange={(e) => handleSeller_id(e.target.value)}
                    value={seller_id}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3 flex-grow-1">
                  <Form.Control
                    type="text"
                    placeholder={_("panel.themarketer")}
                    onChange={(e) => handleMarketer_id(e.target.value)}
                    value={marketer_id}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3 flex-grow-1">
                  <Form.Control
                    type="text"
                    placeholder={_("panel.product")}
                    onChange={(e) => handleProduct_id(e.target.value)}
                    value={product_id}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <DateRangePicker onApply={handleApply}>
                    <input type="text" className="form-control" />
                  </DateRangePicker>
                </Form.Group>
              </Col>
            </div>
            <div className="action-inputs mt-3">
              <input
                className="px-5 align-self-end py-2"
                style={{
                  backgroundColor: "rgb(243, 168, 55)",
                  border: "none",
                  borderRadius: "7px",
                  color: "white",
                  textAlign: "center",
                }}
                type="submit"
                value={_("panel.search")}
              />
              <Button
                style={{ backgroundColor: "rgb(23, 43, 77)" }}
                variant="dark"
                onClick={resetHandler}
              >
                {_("panel.reset")}
              </Button>
            </div>
          </div>
        </form>
      ) : ""} */}

      <div className="reports-container my-3 d-flex flex-column gap-2 px-2 position-relative">
        <Loader active={loading} />

        <div className="reports-header d-flex flex-column flex-md-row py-2 justify-content-between flex-wrap">
          <h3 className="fs-2 mb-4" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.finance_couriers")}
          </h3>
          <button
            className="btn text-nowrap"
            style={{ border: "1px solid #172B4D", color: "#172B4D" }}
            onClick={downloadExcel}
            disabled={loading}
          >
            <IconsGen icon_name={"excel"} /> {_("panel.downloadExcel")}
          </button>
        </div>
        <div className="row" dir={box_head_dir} style={{ height: "1200px" }}>
          <div
            className="col-12"
            style={{ position: "relative", overflowX: "auto", height: "100%" }}
          >
            <Table
              responsive
              dir={box_head_dir}
              className="reports-table text-nowrap text-center"
            >
              <thead>
                <tr className="text-center">
                  <th>#</th>
                  <th>{_("panel.name")}</th>
                  <th>{_("panel.price")}</th>
                  <th>{_("panel.realShippingCost")}</th>
                  <th>{_("panel.shippingCost")}</th>
                  <th>{_("panel.shippingCostDiff")}</th>
                  <th>{_("home.totalprice")}</th>
                </tr>
              </thead>
              <tbody>
                {allData && allData.length > 0 ? (
                  allData.map((item, index) => (
                    <tr key={index} className="text-center">
                      <td>{index + 1}</td>
                      <td><span className="fw-bold"># {item.id}</span><br />{item.name}</td>
                      <td>{parseInt(item.net_price).toLocaleString()}</td>
                      <td>{parseInt(item.real_shipping_cost).toLocaleString()}</td>
                      <td>{parseInt(item.shipping_cost).toLocaleString()}</td>
                      <td>{parseInt(item.shipping_cost_diff).toLocaleString()}</td>
                      <td>{parseInt(item.total_price).toLocaleString()}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7" className="text-center">
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinanceCouriers;
