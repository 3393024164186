import "./styles.css";
import React, { useState, useRef, useEffect } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { format, endOfYear } from "date-fns";
import { FaCalendarAlt } from "react-icons/fa";

const DateRangePickerComponent = ({
  state,
  setState,
  applyDateRange,
  width = "",
}) => {
  const { startDate, endDate } = state.selection;
  const maxDate = endOfYear(new Date());
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const pickerRef = useRef(null);
  const togglePicker = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    } else {
      setIsPickerOpen((prevState) => !prevState);
    }
  };
  const handleClickOutside = (event) => {
    if (pickerRef.current && !pickerRef.current.contains(event.target)) {
      setIsPickerOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  const formattedStartDate = format(startDate, "yyyy-MM-dd");
  const formattedEndDate = format(endDate, "yyyy-MM-dd");

  const handleDateChange = (item) => {
    setState((prevState) => ({
      ...prevState,
      selection: {
        ...prevState.selection,
        ...item.selection,
      },
    }));
  };

  const handleApplyDateRange = () => {
    if (state.selection.startDate && state.selection.endDate) {
      applyDateRange(state.selection.startDate, state.selection.endDate);
      setIsPickerOpen(false);
    }
  };

  return (
    <div ref={pickerRef} style={{ width: width }}>
      <button
        type="button"
        className="btn d-flex align-items-center justify-content-between px-1"
        onClick={togglePicker}
        onKeyDown={togglePicker}
        style={{
          border: "rgba(89, 198, 201, 1) 1px solid",
          width: width,
          paddingTop: "6px",
          paddingBottom: "6px",
        }}
      >
        {`${formattedStartDate} - ${formattedEndDate}`}
        <FaCalendarAlt className="me-1" />
      </button>

      {isPickerOpen && (
        <div
          dir="ltr"
          className="mt-3 shadow border bg-light container-position"
          style={{ zIndex: 9999 }}
        >
          <DateRangePicker
            onChange={handleDateChange}
            showSelectionPreview={false}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={[state.selection]}
            maxDate={maxDate}
            minDate={new Date("2000-01-01")}
            direction="horizontal"
            ariaLabels={{
              dateInput: {
                selection: {
                  startDate: `Start Date: ${formattedStartDate}`,
                  endDate: `End Date: ${formattedEndDate}`,
                },
              },
              monthPicker: "month picker",
              yearPicker: "year picker",
              prevButton: "previous month button",
              nextButton: "next month button",
            }}
          />
          <div className="d-flex align justify-content-end px-2">
            <button
              className="btn text-white mt-3 mb-2"
              style={{ background: "rgba(89, 198, 201, 1)" }}
              onClick={handleApplyDateRange}
            >
              ok
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DateRangePickerComponent;
