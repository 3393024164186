import React from "react";
import { Helmet } from "react-helmet";
import Suppliers from "./Shipping";
import ShippingSellerFulfillment from "./shipping fulfillment/Shipping";
import GovPricing from "./governments/Shipping";
import { Tabs } from "@mantine/core";
import { useTranslation } from "react-i18next";

const ShippingPage = () => {
  const [_] = useTranslation("global");

  return (
    <div
      className="position-relative tables-container"
      style={{ minHeight: "100px", width: "100%" }}
    >
      {" "}
      <Helmet>
        <title>{_("panel.couriers")}</title>
      </Helmet>
      <Tabs defaultValue="Suppliers">
        <Tabs.List grow>
          {" "}
          <Tabs.Tab
            value="Suppliers"
            className="fs-5"
            color="rgb(89, 198, 201)"
          >
            {_("panel.couriers")}
          </Tabs.Tab>{" "}
          <Tabs.Tab
            value="GovPricing"
            className="fs-5"
            color="rgb(89, 198, 201)"
          >
            {_("panel.shippingCost")}
          </Tabs.Tab>
          <Tabs.Tab
            value="ShippingSellerFulfillment"
            className="fs-5"
            color="rgb(89, 198, 201)"
          >
            {_("panel.fulfillmentpricing")}
          </Tabs.Tab>
        </Tabs.List>{" "}
        <Tabs.Panel value="Suppliers">
          <Suppliers />
        </Tabs.Panel>
        <Tabs.Panel value="GovPricing">
          <GovPricing />
        </Tabs.Panel>{" "}
        <Tabs.Panel value="ShippingSellerFulfillment">
          <ShippingSellerFulfillment />
        </Tabs.Panel>
      </Tabs>
    </div>
  );
};

export default ShippingPage;
