import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const formatNumber = (number) => {
  return Number.isNaN(Number(number)) ? 0 : Number(number).toLocaleString();
};

const TableLabels = ({
  totalCurrentStock,
  totalLeftValue,
  courierQ,
  courierV,
  totalPrice,
  totalLeftByPage,
  totalPiecePriceByPage,
  totalCourierQuantityByPage,
  totalCourierValueByPage,
  totalLeftValueByPage,
  total_labels,
}) => {
  const [_] = useTranslation("global");
  const role = localStorage.getItem("role");
  const employee_type = localStorage.getItem("employee_type");

  const roles_labels = {
    employee: [
      _("panel.date"),
      _("panel.title"),
      _("sku"),
      _("panel.left"),
      _("panel.courier_quantity"),
      _("panel.courier_value"),
    ],
    admin: [
      _("panel.date"),
      _("panel.title"),
      _("sku"),
      _("panel.left"),
      _("panel.leftValue"),
      _("panel.اجمالي ربحيه التجار"),
      _("panel.courier_quantity"),
      _("panel.courier_value"),
    ],
    seller: [
      _("panel.date"),
      _("panel.title"),
      _("sku"),
      _("panel.left"),
      _("panel.price"),
      _("panel.courier_quantity"),
    ],
    fulfillment: [
      _("panel.date"),
      _("panel.title"),
      _("sku"),
      _("panel.left"),
      _("panel.price"),
      _("panel.courier_quantity"),
    ],
  };

  const labels = {
    left: totalCurrentStock,
    leftValue: totalLeftValue,
    courier_quantity: courierQ,
    courier_value: courierV,
    price: totalPrice,
  };

  // Effect to re-render based on changes to totals
  useEffect(() => {}, [totalCurrentStock, totalLeftValue, courierQ, courierV]);

  const parseSafeNumber = (value) => {
    return Number.isNaN(Number(value?.replace(/,/g, "")))
      ? 0
      : Number(value.replace(/,/g, ""));
  };

  return (
    <tr>
      <th>
        <div
          className="form-check mt-3 d-flex justify-content-center"
          style={{ paddingLeft: "2.24rem", paddingRight: "7px" }}
        >
          <input
            className="form-check-input"
            style={{
              border: "1px solid rgba(243, 168, 55, 1)",
              transform: "translate(-0%, -50%)",
            }}
            type="checkbox"
            value=""
            id="flexCheckDefault"
          />
        </div>
      </th>
      {role !== "admin" &&
        roles_labels[role].map((label, index) => {
          let labelValue = 0;
          let labelPage = 0;

          // Set values based on index to match role-based display order
          if (index === 3) {
            labelValue = labels.left;
            labelPage = totalLeftByPage || 0;
          } else if (index === 4) {
            labelValue = labels.leftValue;
            labelPage = totalLeftValueByPage || 0;
          } else if (index === 5) {
            labelValue = labels.price;
            labelPage = totalPiecePriceByPage || 0;
          } else if (index === 6) {
            labelValue = labels.courier_quantity;
            labelPage = totalCourierQuantityByPage || 0;
          } else if (index === 7) {
            labelValue = labels.courier_value;
            labelPage = totalCourierValueByPage || 0;
          }

          return (
            <th key={index}>
              {labelPage && role === "admin"
                ? `(${formatNumber(labelPage)})`
                : ""}
              <br />
              {labelValue && role === "admin"
                ? `t (${formatNumber(labelValue)})`
                : ""}
              <br />
              {label}
            </th>
          );
        })}

      {role === "admin" && (
        <>
          <th>{_("products_stock.date")}</th>
          <th>{_("products_stock.product")}</th>
          <th>
            {_("products_stock.stock_quantity")}
            <br />
            t({formatNumber(total_labels.total_left)})
          </th>
          <th>
            {_("products_stock.unit_value")}
            <br /> ({_("products_stock.seller_price")})
          </th>
          <th>
            {_("products_stock.total_stock_value")} <br />
            t({formatNumber(total_labels.total_value)})
          </th>
          <th>
            {_("products_stock.shipping_quantity")}
            <br />
            t({formatNumber(total_labels.courier_quantity)})
          </th>
          <th>
            {_("products_stock.total_shipping_price")} <br />
            t({formatNumber(total_labels.courier_value)})
          </th>
          <th>
            {_("products_stock.total_goods_value_with_shipping")} <br />
            t(
            {formatNumber(
              parseSafeNumber(labels.courier_value) +
                parseSafeNumber(total_labels.total_value)
            )}
            )
          </th>
        </>
      )}
    </tr>
  );
};

export default TableLabels;
