import React, { useState } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import DateRangePickerComponent from "../../date/DateRangePickerComponent";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

const FinanceSearchComponent = ({ onSearch }) => {
  const { t: _ } = useTranslation("global");
  const [isCash, setIsCash] = useState("");
  const [productId, setProductId] = useState("");
  const [status, setStatus] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [state, setState] = useState({
    selection: {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  });

  const handleApply = () => {
    const { startDate, endDate } = state.selection;
    const formattedStartDate = new Date(startDate.setHours(0, 0, 0, 0));
    const formattedEndDate = new Date(endDate.setHours(0, 0, 0, 0));

    const apiStartDate = format(formattedStartDate, "yyyy-MM-dd");
    const apiEndDate = format(formattedEndDate, "yyyy-MM-dd");

    setStartDate(apiStartDate);
    setEndDate(apiEndDate);
  };

  const handleSearch = () => {
    const searchParams = {
      is_cash: isCash,
      product_id: productId,
      status,
      start_date: startDate,
      end_date: endDate,
    };
    onSearch(searchParams);
  };

  const handleReset = () => {
    setIsCash("");
    setProductId("");
    setStatus("");
    setStartDate("");
    setEndDate("");
    setState({
      selection: {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    });

    onSearch({
      is_cash: "",
      product_id: "",
      status: "",
      start_date: "",
      end_date: "",
    });
  };
  return (
    <Form className="my-3">
      <Row className="mb-3">
        <Col md={6} lg={3}>
          <Form.Group controlId="formIsCash">
            <Form.Select
              value={isCash}
              onChange={(e) => setIsCash(e.target.value)}
            >
              <option value="">{_("finance_search.is_cash")}</option>
              <option value="1">{_("finance_search.cash")}</option>
              <option value="-1">{_("finance_search.postpaid")}</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={6} lg={3}>
          <Form.Group controlId="formProductId">
            <Form.Control
              type="text"
              value={productId}
              onChange={(e) => setProductId(e.target.value)}
              placeholder={_("finance_search.product_id")}
            />
          </Form.Group>
        </Col>
        <Col md={6} lg={3}>
  <Form.Group controlId="formStatus">
    <Form.Control
      as="select"
      value={status}
      onChange={(e) => setStatus(e.target.value)}
    >
      <option value="">{_("finance_search.status")}</option>
      <option value="new">new</option>
      <option value="sell">sell</option>
      <option value="damaged">damaged</option>
    </Form.Control>
  </Form.Group>
</Col>

        <Col md={6} lg={3}>
          <Form.Group controlId="formStartDate">
            <DateRangePickerComponent
              state={state}
              setState={setState}
              applyDateRange={handleApply}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="justify-content-between">
        <Col md={2}>
          <Button
            onClick={handleSearch}
            variant="primary"
            className=" px-5 align-self-end py-2"
            style={{
              backgroundColor: "rgb(243, 168, 55)",
              border: "none",
              borderRadius: "7px",
              color: "white",
              textAlign: "center",
            }}
          >
            {_("finance_search.search_button")}
          </Button>
        </Col>
        <Col md={1}>
          <Button
            style={{ backgroundColor: "rgb(23, 43, 77)" }}
            variant="dark"
            className="d-inline"
            onClick={handleReset}
          >
            {_("panel.reset")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default FinanceSearchComponent;
