import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation, useParams } from "react-router-dom";
import IconsGen from "../../../icons_generator/IconsGen"; 

const Settings = ({ direction }) => {
  const [_] = useTranslation("global");
  const { slug, lang } = useParams();
  const role = localStorage.getItem("role");
  const employee_type = localStorage.getItem("employee_type");
  const [isSubNavVisible, setIsSubNavVisible] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes("/panel/settings/")) {
      setIsSubNavVisible(true);
    }else{
      setIsSubNavVisible(false);

    }
  }, [location.pathname]);
  const handleClick = () => {
    setIsSubNavVisible(!isSubNavVisible);
  };
  return (
    <div className="links-container d-flex flex-column">
      
      <div className="d-flex flex-column">
        <div
          className={`feature_link ${
            lang === "en" ? "feature_link_en" : ""
          } d-flex flex-row-reverse align-items-center gap-2 py-3        
             ${isSubNavVisible ? 'active-link' : ''}`}

          dir={direction}
          // to={`/${slug}/${lang}/panel/settings`}
          onClick={handleClick} 
        >
          <IconsGen icon_name={"settings"} />
          <p>{_("panel.settings")}</p>
        </div>
        <ul className={`${isSubNavVisible ? 'd-flex' : 'd-none'} sub-nav  flex-column gap-2 px-5 pt-3`}>
          <li>
            <NavLink to={`/${slug}/${lang}/panel/settings/banners`}>
              <p className="d-inline ">{_("panel.banners")}</p>
            </NavLink>
          </li>
          <li>
            <NavLink to={`/${slug}/${lang}/panel/settings/statistics`}>
              <p className="d-inline ">{_("home.contactus")}</p>
            </NavLink>
          </li>
          <li>
            <NavLink to={`/${slug}/${lang}/panel/settings/levels`}>
              <p className="d-inline ">{_("panel.levelAffiliate")}</p>
            </NavLink>
          </li>
          <li>  <NavLink to={`/${slug}/${lang}/panel/setting/couriers`}>
              <p className="d-inline">{_("panel.couriers")}</p>
            </NavLink></li>
        </ul>
      </div>
    </div>
  );
};

export default Settings;
