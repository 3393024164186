import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom";
import TableLabels from "./TableLabels";
import TableSelectStatus from "./TableSelectStatus";
import IconsGen from "../../../icons_generator/IconsGen";
const SuppliersTableRow = ({
  payments_list,
  alt_name,
  handleLoader,
  setShow,
  setCost,
  setGovId,
  setHandling,
  setCancellation_fee,
  setExtraHandling,
}) => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  return (
    <div
      className={`table-responsive my-3 d-flex ${
        lang === "en" ? "flex-row" : "flex-row-reversed"
      } align-items-center`}
    >
      <table className="table table-borderless" htmlFor="flexCheckDefault">
        <thead>
          <TableLabels />
        </thead>
        <tbody>
          {payments_list.map((order) => {
            const dateSegments = order["created_at_formatted"].split(" ");
            return (
              <tr className="">
                <td key={order.gov_id}>
                  <div
                    className="form-check mt-3 d-flex justify-content-center align-items-center"
                    style={{ paddingLeft: "2.24rem", paddingRight: "7px" }}
                  >
                    <input
                      className="form-check-input"
                      style={{
                        border: "1px solid rgba(243, 168, 55, 1)",
                        transform: "translate(-0%, -50%)",
                      }}
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                  </div>
                </td>
                <td scope="row" className="align-middle">
                  {dateSegments[0]} <br /> {dateSegments[1]} {dateSegments[2]}
                </td>
                <td className="align-middle">{order["gov_name"]}</td>
                <td className="align-middle">{order["cost"]}</td>
                <td className="align-middle">{order["handling"]}</td>
                <td className="align-middle">{order["extra_handling"]}</td>
                <td className="align-middle">
                  {parseInt(order["cancellation_fee"]).toLocaleString()}
                </td>
                <td
                  onClick={() => {
                    setCost(order["cost"]);
                    setHandling(order["handling"]);
                    setExtraHandling(order["extra_handling"]);
                    setGovId(order["gov_id"]);
                    setCancellation_fee(order["cancellation_fee"]);
                    setShow(true);
                  }}
                  className="align-middle"
                >
                  {<IconsGen icon_name={"edit"} />}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default SuppliersTableRow;
