import React, { useEffect, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../../loader/Loader";
import AxiosController from "../../../authentication/AxiosController";
import { format } from "date-fns";
import DateRangePickerComponent from "../../../date/DateRangePickerComponent";

const get_warehouses = (slug, lang, token, handleWarehouses) => {
  AxiosController.get(`api/${slug}/${lang}/get-warehouses/`, {
    headers: { Authorization: `Bearer ${token}` },
  })
      .then((res) => {
        handleWarehouses(res.data);
      })
      .catch((e) => {});
};

const get_suppliers = (slug, lang, token, handleSuppliers) => {
  AxiosController.get(`api/${slug}/${lang}/get-suppliers/`, {
    headers: { Authorization: `Bearer ${token}` },
  })
      .then((res) => {
        handleSuppliers(res.data);
      })
      .catch((e) => {});
};

const SearchComponent = () => {
  const [_] = useTranslation("global");
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const location = useLocation();
  const Navigate = useNavigate();

  const urlParams = new URLSearchParams(location.search);

  const [productCode, setProductCode] = useState("");
  const [sku, setSku] = useState("");
  const [supplier, setSupplier] = useState("");
  const [warehouse, setWarehouse] = useState("");
  const [startDate, handleStartDate] = useState("");
  const [sellerId, handleSellerId] = useState("");
  const [endDate, handleEndDate] = useState("");
  const [loading, handleLoader] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
 const [state, setState] = useState({
    selection: {
      startDate: new Date(),
      endDate:new Date(),
      key: 'selection'
    }
  });
  const changeParam = () => {
    urlParams.set("product_code", productCode);
    urlParams.set("sku", sku);
    urlParams.set("supplier", supplier);
    urlParams.set("warehouse", warehouse);
    urlParams.set("seller_id", sellerId);
    urlParams.set("start_date", startDate);
    urlParams.set("end_date", endDate);
    Navigate(`?${urlParams.toString()}`);
  };

  const resetHandler = () => {
    setProductCode("");
    setSku("");
    setSupplier("");
    setWarehouse("");
    handleStartDate("");
    handleEndDate("");
    handleLoader(false);
    handleSellerId("");
    setSuppliers([]);
    setWarehouses([]);
    Navigate("");
  };

  const handleApply = (event, picker) => {
    const { startDate, endDate } = state.selection;
    const formattedStartDate = new Date(startDate.setHours(0, 0, 0, 0));
    const formattedEndDate = new Date(endDate.setHours(0, 0, 0, 0));

   
    const apiStartDate = format(formattedStartDate, "yyyy-MM-dd");
    const apiEndDate = format(formattedEndDate, "yyyy-MM-dd");
    handleStartDate(apiStartDate);
    handleEndDate(apiEndDate);
   
    // const startDate = picker.startDate.format("YYYY-MM-DD");
    // const endDate = picker.endDate.format("YYYY-MM-DD");
    // handleStartDate(startDate);
    // handleEndDate(endDate);
  };

  useEffect(() => {
    handleLoader(true);
    if (role === "admin") {
      get_suppliers(slug, lang, token, setSuppliers);
      get_warehouses(slug, lang, token, setWarehouses);
    }
    handleLoader(false);
  }, [role, slug, lang, token]);

  return (
      <div className="w-100 d-flex flex-column gap-1 mt-2 position-relative gap-3 my-3 pl-5 ">
        <Loader active={loading} />
        <div className="inputs-row d-flex flex-column  flex-md-row  gap-2 ">
          <Col>
            <Form.Group className="flex-grow-1" controlId="exampleForm.ControlInput1">
              <Form.Control
                  type="text"
                  placeholder={_("panel.code")}
                  onChange={(e) => setProductCode(e.target.value)}
                  value={productCode}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="flex-grow-1" controlId="exampleForm.ControlInput1">
              <Form.Control
                  type="text"
                  placeholder={"SKU"}
                  onChange={(e) => setSku(e.target.value)}
                  value={sku}
              />
            </Form.Group>
          </Col>
        </div>

        {role === "admin" && (
            <>
              <div className="inputs-row d-flex flex-column flex-md-row gap-2">
                <Col>
                  <Form.Group className="flex-grow-1" controlId="exampleForm.ControlInput1">
                    <Form.Control
                        type="text"
                        placeholder={_("panel.seller")}
                        onChange={(e) => handleSellerId(e.target.value)}
                        value={sellerId}
                    />
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group className="flex-grow-1">
                    <Form.Select
                        onChange={(e) => setSupplier(e.target.value)}
                        value={supplier}
                    >
                      <option value={""} disabled>
                        الموردين
                      </option>
                      {suppliers.map((supplier) => (
                          <option key={supplier.id} value={supplier.id}>
                            {supplier.name}
                          </option>
                      ))}
                      <option value={"Rejected"}>{_("panel.Rejected")}</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </div>

              <div className="inputs-row d-flex flex-column flex-md-row gap-2">
                <Col>
                  <Form.Group className="flex-grow-1">
                    <Form.Select
                        onChange={(e) => setWarehouse(e.target.value)}
                        value={warehouse}
                    >
                      <option value={""} disabled>
                        المخازن
                      </option>
                      {warehouses.map((warehouse, index) => (
                          <option key={index} value={warehouse.id}>
                            {warehouse.title}
                          </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </div>
            </>
        )}

        <div className="inputs-row d-flex w-100 gap-2 flex-column  flex-md-row">
          <Col>
            <Form.Group className="flex-grow-1" controlId="exampleForm.ControlInput1">
            <DateRangePickerComponent
                  state={state}
                  setState={setState}
                  applyDateRange={() => handleApply()}
                />
              {/* <DateRangePicker onApply={handleApply}>
                <input type="text" className="form-control" />
              </DateRangePicker> */}
            </Form.Group>
          </Col>
        </div>

        <div className="action-inputs">
          <input
              className="px-5 align-self-end py-2"
              style={{
                backgroundColor: "rgb(243, 168, 55)",
                border: "none",
                borderRadius: "7px",
                color: "white",
                textAlign: "center",
              }}
              onClick={changeParam}
              type="submit"
              value={_("panel.search")}
          />
          <Button
              style={{ backgroundColor: "rgb(23, 43, 77)" }}
              variant="dark"
              className="d-inline"
              onClick={resetHandler}
          >
            {_("panel.reset")}
          </Button>
        </div>
      </div>
  );
};

export default SearchComponent;
