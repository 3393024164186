import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../loader/Loader";
import { Button, Col, Table, Form } from "react-bootstrap";
import { Helmet } from "react-helmet"; 
import AxiosController from "../../authentication/AxiosController";
import * as XLSX from "xlsx";
import IconsGen from "../../icons_generator/IconsGen";
import DateRangePickerComponent from "../../date/DateRangePickerComponent";
import { format } from "date-fns";

const FinanceSales = () => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const box_head_dir = lang === "en" ? "ltr" : "rtl";
  const [loading, handleLoader] = useState(false);
  const [allData, handleAllData] = useState([]);
  const [search, handleSearch] = useState(false);
  const token = localStorage.getItem("token");
  const location = useLocation();
  const Navigate = useNavigate();
  const urlParams = new URLSearchParams(location.search);
  const [marketer_id, handleMarketer_id] = useState("");
  const [product_id, handleProduct_id] = useState("");
  const [days, handleDays] = useState("");
  const [userId, handleUserId] = useState("");
  const [seller_id, handleSeller_id] = useState("");
  const [startDate, handleStartDate] = useState("");
  const [endDate, handleEndDate] = useState("");
  const [state, setState] = useState({
    selection: {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  });
  const changeParam = () => {
    urlParams.set("marketer_id", marketer_id);
    urlParams.set("product_id", product_id);
    urlParams.set("days", days);
    urlParams.set("user_id", userId);
    urlParams.set("seller_id", seller_id);
    urlParams.set("start_date", startDate);
    urlParams.set("end_date", endDate);
    Navigate(`?${urlParams.toString()}`);
  };

  const resetHandler = () => {
    handleMarketer_id("");
    handleProduct_id("");
    handleDays("");
    handleUserId("");
    handleEndDate("");
    Navigate("");
  };

  const handleApply = () => {
    const { startDate, endDate } = state.selection;
    const formattedStartDate = new Date(startDate.setHours(0, 0, 0, 0));
    const formattedEndDate = new Date(endDate.setHours(0, 0, 0, 0));

    // Format dates to YYYY-MM-DD for API
    const apiStartDate = format(formattedStartDate, "yyyy-MM-dd");
    const apiEndDate = format(formattedEndDate, "yyyy-MM-dd");
    handleStartDate(apiStartDate);
    handleEndDate(apiEndDate);
  };

  const get_financials_sellers = () => {
    handleLoader(true);

    AxiosController.get(`api/${slug}/${lang}/reports/sales`, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        start_date: startDate,
        end_date: endDate,
        marketer_id: marketer_id,
        seller_id: seller_id,
        product_id: product_id,
      },
    })
      .then((res) => {
        handleLoader(false);
        handleAllData(res?.data);
      })
      .catch((error) => {
        handleLoader(false);
        console.error(error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    changeParam();
    get_financials_sellers();
  };

  const downloadExcel = () => {

    const formattedData = allData.map((item, index) => ({
      "#": index + 1,
      "اليوم": item.day,
      "التاريخ": new Date(item.created_at).toLocaleDateString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' }),
      "رقم الطلب": item.track,
      "اسم المنتج ورقم المنتج": item.product_info,
      "اسم التاجر": item.seller_name,
      "اسم المسوق": item.marketer_name,
      "الكمية المباعة": item.quantity,
      "المبلغ": item.total_price,
      "عمولة التاجر": item.total_seller_commission,
      "عمولة المسوق": item.total_marketer_commission,
      "عمولة الشركة": item.total_sys_commission,
      "اجمالي": item.total_commissions,
      "مصاريف الشحن": item.shipping_cost,
      "ربح فرق الشحن": item.shipping_profit,
      "مصاريف فرق الشحن": item.shipping_loss,
      "قابل للسحب بدون مصاريف الشحن": item.is_cash_delivery,
      "تم التوصيل": item.is_delivered,
      "جاري الشحن": item.is_shipped,
      "الغاء في الطريق": item.is_pending_cancel,
      "مرجع في الطريق": item.is_pending_return,
      "المخزون": item.left_stock || "غير متوفر"
    }));

    // Create a new workbook and add the table data
    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "finance_sales");

    // Export the workbook to an Excel file
    XLSX.writeFile(wb, "finance_sales.xlsx");
  };
  return (
    <div className="flex-grow-1 stats-component">
      <Helmet>
        <title>{_("panel.finance_sales")}</title>
      </Helmet>
      <Button
        className="fs-5 px-5 w-25 mb-4"
        style={{
          backgroundColor: "rgb(89, 198, 201)",
          border: "none",
          borderRadius: "7px",
        }}
        onClick={() => {
          handleSearch(!search);
        }}
      >
        {_("panel.search")}
      </Button>
      {search ? (
        <form onSubmit={handleSubmit}>
          <div className="w-100 d-flex flex-column gap-1">
            <div className="inputs-row d-flex w-100 gap-2 flex-column  flex-md-row">
              <>


                <Col>
                  <Form.Group className="mb-3 flex-grow-1">
                    <Form.Control
                      type="text"
                      placeholder={_("panel.seller")}
                      onChange={(e) => {
                        handleSeller_id(e.target.value);
                      }}
                      value={seller_id}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3 flex-grow-1">
                    <Form.Control
                      type="text"
                      placeholder={_("panel.themarketer")}
                      onChange={(e) => {
                        handleMarketer_id(e.target.value);
                      }}
                      value={marketer_id}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3 flex-grow-1">
                    <Form.Control
                      type="text"
                      placeholder={_("panel.product")}
                      onChange={(e) => {
                        handleProduct_id(e.target.value);
                      }}
                      value={product_id}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <DateRangePickerComponent
                      state={state}
                      setState={setState}
                      applyDateRange={() => handleApply()}
                    />
                    {/* <DateRangePicker onApply={handleApply}>
                      <input type="text" className="form-control" />
                    </DateRangePicker> */}
                  </Form.Group>
                </Col>
              </>
            </div>
            <div className="action-inputs mt-3">
              <input
                className="px-5 align-self-end py-2"
                style={{
                  backgroundColor: "rgb(243, 168, 55)",
                  border: "none",
                  borderRadius: "7px",
                  color: "white",
                  textAlign: "center",
                }}
                type="submit"
                value={_("panel.search")}
              />
              <Button
                style={{ backgroundColor: "rgb(23, 43, 77)" }}
                variant="dark"
                onClick={resetHandler}
              >
                {_("panel.reset")}
              </Button>
            </div>
          </div>
        </form>
      ) : (
        ""
      )}

      <div className="reports-container my-3 d-flex flex-column gap-2 px-2 position-relative ">
        <Loader active={loading} />

        <div className="reports-header d-flex flex-column flex-md-row py-2 justify-content-between flex-wrap">
          <h3 className="fs-2 mb-4" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.finance_sales")}
          </h3>
          <button
            className="btn   text-nowrap"
            style={{ border: "1px solid #172B4D", color: "#172B4D" }}
            onClick={downloadExcel}
            disabled={loading}
          >
            <IconsGen icon_name={"excel"} /> {_("panel.downloadExcel")}
          </button>
        </div>
        <div className="row" dir={box_head_dir} style={{ height: "1200px" }}>
          <div
            className="col-12"
            style={{ position: "relative", overflowX: "auto", height: "100%" }}
          >
            <Table
              responsive
              dir={box_head_dir}
              className="reports-table text-nowrap text-center">
              <thead>
                <tr className="text-center">
                  <th>م</th>
                  <th>اليوم</th>
                  <th>التاريخ</th>
                  <th>رقم الطلب </th>
                  <th>اسم المنتج ورقم المنتج </th>
                  <th>اسم التاجر</th>
                  <th>اسم المسوق</th>
                  <th>  الكمية المباعة</th>
                  <th>المبلغ</th>
                  <th>عمولة التاجر</th>
                  <th>عمولة المسوق</th>
                  <th>عمولة الشركة</th>
                  <th>اجمالي</th>
                  <th>مصاريف الشحن</th>
                  <th>ربح فرق الشحن</th>
                  <th>مصاريف فرق الشحن</th>
                  <th>قابل للسحب بدون مصاريف الشحن</th>
                  <th>تم التوصيل</th>
                  <th>جاري الشحن</th>
                  <th>الغاء في الطريق</th>
                  <th>مرجع في الطريق</th>
                  <th>المخزون</th>
                </tr>
              </thead>
              <tbody>
                {allData && allData?.length > 0 ? (
                  allData?.map((item, index) => (
                    <tr key={index} className="text-center">
                      <td>{index + 1}</td>
                      <td>{item?.day}</td>
                      <td>{new Date(item?.created_at).toLocaleDateString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' })}</td>
                      <td>{item?.track}</td>
                      <td>{item?.product_info}</td>
                      <td>{item?.seller_name}</td>
                      <td>{item?.marketer_name}</td>
                      <td>{item?.quantity}</td>
                      <td>{item?.total_price}</td>
                      <td>{item?.total_seller_commission}</td>
                      <td>{item?.total_marketer_commission}</td>
                      <td>{item?.total_sys_commission}</td>
                      <td>{item?.total_commissions}</td>
                      <td>{item?.shipping_cost}</td>
                      <td>{item?.shipping_profit}</td>
                      <td>{item?.shipping_loss}</td>
                      <td>{item?.is_cash_delivery}</td>
                      <td>{item?.is_delivered}</td>
                      <td>{item?.is_shipped}</td>
                      <td>{item?.is_pending_cancel}</td>
                      <td>{item?.is_pending_return}</td>
                      <td>{item?.left_stock ? item?.left_stock : "غير متوفر"}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="22" className="text-center">No data available</td>
                  </tr>
                )}
              </tbody>

            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinanceSales;
