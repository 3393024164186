import React, { useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import "primereact/resources/primereact.min.css"; // Import necessary PrimeReact styles

export default function Top() {
  const [dateRange, setDateRange] = useState([
    new Date(2020, 7, 9),
    new Date(2025, 7, 9),
  ]);

  const topCustomers = [
    {
      id: 1,
      name: "Demo User",
      orders: 379,
      avatar: "https://placehold.co/45",
    },
    {
      id: 2,
      name: "Raju Islam",
      orders: 234,
      avatar: "https://placehold.co/45",
    },
    {
      id: 3,
      name: "Ellon Musk",
      orders: 126,
      avatar: "https://placehold.co/45",
    },
    {
      id: 4,
      name: "Natalie Sporer",
      orders: 98,
      avatar: "https://placehold.co/45",
    },
    {
      id: 5,
      name: "arafat",
      orders: 72,
      avatar: "https://placehold.co/45",
    },
    {
      id: 6,
      name: "Mr. Richard Gottlieb IV",
      orders: 13,
      avatar: "https://placehold.co/45",
    },
    {
      id: 7,
      name: "Pedro Flatley",
      orders: 12,
      avatar: "https://placehold.co/45",
    },
    {
      id: 8,
      name: "Glennie Lueilwitz",
      orders: 11,
      avatar: "https://placehold.co/45",
    },
  ];

  const topProducts = [
    {
      id: 1,
      name: "Women Dresses",
      rating: 5.0,
      image: "https://placehold.co/45",
    },
    {
      id: 2,
      name: "Chowdhury Gold",
      rating: 5.0,
      image: "https://placehold.co/45",
    },
    {
      id: 3,
      name: "watches",
      rating: 5.0,
      image: "https://placehold.co/45",
    },
    {
      id: 4,
      name: "iPhone 15 Pro 128GB Dress Ver...",
      rating: 5.0,
      image: "https://placehold.co/45",
    },
    {
      id: 5,
      name: "gazipur",
      rating: 5.0,
      image: "https://placehold.co/45",
    },
    {
      id: 6,
      name: "Sunburn Frame Sunglass Men ...",
      rating: 5.0,
      image: "https://placehold.co/45",
    },
    {
      id: 7,
      name: "RETRAC CABLE KITCHEN FAUCET S...",
      rating: 5.0,
      image: "https://placehold.co/45",
    },
    {
      id: 8,
      name: "eat",
      rating: 5.0,
      image: "https://placehold.co/45",
    },
  ];

  const customerNameTemplate = (rowData) => {
    return (
      <div className="d-flex align-items-center">
        <img
          src={rowData.avatar}
          alt={rowData.name}
          className="rounded-circle ms-2"
          style={{ width: "45px", height: "45px" }}
        />
        <span>{rowData.name}</span>
      </div>
    );
  };

  const productNameTemplate = (rowData) => {
    return (
      <div className="d-flex align-items-center">
        <img
          src={rowData.image}
          alt={rowData.name}
          className="ms-2 rounded-circle"
          style={{ width: "45px", height: "45px" }}
        />
        <span>{rowData.name}</span>
      </div>
    );
  };

  const ratingTemplate = (rowData) => {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <span className="text-warning">{rowData.rating.toFixed(1)}</span>
      </div>
    );
  };

  return (
    <Container fluid className="py-3" style={{ direction: "rtl" }}>
      <Row className="mb-3">
        <Col xs={3}>
          <Calendar
            value={dateRange}
            onChange={(e) => setDateRange(e.value)}
            selectionMode="range"
            readOnlyInput
            className="w-100 px-2 py-1 top-calendar-input"
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Card className="border-0 shadow">
            <Card.Header className="bg-white border-0">
              <h5 className="mb-0 text-center">أعلى المسوقين طلبا في الشهر</h5>
            </Card.Header>
            <Card.Body>
              <DataTable value={topCustomers} className="datatable-sm">
                <Column
                  field="name"
                  header="Top Customer"
                  body={customerNameTemplate}
                  key="name"
                />
                <Column field="orders" header="Orders" key="orders" />
              </DataTable>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="border-0 shadow">
            <Card.Header className="bg-white border-0">
              <h5 className="mb-0 text-center">أعلى المنتجات طلبا في الشهر</h5>
            </Card.Header>
            <Card.Body>
              <DataTable value={topProducts} className="datatable-sm">
                <Column
                  field="name"
                  header="Top Rating Products"
                  body={productNameTemplate}
                  key="name"
                />
                <Column
                  field="rating"
                  header="Rating"
                  body={ratingTemplate}
                  key="rating"
                />
              </DataTable>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
