import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import StoreTableLabels from "./StoreTableLabels";
import IconsGen from "../../icons_generator/IconsGen";
import Loader from "../../loader/Loader";
import AxiosController from "../../authentication/AxiosController";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

const MyStoresTableRow = ({ stores_list, fetchStores }) => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const token = localStorage.getItem("token");
  const Navigate = useNavigate();
  const [loading, handleLoader] = useState(false);
  const [deleteErr, setDeleteErr] = useState(false);
  const delete_store = (id) => {
    handleLoader(true);
    AxiosController.delete(`api/${slug}/${lang}/store/delete/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        fetchStores();
        handleLoader(false);
      })
      .catch((e) => {
        setDeleteErr(e.response.data.error ? e.response.data.error.store : "");
        handleLoader(false);
      });
  };
  const delete_product = (product_id) => {
    AxiosController.post(
      `apiv2/${slug}/${lang}/builder/delete-store/`,
      { id: product_id },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        Navigate(`/${slug}/${lang}/panel/my-shop/create-store`);
      })
      .catch((e) => {});
  };
  const accept = (product_id) => {
    delete_product(product_id);
    // toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
  };

  const reject = () => {
    // toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
  };
  const confirm2 = (product_id) => {
    confirmDialog({
      message: _("panel.deleteStore"),
      header: _("panel.deleteStore"),
      icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      accept: () => {
        accept(product_id);
      },
      reject,
    });
  };

  return (
    <div
      className={`table-responsive my-3 d-flex ${
        lang === "en" ? "flex-row" : "flex-row-reversed"
      } align-items-center`}
    >
      <>
        <ConfirmDialog style={{ direction: lang == "en" ? "ltr" : "rtl" }} />
      </>
      <Loader active={loading} />
      <table className="table table-borderless" htmlFor="flexCheckDefault">
        <thead>
          <StoreTableLabels />
        </thead>
        <tbody>
          {stores_list.map((store) => {
            return (
              <tr className="">
                <td>
                  {store["sub_domain"]} <br /> ID#{store["id"]}{" "}
                </td>
                <td>
                  <a
                    className="fs-5"
                    style={{ color: "rgb(243, 168, 55)" }}
                    href={"https://" + store["sub_domain"] + ".tejarty.store"}
                    target="_blank"
                  >
                    {_("panel.visit")}
                  </a>
                </td>
                                <td>{store.favicon ?<img src={store?.favicon } width={"90px" } height={"90px"}   alt='product'/> : "لا يوجد"  }</td>
                                <td>{store.icon ?<img src={store?.icon } width={"90px" } height={"90px"}   alt='product'/> : "لا يوجد"  }</td>


                <td>
                  <NavLink to={`/${slug}/${lang}/panel/my-shop/create-store`}>
                    {<IconsGen icon_name={"enter"} />}
                  </NavLink>
                </td>

                <td
                  onClick={() => {
                    confirm2(store["id"]);
                  }}
                >
                  {<IconsGen icon_name={"delete"} />} <br />{" "}
                  {deleteErr ? deleteErr : ""}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default MyStoresTableRow;
