import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AxiosController from "../../../../components/authentication/AxiosController";
import { useTranslation } from "react-i18next";
import Loader from "../../../../components/loader/Loader";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import BaseServerUrl from "../../../authentication/BaseServerUrl";
import IconsGen from "../../../icons_generator/IconsGen";
import { Editor } from "primereact/editor";

import "./AddProduct.css";
import { Alert, Button, Form } from "react-bootstrap";
// import Sizes from "../../../authentication/Sizes";
import VariantsProps from "./Components/VariantsProps";
import { Helmet } from "react-helmet";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const fetchCategories = (slug, lang, token, handleCategories, handleLoader) => {
  AxiosController.get(`api/${slug}/${lang}/categories`, {
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      handleCategories(res.data);
      handleLoader(false);
    })
    .catch((e) => {
      handleLoader(false);
    });
};

const AddProduct = () => {
  const { slug, lang } = useParams();
  const [loading, handleLoader] = useState(true);
  const [categories, handleCategories] = useState([]);

  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const Navigate = useNavigate();
  const [instanceObject, setInstanceObj] = useState({
    quantity: 0,
    warehouse: 0,
    quantityErr: 0,
    warehouseErr: "",
    seller_commision: 0,
    seller_commisionErr: "",
    sys_commision: 0,
    sys_commisionErr: 0,
    sku: "",
  });

  // Product data
  const [productName, handleProductName] = useState("");
  const [productENName, handleENProductName] = useState("");
  const [uploadedProductId2, handleUploadedProductId] = useState(0);
  let uploadedProductId = 0;
  const [productCat, handleProductCat] = useState("");
  const [productDesc, handleProductDesc] = useState("");
  const [productENDesc, handleENProductDesc] = useState("");
  const [productDrive, handleProductDrive] = useState("");
  const [sellerId, handleSellerId] = useState("");
  const [sellerIdErr, handleSellerIdErr] = useState("");

  const [success, handleSuccess] = useState("");

  const [productNameErr, handleProductNameErr] = useState("");
  const [productCatErr, handleProductCatErr] = useState("");
  const [productDescErr, handleProductDescErr] = useState("");
  const [productDriveErr, handleProductDriveErr] = useState("");
  const [productImgsErr, handleProductImgsErr] = useState("");
  const [productVariants, handleProductVariants] = useState([instanceObject]);

  const pond = useRef(null);

  const [files, setFiles] = useState([]);
  const [Sizes, setSizes] = useState([]);
  const [Colors, setColors] = useState([]);
  const [_] = useTranslation("global");

  const acceptedFileTypes = [
    "image/jpeg",
    "image/png",
    "image/gif",
    "image/jpg",
  ];
  const box_head_dir = lang === "en" ? "ltr" : "rtl";
  const fetchColors = () => {
    AxiosController.get(`apiv2/${slug}/${lang}/colors`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setColors(res.data.map((v) => v.name));
      })
      .catch((e) => {});
  };
  const fetchSizes = () => {
    AxiosController.get(`apiv2/${slug}/${lang}/sizes`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setSizes(res.data.map((v) => v.name));
      })
      .catch((e) => {});
  };
  const create_product = () => {
    handleLoader(true);

    handleProductImgsErr("");
    let valid = true;
    if (files.length <= 0) {
      handleProductImgsErr(_("panel.uploadImgs"));
      valid = false;
    }

    if (productVariants.length > 0 && valid) {
      valid = true;
      productVariants.map((variant, index) => {
        let offerInstance = variant;
        offerInstance.quantityErr = "";

        offerInstance.seller_commisionErr = "";

        offerInstance.warehouseErr = "";
        if (
          parseInt(variant.quantity) < 0 ||
          isNaN(parseInt(variant.quantity))
        ) {
          // valid = false;
          // variant.quantityErr = _("panel.variantPriceErr");
        } else if (
          parseInt(variant.seller_commision) < 0 ||
          isNaN(parseInt(variant.seller_commision))
        ) {
          valid = false;
          variant.seller_commisionErr = _("panel.variantPriceErr");
        } else if (productName == "" && productENName == "") {
          valid = false;
          handleProductNameErr(_("panel.required"));
        } else if (productDesc == "" && productENDesc == "") {
          valid = false;
          handleProductDescErr(_("panel.required"));
        }

        return offerInstance;
      });
    }
    if (valid) {
      AxiosController.post(
        `api/${slug}/${lang}/product/add-product`,
        {
          title: productName,
          en_title: productENName,
          google_drive_link: productDrive,
          description: productDesc,
          en_description: productENDesc,
          category_id: productCat,
          seller_id: sellerId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
        .then((res) => {
          handleUploadedProductId(res.data.product_id);
          uploadedProductId = res.data.product_id;
          handleProductName("");
          handleENProductName("");
          handleProductCat(0);
          handleProductDesc("");
          handleProductDrive("");
          handleProductNameErr("");
          handleProductCatErr("");
          handleProductDescErr("");
          handleENProductDesc("");
          handleProductDriveErr("");
          productVariants.forEach((variant, index) => {
            AxiosController.post(
              `api/${slug}/${lang}/variety/add-variety`,
              {
                product_id: uploadedProductId,
                color: variant.color,
                size: variant.size,
                sys_commision: variant.sys_commision ?? 0,
                seller_commision: variant.seller_commision ?? 0,
                sku: variant.sku,
              },
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
              .then((res) => {
                handleProductVariants([instanceObject]);
                if (index == productVariants.length - 1) {
                  pond.current.processFiles().then(() => {});
                }
              })
              .catch((e) => {
                handleLoader(false);
              });
          });
        })
        .catch((e) => {
          handleProductNameErr(
            e.response.data.error.title ? e.response.data.error.title[0] : ""
          );
          handleProductCatErr(
            e.response.data.error.category_id
              ? e.response.data.error.category_id[0]
              : ""
          );
          handleProductDescErr(
            e.response.data.error.description
              ? e.response.data.error.description[0]
              : ""
          );
          handleProductDriveErr(
            e.response.data.error.google_drive_link
              ? e.response.data.error.google_drive_link[0]
              : ""
          );
          handleSellerIdErr(
            e.response.data.error.seller_id
              ? e.response.data.error.seller_id[0]
              : ""
          );
          handleLoader(false);
        });
    } else {
      handleLoader(false);
    }
  };

  useEffect(() => {
    handleLoader(true);
    fetchCategories(slug, lang, token, handleCategories, handleLoader);
    fetchColors();
    fetchSizes();
  }, [slug, lang, token]);
  return (
    <div
      class="add-product-container d-flex flex-column gap-2 my-3 px-4 "
      style={{ flexGrow: 4, position: "relative" }}
    >
      <Helmet>
        <title>{_("panel.addProduct")}</title>
      </Helmet>
      <Loader active={loading} />
      <div className="add-header" dir={box_head_dir}>
        <h2 className="mb-3">{_("panel.addProduct")}</h2>
        <p>{_("panel.addProductSlogan")}</p>
      </div>
      <div
        className="add-body1 d-flex flex-column flex-md-row gap-4 mb-4"
        dir={box_head_dir}
      >
        <div className="right-side d-flex flex-column">
          <div className="right-head">
            <h3>{_("panel.product_info")}</h3>
            <p>{_("panel.makesuredataisright")}</p>
          </div>
          {role == "admin" || role == "employee" ? (
            <div className="mb-3">
              <label htmlFor="productName" className="form-label">
                {_("panel.seller")}
              </label>
              <input
                type="text"
                className="form-control py-2 px-3 egp"
                id="productName"
                aria-describedby="emailHelp"
                value={sellerId}
                onInput={(e) => {
                  handleSellerId(e.target.value);
                }}
              />
            </div>
          ) : (
            ""
          )}
          {sellerIdErr ? <Alert variant={"danger"}>{sellerIdErr}</Alert> : ""}
          <div className="mb-3">
            <label htmlFor="productName" className="form-label">
              {_("panel.productName")}
            </label>
            <input
              type="text"
              className="form-control py-2 px-3 egp"
              id="productName"
              aria-describedby="emailHelp"
              value={productName}
              onInput={(e) => {
                handleProductName(e.target.value);
              }}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="productName" className="form-label">
              {_("panel.enproductName")}
            </label>
            <input
              type="text"
              className="form-control py-2 px-3 egp"
              id="productName"
              aria-describedby="emailHelp"
              value={productENName}
              onInput={(e) => {
                handleENProductName(e.target.value);
              }}
            />
          </div>
          {productNameErr ? (
            <Alert variant={"danger"}>{productNameErr}</Alert>
          ) : (
            ""
          )}
          <div className="mb-3">
            <label htmlFor="category" className="form-label">
              {_("panel.category")}
            </label>
            <Form.Select
              aria-label="Default select example"
              value={productCat}
              onChange={(e) => {
                handleProductCat(e.target.value);
              }}
            >
              <option value={0}></option>
              {categories.length
                ? categories.map((cat) => {
                    return <option value={cat.id}>{cat.name}</option>;
                  })
                : ""}
            </Form.Select>
          </div>
          {productCatErr ? (
            <Alert variant={"danger"}>{productCatErr}</Alert>
          ) : (
            ""
          )}

          <div className="mb-3">
            <label htmlFor="driveLink" className="form-label">
              {_("panel.drivelink")}
            </label>
            <input
              type="text"
              className="form-control py-2 px-3 egp"
              id="driveLink"
              aria-describedby="emailHelp"
              value={productDrive}
              onInput={(e) => {
                handleProductDrive(e.target.value);
              }}
            />
          </div>
          {productDriveErr ? (
            <Alert variant={"danger"}>{productDriveErr}</Alert>
          ) : (
            ""
          )}
        </div>
        <div
          className="left-side d-flex flex-column align-items-center  text-center py-4 px-4 gap-2"
          style={{ height: "fit-content", overflow: "scroll", minWidth: "35%" }}
        >
          <div className="top py-3">
            <h3>{_("panel.productImgs")}</h3>
          </div>

          <FilePond
            acceptedFileTypes={acceptedFileTypes}
            files={files}
            ref={pond}
            onprocessfiles={() => {
              setTimeout(() => {
                setFiles([]);
                handleSuccess(_("panel.productCreated"));
                handleLoader(false);
                Navigate(
                  `/${slug}/${lang}/panel/product/${uploadedProductId2}`
                );
              }, 200);
            }}
            onupdatefiles={setFiles}
            allowMultiple={true}
            maxFiles={6}
            server={{
              url:
                BaseServerUrl +
                `/api/${slug}/${lang}/product-image/add-images/${uploadedProductId2}`,
              headers: {
                Authorization: `Bearer ${token}`,
              },
              process: {
                onload: (e) => {},
              },
            }}
            instantUpload={false}
            name="image"
            labelIdle={`${_(
              "panel.draglandpage"
            )} <span class="filepond--label-action">${_(
              "panel.browse"
            )}</span>`}
          />
          {productImgsErr ? (
            <Alert variant={"danger"}>{productImgsErr}</Alert>
          ) : (
            ""
          )}
        </div>
      </div>
      <div
        className="add-body1 d-flex flex-column-reverse flex-md-row gap-4"
        dir={box_head_dir}
      >
        <div className="right-side d-flex flex-column">
          {productDescErr ? (
            <Alert variant={"danger"}>{productDescErr}</Alert>
          ) : (
            ""
          )}
          <div className="mb-3">
            <label htmlFor="category" className="form-label">
              {_("panel.description")}
            </label>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              {/* <DescriptionEditor handleProductDesc={handleProductDesc} /> */}
              <Editor
                value={productDesc}
                onTextChange={(e) => handleProductDesc(e.htmlValue)}
                style={{ height: "320px" }}
                dir="ltr"
              />
            </Form.Group>
          </div>
          <div className="mb-3">
            <label htmlFor="category" className="form-label">
              {_("panel.endescription")}
            </label>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              {/* <DescriptionEditor handleProductDesc={handleProductDesc} /> */}
              <Editor
                value={productENDesc}
                onTextChange={(e) => handleENProductDesc(e.htmlValue)}
                style={{ height: "320px" }}
                dir="ltr"
              />
            </Form.Group>
          </div>
          <label
            htmlFor="exampleInputEmail1"
            className="form-label d-flex justify-content-between"
          >
            {_("panel.productDetails")}
            <Button
              style={{
                backgroundColor: "rgb(243, 168, 55)",
                color: "white",
                border: "none",
              }}
              className="fs-5 px-3 py-1"
              onClick={() => {
                handleProductVariants([...productVariants, instanceObject]);
              }}
            >
              {_("panel.newoffer")}
            </Button>
          </label>
          <div className="mb-3 d-flex flex-column">
            <VariantsProps
              Variants={productVariants}
              setVariants={handleProductVariants}
              stdObj={instanceObject}
              setStdObj={setInstanceObj}
            />
            {productVariants.map((variant, index) => {
              return (
                <div
                  className="offers d-flex gap-4 flex-md-row flex-column align-items-center"
                  style={{ width: "100%" }}
                >
                  {role !== "fulfillment" ? (
                    <div className="flex-grow-1">
                      <Form.Label htmlFor="inputPassword5">
                        {_("panel.price")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="inputPassword5"
                        aria-describedby="passwordHelpBlock"
                        value={variant.seller_commision}
                        onInput={(e) => {
                          console.log(
                            productVariants.map((variant2, index2) => {
                              if (index !== index2) {
                                return variant2;
                              }
                              let offerInstance = { ...variant2 };
                              offerInstance.seller_commision = e.target.value;
                              return offerInstance;
                            })
                          );
                          handleProductVariants(() => {
                            return productVariants.map((variant2, index2) => {
                              if (index !== index2) {
                                return variant2;
                              }
                              let offerInstance = { ...variant2 };
                              offerInstance.seller_commision = e.target.value;
                              return offerInstance;
                            });
                          });
                        }}
                      />
                      {variant.seller_commisionErr ? (
                        <Alert variant={"danger"}>
                          {variant.seller_commisionErr}
                        </Alert>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {/* {Object.keys(variant).includes("color") ? (
                    <div className="flex-grow-1">
                      <Form.Label htmlFor="inputPassword5">
                        {_("panel.color")}
                      </Form.Label>
                      <Form.Control
                        type="color"
                        style={{ width: "100%" }}
                        id="exampleColorInput"
                        title="Choose your color"
                        onInput={(e) => {
                          handleProductVariants(() => {
                            return productVariants.map((variant2, index2) => {
                              if (index !== index2) {
                                return variant2;
                              }
                              let offerInstance = {...variant2};
                              offerInstance.color = e.target.value;
                              return offerInstance;
                            });
                          });
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )} */}

                  {Object.keys(variant).includes("color") ? (
                    <div className="flex-grow-1">
                      <Form.Label htmlFor="inputPassword5">
                        {_("panel.color")}
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        value={variant.color}
                        onChange={(e) => {
                          handleProductVariants(() => {
                            return productVariants.map((variant2, index2) => {
                              if (index !== index2) {
                                return variant2;
                              }
                              let offerInstance = variant2;
                              offerInstance.color = e.target.value;
                              return offerInstance;
                            });
                          });
                        }}
                      >
                        <option value={""}></option>
                        {Colors.length
                          ? Colors.map((Color) => {
                              return <option value={Color}>{Color}</option>;
                            })
                          : ""}
                      </Form.Select>
                    </div>
                  ) : (
                    ""
                  )}

                  {Object.keys(variant).includes("size") ? (
                    <div className="flex-grow-1">
                      <Form.Label htmlFor="inputPassword5">
                        {_("panel.size")}
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        //   value={productCat}
                        onChange={(e) => {
                          handleProductVariants(() => {
                            return productVariants.map((variant2, index2) => {
                              if (index !== index2) {
                                return variant2;
                              }
                              let offerInstance = { ...variant2 };
                              offerInstance.size = e.target.value;
                              return offerInstance;
                            });
                          });
                        }}
                      >
                        <option value={""}></option>
                        {Sizes.length
                          ? Sizes.map((Size) => {
                              return <option value={Size}>{Size}</option>;
                            })
                          : ""}
                      </Form.Select>
                    </div>
                  ) : (
                    ""
                  )}
                  {role === "fulfillment" ? (
                    <div className="flex-grow-1">
                      <Form.Label htmlFor="inputPassword5">SKU</Form.Label>
                      <Form.Control
                        type="text"
                        id="inputPassword5"
                        aria-describedby="passwordHelpBlock"
                        value={variant.sku}
                        onInput={(e) => {
                          handleProductVariants(() => {
                            return productVariants.map((variant2, index2) => {
                              if (index !== index2) {
                                return variant2;
                              }
                              let offerInstance = { ...variant2 };
                              offerInstance.sku = e.target.value;
                              return offerInstance;
                            });
                          });
                        }}
                      />
                      {variant.seller_commisionErr ? (
                        <Alert variant={"danger"}>
                          {variant.seller_commisionErr}
                        </Alert>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  <div
                    className="flex-grow-1 mt-4"
                    onClick={() => {
                      handleProductVariants(() => {
                        return productVariants.filter((value, index2) => {
                          return index != index2;
                        });
                      });
                    }}
                  >
                    <IconsGen icon_name={"delete"} />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="mb-3">
            <Button
              style={{
                backgroundColor: "rgba(89, 198, 201, 1)",
                color: "white",
                border: "none",
                width: "100%",
              }}
              className="fs-5"
              onClick={() => {
                handleProductNameErr("");
                handleProductCatErr("");
                handleProductDescErr("");
                handleProductDriveErr("");
                create_product();
              }}
            >
              {_("panel.addProduct")}
            </Button>
          </div>
          {success ? <Alert variant={"success"}>{success}</Alert> : ""}
        </div>
      </div>
    </div>
  );
};

export default AddProduct;
