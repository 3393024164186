import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation, useParams } from "react-router-dom";
import IconsGen from "../../../icons_generator/IconsGen";
import ProtectedSidebarRoute from "../../../authentication/ProtectedSidebarRoute";
import Roles from "../../../authentication/Roles";
import AddProduct from "./AddProduct";
import Favorites from "./Favorites";
import EmployeeTypes from "../../../authentication/EmployeeTypes";
import Exclusive from "./Exclusive";
import AxiosController from "../../../authentication/AxiosController";

const LinkOutside = ({ direction }) => {
  const [_] = useTranslation("global");
  const { slug, lang } = useParams();
  const role = localStorage.getItem("role");
  const employee_type = localStorage.getItem("employee_type");
  const [isSubNavVisible, setIsSubNavVisible] = useState(false);
  const [userId, setUserId] = useState(null);
  const token = localStorage.getItem("token");
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes("/outside-integrations/")) {
      setIsSubNavVisible(true);
    } else {
      setIsSubNavVisible(false);
    }
  }, [location.pathname]);

  const handleClick = () => {
    setIsSubNavVisible(!isSubNavVisible);
  };
  const current_user = async () => {
    AxiosController.get(`api/${lang}/auth/userdata`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setUserId(res.data["id"]);
      })
      .catch(() => {});
  };
  useEffect(() => {
    current_user();
  }, []);
  return (
    <div className="links-container d-flex flex-column">
      <div
        className={`feature_link ${
          lang === "en" ? "feature_link_en" : ""
        } d-flex flex-row-reverse align-items-center gap-2 py-3        ${
          isSubNavVisible ? "active-link" : ""
        }`}
        dir={direction}
        onClick={handleClick}

        // to={`/${slug}/${lang}/panel/outside-integrations/`}
      >
        <IconsGen icon_name={"linkOutside"} />
        <p>{_("panel.linkOutside")}</p>
      </div>
      {isSubNavVisible && (
        <ul
          className={`sub-nav flex-column gap-2 px-5 pt-3  ${
            isSubNavVisible ? "d-flex" : "d-none"
          }`}
        >
          <li>
            <NavLink
              to={`/${slug}/${lang}/panel/outside-integrations/easy-orders`}
            >
              <p className="d-inline ">{_("panel.easyOrders")}</p>
            </NavLink>
          </li>

          {/* Yalla Shop link  */}
          {userId === 12330 && (
            <li>
              <NavLink
                to={`/${slug}/${lang}/panel/outside-integrations/yalla-shop`}
              >
                <p className="d-inline ">{_("yalla_shop.yalla_shop")}</p>
              </NavLink>
            </li>
          )}
        </ul>
      )}
    </div>
  );
};

export default LinkOutside;
