import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../loader/Loader";
import { Button, Col, Table, Form } from "react-bootstrap";
import { Helmet } from "react-helmet";
import DateRangePicker from "react-bootstrap-daterangepicker";
import AxiosController from "../../authentication/AxiosController";
import * as XLSX from "xlsx";
import IconsGen from "../../icons_generator/IconsGen";
import PaginationComponent from "../../reuseable/PaginationComponent";

const FinanceMarketers = () => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const box_head_dir = lang === "en" ? "ltr" : "rtl";
  const [loading, handleLoader] = useState(false);
  const [allData, handleAllData] = useState([]);
  const [search, handleSearch] = useState(false);
  const token = localStorage.getItem("token");
  const location = useLocation();
  const Navigate = useNavigate();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(location.search);
  const [marketer_id, handleMarketer_id] = useState("");
  const [product_id, handleProduct_id] = useState("");
  const [days, handleDays] = useState("");
  const [userId, handleUserId] = useState("");
  const [seller_id, handleSeller_id] = useState("");
  const [startDate, handleStartDate] = useState("");
  const [endDate, handleEndDate] = useState("");
  const pageFromUrl = new URLSearchParams(location.search).get("page") || 1;
  const [page, setPage] = useState(Number(pageFromUrl));
  const changeParam = () => {
    urlParams.set("marketer_id", marketer_id);
    urlParams.set("product_id", product_id);
    urlParams.set("days", days);
    urlParams.set("user_id", userId);
    urlParams.set("seller_id", seller_id);
    urlParams.set("start_date", startDate);
    urlParams.set("end_date", endDate);
    Navigate(`?${urlParams.toString()}`);
  };
  const updatePage = (param, value) => {
    urlParams.set(param, value);
    setPage(value);
    Navigate(`?${urlParams.toString()}`);
  };
  const resetHandler = () => {
    handleMarketer_id("");
    handleProduct_id("");
    handleDays("");
    handleUserId("");
    handleEndDate("");
    Navigate("");
  };

  const handleApply = (event, picker) => {
    const startDate = picker.startDate.format("YYYY-MM-DD");
    const endDate = picker.endDate.format("YYYY-MM-DD");
    handleStartDate(startDate);
    handleEndDate(endDate);
  };

  const get_financials_marketers = useCallback(() => {
    handleLoader(true);

    AxiosController.get(
      `api/${slug}/${lang}/financials/marketers-overview?page=${page}`,
      {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          // Optional: Uncomment if you need to use the params
          // start_date: startDate,
          // end_date: endDate,
          // marketer_id: marketer_id,
          // seller_id: seller_id,
          // product_id: product_id,
        },
      }
    )
      .then((res) => {
        handleLoader(false);
        handleAllData(res?.data?.marketers);
      })
      .catch((error) => {
        handleLoader(false);
        console.error(error);
      });
  },[lang, page, slug, token]);
  const handleSubmit = (e) => {
    e.preventDefault();
    changeParam();
    get_financials_marketers();
  };

  useEffect(() => {
    get_financials_marketers();
  }, [get_financials_marketers]);

  const downloadExcel = () => {
    const formattedData = allData?.data.map((item, index) => ({
      "#": index + 1,
      "First Name": item.first_name,
      "Last Name": item.last_name,
      "Marketer Commissions": item.marketer_commissions,
      "Payment Requests Amount": item.payment_requests_amount,
      "Balance Total": item.balance_total,
    }));

    // Create a new workbook and add the table data
    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "finance_marketers");

    // Export the workbook to an Excel file
    XLSX.writeFile(wb, "finance_marketers_data.xlsx");
  };

  return (
    <div className="flex-grow-1 stats-component">
      <Helmet>
        <title>{_("panel.finance_marketers")}</title>
      </Helmet>
      {/* <Button
        className="fs-5 px-5 w-25 mb-4"
        style={{
          backgroundColor: "rgb(89, 198, 201)",
          border: "none",
          borderRadius: "7px",
        }}
        onClick={() => {
          handleSearch(!search);
        }}
      >
        {_("panel.search")}
      </Button>
      {search ? (
        <form onSubmit={handleSubmit}>
          <div className="w-100 d-flex flex-column gap-1">
            <div className="inputs-row d-flex w-100 gap-2 flex-column flex-md-row">
              <>
                <Col>
                  <Form.Group className="mb-3 flex-grow-1">
                    <Form.Control
                      type="text"
                      placeholder={_("panel.seller")}
                      onChange={(e) => handleSeller_id(e.target.value)}
                      value={seller_id}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3 flex-grow-1">
                    <Form.Control
                      type="text"
                      placeholder={_("panel.themarketer")}
                      onChange={(e) => handleMarketer_id(e.target.value)}
                      value={marketer_id}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3 flex-grow-1">
                    <Form.Control
                      type="text"
                      placeholder={_("panel.product")}
                      onChange={(e) => handleProduct_id(e.target.value)}
                      value={product_id}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <DateRangePicker onApply={handleApply}>
                      <input type="text" className="form-control" />
                    </DateRangePicker>
                  </Form.Group>
                </Col>
              </>
            </div>
            <div className="action-inputs mt-3">
              <input
                className="px-5 align-self-end py-2"
                style={{
                  backgroundColor: "rgb(243, 168, 55)",
                  border: "none",
                  borderRadius: "7px",
                  color: "white",
                  textAlign: "center",
                }}
                type="submit"
                value={_("panel.search")}
              />
              <Button
                style={{ backgroundColor: "rgb(23, 43, 77)" }}
                variant="dark"
                onClick={resetHandler}
              >
                {_("panel.reset")}
              </Button>
            </div>
          </div>
        </form>
      ) : (
        ""
      )} */}

      <div className="reports-container my-3 d-flex flex-column gap-2 px-2 position-relative">
        <Loader active={loading} />

        <div className="reports-header d-flex flex-column flex-md-row py-2 justify-content-between flex-wrap">
          <h3 className="fs-2 mb-4" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.finance_marketers")}
          </h3>
          <button
            className="btn text-nowrap"
            style={{ border: "1px solid #172B4D", color: "#172B4D" }}
            onClick={downloadExcel}
            disabled={loading}
          >
            <IconsGen icon_name={"excel"} /> {_("panel.downloadExcel")}
          </button>
        </div>
        <div className="row" dir={box_head_dir} style={{ height: "1200px" }}>
          <div
            className="col-12"
            style={{ position: "relative", overflowX: "auto", height: "100%" }}
          >
            <Table
              responsive
              dir={box_head_dir}
              className="reports-table text-nowrap text-center"
            >
              <thead>
                <tr className="text-center">
                  <th>#</th>
                  <th>{_("panel.name")}</th>
                  <th>{_("panel.marketer_commission")}</th>
                  <th>{_("panel.payment_request")}</th>
                  <th>{_("panel.totalBalance")}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {allData?.data && allData?.data.length > 0 ? (
                  allData?.data.map((item, index) => (
                    <tr key={index} className="text-center">
                      <td>{index + 1}</td>
                      <td>
                        <span className="fw-bold"># {item.id}</span>
                        <br />
                        {item?.first_name} {item?.last_name}
                      </td>

                      <td>
                        {parseInt(item?.marketer_commissions).toLocaleString()}
                      </td>
                      <td>
                        {parseInt(
                          item?.payment_requests_amount
                        ).toLocaleString()}
                      </td>
                      <td>{parseInt(item?.balance_total).toLocaleString()}</td>
                      <td
                        onClick={() =>
                          navigate(
                            `/${slug}/${lang}/panel/financials/marketer-details/${item?.id}`
                          )
                        }
                      >
                        <IconsGen icon_name={"enter"} />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center">
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            <PaginationComponent
              currentPage={page||1}
              totalPages={allData?.last_page}
              links={allData?.links}
              onPageChange={(newPage) => updatePage("page", newPage)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinanceMarketers;
