import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const PrivacyPolicy = () => {
  const [_] = useTranslation("global");
  const { lang } = useParams();
  const direction = lang === "en" ? "ltr" : "rtl";

  return (
    <div
      className="container mx-auto my-5 p-5 bg-white rounded-lg shadow-lg"
      dir={direction}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{_("home.Privacy Policy")}</title>
        <link rel="canonical" href={`http://mysite.com/shop`} />
      </Helmet>

      <h2 className="text-3xl py-3 fw-bold text-center mb-6">
        {_("home.Privacy Policy")}
      </h2>

      <p className="text-lg leading-relaxed mb-8 text-gray-700">
        {_(
          "home.privacy_policy_description"
        )}
      </p>

      <div className="mb-10">
        <h3 className="text-2xl fw-semibold mb-4">
          {_("home.Information We Collect")}
        </h3>
        <p className="text-lg leading-relaxed text-gray-700">
          {_(
            "home.information_collection_description"
          )}
        </p>
      </div>

      <div className="mb-10">
        <h3 className="text-2xl fw-semibold mb-4">
          {_("home.How We Use Your Information")}
        </h3>
        <p className="text-lg leading-relaxed text-gray-700">
          {_(
            "home.information_usage_description"
          )}
        </p>
      </div>

      <div>
        <h3 className="text-2xl fw-semibold mb-4">
          {_("home.Changes to Privacy Policy")}
        </h3>
        <p className="text-lg leading-relaxed text-gray-700">
          {_(
            "home.privacy_policy_changes_description"
          )}
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
