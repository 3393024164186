import React, { useEffect, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../../loader/Loader";
import AxiosController from "../../../authentication/AxiosController";
import { format } from "date-fns";
import DateRangePickerComponent from "../../../date/DateRangePickerComponent";

const SearchComponent = () => {
  const [_] = useTranslation("global");
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");
  const location = useLocation();
  const Navigate = useNavigate();
  const [banks, setBanks] = useState([]);
  const [labels, setLabels] = useState([]);
  const [state, setState] = useState({
    selection: {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  });
  const get_labels = () => {
    AxiosController.get(`api/${slug}/${lang}/expenses/labels`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setLabels(res.data);
        handleLoader(false);
      })
      .catch((e) => {
        handleLoader(false);
      });
  };
  const get_shippingcosts = () => {
    AxiosController.get(`api/${slug}/${lang}/accounts/`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setBanks(res.data);
        handleLoader(false);
      })
      .catch((e) => {
        handleLoader(false);
      });
  };
  const urlParams = new URLSearchParams(location.search);

  const [account_id, setAccount_id] = useState("");
  const [expense_label, SetExpense_label] = useState("");
  const [startDate, handleStartDate] = useState("");
  const [endDate, handleEndDate] = useState("");
  const [startSpentDate, handleStartSpentDate] = useState("");
  const [endSpentDate, handleEndSpentDate] = useState("");
  const [employees, setEmployees] = useState([]);
  const [employee, setEmployee] = useState("");
  const [loading, handleLoader] = useState(false);
  const changeParam = () => {
    urlParams.set("account_id", account_id);
    urlParams.set("expense_label", expense_label);
    urlParams.set("start", startDate);
    urlParams.set("affected_user_id", employee);
    urlParams.set("end", endDate);
    urlParams.set("spent_at_start", startSpentDate);
    urlParams.set("spent_at_end", endSpentDate);
    Navigate(`?${urlParams.toString()}`);
  };
  const resetHandler = () => {
    setAccount_id("");
    setEmployee("");
    SetExpense_label("");
    handleStartDate("");
    handleEndDate("");
    handleStartSpentDate("");
    handleEndSpentDate("");
    handleLoader("");
    Navigate("");
  };
  const get_employees = () => {
    AxiosController.get(
      `api/${slug}/${lang}/search/get-user/1?role=employee&all=1`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        setEmployees(res.data.users);
        handleLoader(false);
      })
      .catch((e) => {
        handleLoader(false);
      });
  };
  const handleApply = (event, picker) => {
    const { startDate, endDate } = state.selection;
    const formattedStartDate = new Date(startDate.setHours(0, 0, 0, 0));
    const formattedEndDate = new Date(endDate.setHours(0, 0, 0, 0));

    const apiStartDate = format(formattedStartDate, "yyyy-MM-dd");
    const apiEndDate = format(formattedEndDate, "yyyy-MM-dd");
    // handleStartDate(apiStartDate);
    // handleEndDate(apiEndDate);

    // const startDate = picker.startDate.format("YYYY-MM-DD");
    // const endDate = picker.endDate.format("YYYY-MM-DD");
    handleStartDate(apiStartDate);
    handleEndDate(apiEndDate);
  };
  const handleSpentApply = (event, picker) => {
    const { startDate, endDate } = state.selection;
    const formattedStartDate = new Date(startDate.setHours(0, 0, 0, 0));
    const formattedEndDate = new Date(endDate.setHours(0, 0, 0, 0));

    const apiStartDate = format(formattedStartDate, "yyyy-MM-dd");
    const apiEndDate = format(formattedEndDate, "yyyy-MM-dd");
    handleStartSpentDate(apiStartDate);
    handleEndSpentDate(apiEndDate);
  };
  useEffect(() => {
    handleLoader(true);
    handleLoader(false);
  }, []);
  useEffect(() => {
    get_labels();
    get_employees();
    get_shippingcosts();
  }, []);
  return (
    <div className="w-100 d-flex flex-column gap-1 mt-2 position-relative gap-3 my-3 pl-5 mt-4 ">
      <Loader active={loading} />
      <div className="inputs-row d-flex flex-column  flex-md-row  gap-2 ">
        <Col>
          <div className="mb-3">
            <select
              className="form-select"
              id="bankSelect"
              placeholder={_("panel.thebank")}
              onChange={(e) => {
                setAccount_id(e.target.value);
              }}
              value={account_id}
            >
              <option value={0}>{_("panel.thebank")}</option>
              {banks.map((bank) => {
                return <option value={bank.id}>{bank.name}</option>;
              })}
            </select>
          </div>
        </Col>
        <Col>
          <div className="mb-3">
            <select
              className="form-select"
              id="labelSelect"
              placeholder={_("panel.label")}
              onChange={(e) => {
                SetExpense_label(e.target.value);
              }}
              value={expense_label}
            >
              <option value={0}>{_("panel.label")}</option>
              {labels.map((label) => {
                return <option value={label.id}>{label.title}</option>;
              })}
            </select>
          </div>
        </Col>
        <Col>
          <div className="mb-3">
            <select
              className="form-select"
              id="employee"
              onChange={(e) => setEmployee(e.target.value)}
              value={employee}
            >
              <option value={0}> {_("panel.employee")}</option>
              {employees &&
                employees.length > 0 &&
                employees.map((ele) => {
                  return (
                    <option value={ele?.id}>
                      {ele?.first_name} {ele?.last_name}
                    </option>
                  );
                })}
            </select>
          </div>
        </Col>
      </div>
      <div className="inputs-row d-flex w-100 gap-2 flex-column  flex-md-row">
        <Col>
          <label className="mb-2">{_("panel.receiptDate")}</label>

          <Form.Group
            className="flex-grow-1"
            controlId="exampleForm.ControlInput1"
          >
            <DateRangePickerComponent
              state={state}
              setState={setState}
              applyDateRange={() => handleApply()}
            />
          </Form.Group>
        </Col>
        <Col>
          <label className="mb-2">{_("panel.spentDate")}</label>
          <Form.Group
            className="flex-grow-1"
            controlId="exampleForm.ControlInput1"
          >
            <DateRangePickerComponent
              state={state}
              setState={setState}
              applyDateRange={() => handleSpentApply()}
            />
            {/* <DateRangePicker onApply={handleSpentApply}>
              <input type="text" className="form-control" />
            </DateRangePicker> */}
          </Form.Group>
        </Col>
      </div>

      <div className="action-inputs">
        <input
          className=" px-5 align-self-end py-2"
          style={{
            backgroundColor: "rgb(243, 168, 55)",
            border: "none",
            borderRadius: "7px",
            color: "white",
            textAlign: "center",
          }}
          onClick={() => {
            changeParam();
          }}
          type="submit"
          value={_("panel.search")}
        />
        <Button
          style={{ backgroundColor: "rgb(23, 43, 77)" }}
          variant="dark"
          className="d-inline"
          onClick={resetHandler}
        >
          {_("panel.reset")}
        </Button>
      </div>
    </div>
  );
};

export default SearchComponent;
