import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { FaCreditCard, FaPaypal, FaTruck } from "react-icons/fa";

const PaymentAndDeliveryOptions = () => {
  const [_] = useTranslation("global");
  const { lang } = useParams();
  const direction = lang === "en" ? "ltr" : "rtl";

  return (
    <div
      className="container mx-auto my-5 p-5 bg-white rounded-lg shadow-lg"
      dir={direction}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{_("home.Payment and Delivery Options")}</title>
        <link rel="canonical" href={`http://mysite.com/shop`} />
      </Helmet>

      <h2 className="text-2xl py-3 fw-bold text-center mb-6">
        {_("home.Payment and Delivery Options")}
      </h2>

      <p className="text-lg leading-relaxed mb-8 text-gray-700">
        {_(
          "home.payment_options_description"
        )}
      </p>

      <div className="mb-10">
        <h3 className="text-xl fw-semibold mb-4">
          {_("home.Payment Options")}
        </h3>
        <ul className="space-y-3">
          <li className="flex items-center text-lg text-gray-600">
            <FaCreditCard className="mr-2 text-indigo-500" />{" "}
            {_("home.Credit Cards (Visa, MasterCard)")}
          </li> 
          <li className="flex items-center text-lg text-gray-600">
            <FaTruck className="mr-2 text-green-500" />{" "}
            {_("home.Cash on Delivery")}
          </li>
        </ul>
      </div>

      <div>
        <h3 className="text-xl fw-semibold mb-4">
          {_("home.Delivery Options")}
        </h3>
        <p className="text-lg leading-relaxed text-gray-700">
          {_(
            "home.delivery_options_description"
          )}
        </p>
      </div>
    </div>
  );
};

export default PaymentAndDeliveryOptions;
