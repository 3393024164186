import React from "react";
import { useTranslation } from "react-i18next";

const TableLabels = () => {
  const [_] = useTranslation("global");
  const role = localStorage.getItem("role");

  const roles_labels = {
    employee: [
      _("panel.date"),
      _("panel.title"),
      _("panel.sellerCom"),
      _("panel.sysCommission"),
      _("panel.sysCom"),
      _("panel.totalAmount"),
      _("panel.left"),
      _("panel.status"),
      _("panel.edit"),
    ],
    admin: [
      _("variants_history.date"),
      _("variants_history.product"),
      _("variants_history.seller_price"),
      _("variants_history.unit_price"),
      _("variants_history.price_difference"),
      _("variants_history.company_commission"),
      _("variants_history.added_stock"),
      _("variants_history.current_stock"),
    ],
    seller: [
      _("panel.date"),
      _("panel.title"),
      _("panel.sellerCom"),
      _("panel.sysCommission"),
      _("panel.sysCom"),
      _("panel.totalAmount"),
      _("panel.left"),
      _("panel.status"),
      _("panel.edit"),
    ],
  };

  return (
    <tr>
      <th>
        <div
          className="form-check mt-3 d-flex justify-content-center align-items-center"
          style={{ paddingLeft: "2.24rem", paddingRight: "7px" }}
        >
          <input
            className="form-check-input"
            style={{
              border: "1px solid rgba(243, 168, 55, 1)",
              transform: "translate(-0%, -50%)",
            }}
            type="checkbox"
            value=""
            id="flexCheckDefault"
          />
        </div>
      </th>
      {roles_labels[role]?.map((label, idx) => (
        <th key={idx}>{label}</th> // Add key to prevent warnings
      ))}
    </tr>
  );
};

export default TableLabels;
