import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const ReturnAndExchangePolicy = () => {
  const [_] = useTranslation("global");
  const { lang, slug } = useParams();
  const direction = lang === "en" ? "ltr" : "rtl";

  return (
    <div
      className="container mx-auto my-5 p-5 bg-white rounded-lg shadow-lg"
      dir={direction}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{_("home.Return and Exchange Policy")}</title>
        <link rel="canonical" href={`http://mysite.com/shop`} />
      </Helmet>

      <h2 className="text-3xl py-3 fw-bold text-center mb-6">
        {_("home.Return and Exchange Policy")}
      </h2>

      <p className="text-lg leading-relaxed mb-8 text-gray-700">
        {_(
          "home.return_policy_description"
        )}
      </p>

      <div className="mb-10">
        <h3 className="text-2xl fw-semibold mb-4">
          {_("home.Return Conditions")}
        </h3>
        <p className="text-lg leading-relaxed text-gray-700">
          {_(
            "home.return_conditions_description"
          )}
        </p>
        <p>
          الاستلام. (يوجد رسوم استرجاع 25
          {slug === "sa" ? _(`panel.ريال سعودي`) : _(`panel.درهم امارتي`)}{" "}
          &nbsp; ويتم خصمها من قيمة المنتج المسترجع ).
        </p>
      </div>

      <div>
        <h3 className="text-2xl fw-semibold mb-4">
          {_("home.Return Process")}
        </h3>
        <p className="text-lg leading-relaxed text-gray-700">
          {_(
            "home.return_process_description"
          )}
        </p>
      </div>
    </div>
  );
};

export default ReturnAndExchangePolicy;
