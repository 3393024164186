import React, { useEffect, useState } from "react";
import IconsGen from "../../../../icons_generator/IconsGen";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Loader from "../../../../loader/Loader";
import AxiosController from "../../../../authentication/AxiosController";
import DateRangePicker from "react-bootstrap-daterangepicker";
import DateRangePickerComponent from "../../../../date/DateRangePickerComponent";
import { format } from "date-fns";
const control_panel_stats_wallet = (
  slug,
  lang,
  token,
  handleStatsWallet,
  handleAltName,
  handleLoader,
  dates = {}
) => {
  AxiosController.get(`api/${slug}/${lang}/get-control-panel-stats-seller/`, {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      start: dates["start"] ? dates["start"] : "",
      end: dates["end"] ? dates["end"] : "",
    },
  })
    .then((res) => {
      handleStatsWallet(res.data);
      handleAltName("قطعة");
      handleLoader(false);
    })
    .catch((e) => {});
};

const SellerStats = () => {
  const { slug, lang } = useParams();

  const [_] = useTranslation("global");
  const box_head_dir = lang === "en" ? "ltr" : "rtl";
  const token = localStorage.getItem("token");
  const [loading, handleLoader] = useState(false);
  const [statsWallet, handleStatsWallet] = useState({});
  const [alt_name, handleAltName] = useState("");

 
  const [state, setState] = useState({
    selection: {
      startDate: new Date(),
      endDate:new Date(),
      key: 'selection'
    }
  });
  const applyDateRange = () => {
    const { startDate, endDate } = state.selection; 
    const formattedStartDate = new Date(startDate.setHours(0, 0, 0, 0));
    const formattedEndDate = new Date(endDate.setHours(0, 0, 0, 0));
  
    // Format dates to YYYY-MM-DD for API
    const apiStartDate = format(formattedStartDate, 'yyyy-MM-dd');
    const apiEndDate = format(formattedEndDate, 'yyyy-MM-dd'); 
    handleLoader(true);
    control_panel_stats_wallet(
      slug,
      lang,
      token,
      handleStatsWallet,
      handleAltName,
      handleLoader,
      { start: apiStartDate, end: apiEndDate }
    );
  };

  useEffect(() => {
    handleLoader(true);
    control_panel_stats_wallet(
      slug,
      lang,
      token,
      handleStatsWallet,
      handleAltName,
      handleLoader
    );
  }, [slug, lang, token, handleStatsWallet, handleAltName, handleLoader]);

  return (
    <div className="flex-grow-1 stats-component">
      <div className="reports-container my-3 d-flex flex-column gap-2 px-2 position-relative ">
        <Loader active={loading} />
        <div className="reports-header d-flex flex-column flex-md-row py-2 justify-content-between flex-wrap">
          <h3 style={{ color: "rgba(23, 43, 77, 1)" }}>
            {_("panel.products")}
          </h3>
          <div className="interval d-flex flex-column gap-2 flex-md-row-reverse">
          <DateRangePickerComponent 
                state={state} 
                setState={setState}    
                applyDateRange={()=>applyDateRange()}
              />
          </div>
        </div>
        <div
          className="boxes-container d-flex flex-wrap gap-2"
          // style={{ direction: "rtl" }}
          dir={box_head_dir}
        >
          <div className="report-box col-12  col-lg-3 d-flex flex-column gap-2 flex-grow-1 align-items-center py-3">
            <div className="top d-flex align-items-center gap-2">
              <IconsGen icon_name={"dollarSign"} />
              <p className="flex-grow-1" style={{}}>
                {_("panel.TotalSellerProducts")}
              </p>
            </div>
            <div className="middle fs-2">
              <p>
                {statsWallet["total_seller_products"]
                  ? statsWallet["total_seller_products"][0]["stats"]
                  : 0}{" "}
                {alt_name}
              </p>
            </div>
            {/* <div className="footer d-flex gap-2 flex-row-reverse align-items-center">
              <IconsGen icon_name={"bluewaves"} />

              <p>+5%</p>
            </div> */}
          </div>
          <div className="report-box col-12  col-lg-3 d-flex flex-column gap-2 flex-grow-1 align-items-center py-3">
            <div className="top d-flex align-items-center gap-2">
              <IconsGen icon_name={"bluecheck"} />
              <p className="flex-grow-1" style={{}}>
                {_("panel.TotalVariants")}
              </p>
            </div>
            <div className="middle fs-2">
              <p>
                {statsWallet["total_seller_variants"]
                  ? statsWallet["total_seller_variants"][0]["stats"] || 0
                  : 0}{" "}
                {alt_name}
              </p>
            </div>
            {/* <div className="footer d-flex gap-2 flex-row-reverse align-items-center">
              <IconsGen icon_name={"bluewaves"} />

              <p>+5%</p>
            </div> */}
          </div>
          <div className="report-box col-12  col-lg-3 d-flex flex-column gap-2 flex-grow-1 align-items-center py-3">
            <div className="top d-flex align-items-center gap-2">
              <IconsGen icon_name={"bluetrans"} />

              <p className="flex-grow-1" style={{}}>
                {_("panel.total_pickup")}
              </p>
            </div>
            <div className="middle fs-2">
              <p>
                {statsWallet["total_pickup"]
                  ? statsWallet["total_pickup"][0]["stats"] || 0
                  : 0}{" "}
                {alt_name}
              </p>
            </div>
            {/* <div className="footer d-flex gap-2 flex-row-reverse align-items-center">
              <IconsGen icon_name={"bluewaves"} />

              <p>+5%</p>
            </div> */}
          </div>
          <div className="report-box col-12  col-lg-3 d-flex flex-column gap-2 flex-grow-1 align-items-center py-3 orangify">
            <div className="top d-flex align-items-center gap-2">
              <IconsGen icon_name={"orangdash"} />

              <p className="flex-grow-1" style={{}}>
                {_("panel.total_with_shipping")}
              </p>
            </div>
            <div className="middle fs-2">
              <p>
                {statsWallet["total_with_shipping"]
                  ? statsWallet["total_with_shipping"][0]["stats"] || 0
                  : 0}{" "}
                {alt_name}
              </p>
            </div>

            {/* <div className="footer d-flex gap-2 flex-row-reverse align-items-center">
              <IconsGen icon_name={"yellowwaves"} />

              <p>+5%</p>
            </div> */}
          </div>

          <div
            className="report-box col-12  col-lg-3 d-flex flex-column gap-2 flex-grow-1 align-items-center py-3 orangify"
            style={{ background: "none" }}
          ></div>
          <div
            className="report-box col-12  col-lg-3 d-flex flex-column gap-2 flex-grow-1 align-items-center py-3 orangify"
            style={{ background: "none" }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default SellerStats;
