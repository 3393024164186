import React, { useEffect, useState } from "react"; 
import { useTranslation } from "react-i18next"; 
import { downloadPDF } from "./LinkedPrint";

const LinkedManifest = ({ linkManifest }) => {
   const [_] = useTranslation("global"); 
  const [loading, handleLoader] = useState(false);
  const [linked,setLinked ] = useState(linkManifest);
 
  useEffect(() => {
    if (linked && linked.length > 0) {
      handleLoader(false); 
      downloadPDF(linked ,_("panel.total") , _("panel.orderContent") , _("panel.courierNumber") , _("panel.totalorders2"));  
      setLinked([])
    }
  }, [linked]);

  return (
  <></>
  );
};

export default LinkedManifest;
