import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import "./style.css";
import IconsGen from "../../../icons_generator/IconsGen";
import TableLabels from "./TableLabels";
import AxiosController from "../../../authentication/AxiosController";
import { FaCaretDown, FaTimes } from "react-icons/fa";
import { Modal, Table } from "react-bootstrap";
const formatNumber = (number) => {
  return Number.isNaN(Number(number)) ? 0 : Number(number).toLocaleString();
};
const ProductsStockTableRow = ({
  payments_list,
  alt_name,
  labels,
  handleLoader,
  productsStockLog,
}) => {
  const { slug, lang } = useParams();
  const navigate = useNavigate();
  const [_] = useTranslation("global");
  const role = localStorage.getItem("role");
  const employee_type = localStorage.getItem("employee_type");
  const [totalCurrentStock, setTotalCurrentStock] = useState(0);
  const [totalLeftValue, setTotalLeftValue] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [courierQ, setCourierQ] = useState(0);
  const [courierV, setCourierV] = useState(0);
  const token = localStorage.getItem("token");

  const [skus, setSkus] = useState({});
  const [singleSku, setSingleSku] = useState({});
  const [showDropdown, setShowDropdown] = useState({});
  const [loading, setLoading] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [currentProductId, setCurrentProductId] = useState(null);
  const get_sku = (id) => {
    setLoading((prev) => ({ ...prev, [id]: true }));
    AxiosController.get(
      `api/${slug}/${lang}/get-product-variants-stocks/${id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        setSkus((prev) => ({ ...prev, [id]: res.data }));
        setLoading((prev) => ({ ...prev, [id]: false }));
        setCurrentProductId(id);
        if (res.data.length > 1) {
          setShowModal(true);
        } else if (res.data.length === 1) {
          setSingleSku((prev) => ({ ...prev, [id]: res.data[0].sku })); // Store SKU for the specific product
        } else {
          setSingleSku((prev) => ({ ...prev, [id]: null })); // Reset SKU if no SKUs found
        }
      })
      .catch((e) => {
        setLoading((prev) => ({ ...prev, [id]: false }));
        console.error(e);
      });
  };
  const handleClick = (id) => {
    if (!showDropdown[id]) {
      get_sku(id);
    } else {
      setShowDropdown((prev) => ({ ...prev, [id]: false }));
    }
  };

  useEffect(() => {
    payments_list.map((order) => {
      let leftValue = productsStockLog.reduce((prev, stock_log) => {
        if (stock_log.product_id === order["product_id"]) {
          return (
            parseFloat(stock_log.piece_price) * parseInt(stock_log.left) + prev
          );
        } else {
          return prev;
        }
      }, 0);
    });
  }, [payments_list, productsStockLog]);

  useEffect(() => {
    setTotalCurrentStock(labels?.total_left);
    setCourierQ(labels?.courier_quantity);
    setTotalPrice(labels?.total_price);
    setCourierV(labels?.courier_value);
    setTotalLeftValue(labels?.total_value);
  }, [labels]);
  const totalLeftByPage = payments_list
    .map((order) => order["left"])
    .reduce((acc, left) => acc + left, 0);

  const totalLeftValueByPage = payments_list.reduce((acc, order) => {
    const leftValue = productsStockLog.reduce((prev, stock_log) => {
      if (stock_log.product_id === order["product_id"]) {
        return (
          prev + parseFloat(stock_log.piece_price) * parseInt(stock_log.left)
        );
      } else {
        return prev;
      }
    }, 0);
    return acc + leftValue;
  }, 0);

  const totalPiecePriceByPage = payments_list
    .map((order) => order["piece_price"])
    .reduce((acc, piecePrice) => acc + Number(piecePrice), 0);

  const totalCourierQuantityByPage = payments_list
    .map((order) => order["courier_quantity"])
    .reduce((acc, courierQuantity) => acc + Number(courierQuantity), 0);

  const totalCourierValueByPage = payments_list
    .map((order) => order["courier_value"])
    .reduce((acc, courierValue) => acc + Number(courierValue), 0);
  const totalGoodsValueWithShipping =
    totalCourierQuantityByPage * totalPiecePriceByPage;
  const handleModalClose = () => {
    setShowModal(false);
    setCurrentProductId(null);
  };

  return (
    <div
      className={`table-responsive my-3 d-flex ${
        lang === "en" ? "flex-row" : "flex-row-reversed"
      } align-items-center`}
    >
      <table className="table table-borderless text-nowrap">
        <thead>
          <TableLabels
            totalCurrentStock={parseInt(totalCurrentStock).toLocaleString()}
            totalLeftValue={parseInt(totalLeftValue).toLocaleString()}
            totalPrice={parseInt(totalPrice).toLocaleString()}
            courierQ={parseInt(courierQ).toLocaleString()}
            courierV={parseInt(courierV).toLocaleString()}
            totalLeftByPage={totalLeftByPage}
            totalPiecePriceByPage={totalPiecePriceByPage}
            totalCourierQuantityByPage={totalCourierQuantityByPage}
            totalCourierValueByPage={totalCourierValueByPage}
            totalLeftValueByPage={totalLeftValueByPage}
            totalGoodsValueWithShipping={totalGoodsValueWithShipping}
            total_labels={labels}
          />
        </thead>
        {role !== "admin" ? (
          <tbody>
            {payments_list.map((order) => {
              const dateSegments = order["created_at_formatted"].split(" ");
              let leftValue = productsStockLog.reduce((prev, stock_log) => {
                if (stock_log.product_id === order["product_id"]) {
                  return (
                    parseFloat(stock_log.piece_price) *
                      parseInt(stock_log.left) +
                    prev
                  );
                } else {
                  return prev;
                }
              }, 0);

              return (
                <tr key={order["id"]}>
                  <td>
                    <div
                      className="form-check mt-3 d-flex justify-content-center"
                      style={{ paddingLeft: "2.24rem", paddingRight: "7px" }}
                    >
                      <input
                        className="form-check-input"
                        style={{
                          border: "1px solid rgba(243, 168, 55, 1)",
                          transform: "translate(-0%, -50%)",
                        }}
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                    </div>
                  </td>
                  <td>
                    {dateSegments[0]} <br /> {dateSegments[1]} {dateSegments[2]}
                  </td>
                  <td>
                    <img
                      src={order["thumbnail"]}
                      width={"69px"}
                      className="rounded"
                    />
                    <br />
                    {order["title"].slice(0, 15)}... <br />{" "}
                    <span className="fw-bold">{order["product_id"]} </span> #
                    <br />
                    {order["sku"]}
                  </td>{" "}
                  <td
                    onClick={() => handleClick(order["product_id"])}
                    style={{ position: "relative", cursor: "pointer" }}
                  >
                    {loading[order["product_id"]] ? (
                      <span>Loading...</span>
                    ) : singleSku[order["product_id"]] ? (
                      singleSku[order["product_id"]]
                    ) : (
                      <>
                        SKU
                        <FaCaretDown style={{ marginLeft: "5px" }} />
                      </>
                    )}
                  </td>
                  <td>{parseInt(order["left"]).toLocaleString()}</td>
                  {role === "admin" && (
                    <td>{parseInt(leftValue).toLocaleString() || 0}</td>
                  )}
                  {(role === "admin" || role === "seller") && (
                    <td>
                      {/* ربحية التاجر */}
                      {parseFloat(order["piece_price"]).toLocaleString() || 0}
                    </td>
                  )}
                  <td>
                    {parseInt(order["courier_quantity"]).toLocaleString() ||
                      "0"}
                  </td>
                  {(role === "admin" || role === "employee") && (
                    <td>
                      {parseInt(order["courier_value"]).toLocaleString() || 0}
                    </td>
                  )}
                  <td
                    onClick={() =>
                      navigate(
                        `/${slug}/${lang}/panel/warehouse/product-stock/${order["product_id"]}`
                      )
                    }
                  >
                    {<IconsGen icon_name={"enter"} />}
                  </td>
                </tr>
              );
            })}
          </tbody>
        ) : (
          <tbody>
            {payments_list.map((order) => {
              const dateSegments = order["created_at_formatted"].split(" ");
              return (
                <tr key={order["id"]}>
                  <td>
                    <div
                      className="form-check mt-3 d-flex justify-content-center"
                      style={{ paddingLeft: "2.24rem", paddingRight: "7px" }}
                    >
                      <input
                        className="form-check-input"
                        style={{
                          border: "1px solid rgba(243, 168, 55, 1)",
                          transform: "translate(-0%, -50%)",
                        }}
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                    </div>
                  </td>
                  <td>
                    {dateSegments[0]} <br /> {dateSegments[1]} {dateSegments[2]}
                  </td>
                  <td>
                    <img
                      src={order["thumbnail"]}
                      width={"69px"}
                      className="rounded"
                    />
                    <br />
                    {order["title"].slice(0, 15)}... <br />{" "}
                    <span className="fw-bold">{order["product_id"]} </span> #
                    <br />
                    <span
                      onClick={() => handleClick(order["product_id"])}
                      style={{ position: "relative", cursor: "pointer" }}
                    >
                      {loading[order["product_id"]] ? (
                        <span>Loading...</span>
                      ) : singleSku[order["product_id"]] ? (
                        // Display the single SKU for this product only
                        singleSku[order["product_id"]]
                      ) : (
                        <>
                          SKU
                          <FaCaretDown style={{ marginLeft: "5px" }} />
                        </>
                      )}
                    </span>
                  </td>
                  <td>{formatNumber(order["left"])}</td>
                  <td>{formatNumber(order["piece_price"])}</td>
                  <td>
                    {formatNumber(
                      parseInt(order.left) * parseInt(order.piece_price)
                    )}
                  </td>
                  <td>{formatNumber(order.courier_quantity)}</td>
                  <td>{formatNumber(order.courier_value)}</td>
                  <td>
                    {formatNumber(
                      parseInt(order.courier_quantity) *
                        parseInt(order.piece_price)
                    )}
                  </td>

                  <td
                    onClick={() =>
                      navigate(
                        `/${slug}/${lang}/panel/warehouse/product-stock/${order["product_id"]}`
                      )
                    }
                  >
                    {<IconsGen icon_name={"enter"} />}
                  </td>
                </tr>
              );
            })}
          </tbody>
        )}

        {showModal && (
          <Modal
            show={showModal}
            onHide={handleModalClose}
            aria-labelledby="sku-modal-title"
          >
            <Modal.Header>
              <Modal.Title id="sku-modal-title">SKU</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Table responsive bordered hover>
                <thead>
                  <tr className="text-center">
                    <th>{_("sku")}</th>
                    <th>{_("panel.size")}</th>
                    <th>{_("panel.color")}</th>
                    <th>{_("panel.left")}</th>
                    <th>{_("panel.totalAmount")}</th>
                  </tr>
                </thead>
                <tbody>
                  {skus[currentProductId]
                    .sort((a, b) => b.left - a.left)
                    .map((sku, index) => (
                      <tr key={index} className="text-center">
                        <td>{sku.sku || "N/A"}</td>
                        <td>{sku.size || "-"}</td>
                        <td>{sku.color || "-"}</td>
                        <td>{sku.left || 0}</td>
                        <td>{sku.total || 0}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-secondary" onClick={handleModalClose}>
                <FaTimes /> {_("panel.close")}
              </button>
            </Modal.Footer>
          </Modal>
        )}
      </table>
    </div>
  );
};

export default ProductsStockTableRow;
