import React, { useEffect, useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import IconsGen from "../../../icons_generator/IconsGen";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Loader from "../../../loader/Loader";
import AxiosController from "../../../authentication/AxiosController";

// styles
import styles from "./previewState.module.css";
import { currancyConvertor } from "../../../../utils/currancyConvertor";

const boxsSyles =
  "box d-flex flex-column gap-1 align-items-center p-4 flex-grow-1 col-12 col-md-3";

const control_panel_stats_wallet = (
  slug,
  lang,
  token,
  handleStatsWallet,
  handleAltName,
  dates = {},
  handleLoader
) => {
  AxiosController.get(`api/${slug}/${lang}/get-control-panel-stats-wallet/`, {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      start: dates["start"] ? dates["start"] : "",
      end: dates["end"] ? dates["end"] : "",
    },
  })
    .then(async (res) => {
       handleStatsWallet(res.data);
      handleAltName(res.data.alt_name.split(" ")[0]);
      handleLoader(false);
    })
    .catch((e) => {});
};

const get_control_panel_stats_preview = (
  slug,
  lang,
  token,
  handleStatsWalletPrev,
  handleLoader,
  dates = {}
) => {
  AxiosController.get(`api/${slug}/${lang}/get-control-panel-stats-preview/`, {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      start: dates["start"] ? dates["start"] : "",
      end: dates["end"] ? dates["end"] : "",
    },
  })
    .then((res) => {
      handleStatsWalletPrev(res.data);
      handleLoader(false);
    })
    .catch((e) => {});
};

const PreviewStats = () => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");

  const handleApply = (event, picker) => {
    const startDate = picker.startDate.format("YYYY-MM-DD");
    const endDate = picker.endDate.format("YYYY-MM-DD");
    handleLoader(true);
    control_panel_stats_wallet(
      slug,
      lang,
      token,
      handleStatsWallet,
      handleAltName,
      { start: startDate, end: endDate },
      handleLoader
    );
    get_control_panel_stats_preview(
      slug,
      lang,
      token,
      handleStatsWalletPrev,
      handleAltName,
      { start: startDate, end: endDate },
      handleLoader
    );
  };

  const box_head_dir = lang === "en" ? "ltr" : "rtl";
  const [loading, handleLoader] = useState(true);
  const [alt_name, handleAltName] = useState("");
  const [statsWallet, handleStatsWallet] = useState({});
  const [statsWalletPrev, handleStatsWalletPrev] = useState({});
  useEffect(() => {
    handleLoader(true);
    control_panel_stats_wallet(
      slug,
      lang,
      token,
      handleStatsWallet,
      handleAltName,
      {},
      handleLoader
    );
    get_control_panel_stats_preview(
      slug,
      lang,
      token,
      handleStatsWalletPrev,
      handleLoader
    );
  }, [slug, lang, token, handleStatsWallet, handleLoader]);

  if (role == 'affiliate' || role =='seller') {
    return (
      <div className="position-relative">
        <Loader active={loading} />
        <div
          className={`reports-header d-flex flex-column ${
            lang === "en" ? "flex-md-row-reverse" : "flex-md-row"
          } py-2 justify-content-between flex-wrap`}
        >
          <h3 style={{ color: "rgba(23, 43, 77, 1)", fontWeight: "bold" }}>
            {_("panel.mystats")}
          </h3>
          <div className="interval d-flex flex-column gap-2 flex-md-row">
            <div className="form-group">
              {/* <DateRangePicker onApply={handleApply}>
                <input type="text" className="form-control" />
              </DateRangePicker> */}
            </div>
          </div>
        </div>
  
        {/*=========== links =========*/}
        <div class="boxes-container d-flex gap-2 flex-wrap" dir={box_head_dir}>
          <div className={`${boxsSyles} ${styles.box1} flex-column  flex-md-row`}>
            <div
              className="head d-flex justify-content-center flex-row align-items-center gap-2"
              dir={box_head_dir}
            >
              <IconsGen icon_name={"dollarSign"} />
              <p className="m-0">{_("panel.totalearnings")}</p>
            </div>
            <div className="number">
              <p>
                {statsWallet["total_earnings"]
                  ? currancyConvertor(statsWallet["total_earnings"]) || 0
                  : 0}{" "}
                {alt_name}
              </p>
            </div>
            {/* <div className="foot d-flex align-items-center gap-2 flex-row-reverse">
              <IconsGen icon_name={"bluewaves"} />
              <p style={{ color: "rgba(89, 198, 201, 1)" }}>+5%</p>
            </div> */}
          </div>
          <div className={`${boxsSyles} ${styles.box1}`}>
            <div
              className="head d-flex justify-content-center flex-row align-items-center gap-2"
              dir={box_head_dir}
            >
              <IconsGen icon_name={"checkSign"} />
              <p className="m-0">{_("panel.confirmedearnings")}</p>
            </div>
            <div className="number">
              <p>
                {statsWallet["total_confirmed_money"]
                  ? currancyConvertor(
                      statsWallet["total_confirmed_money"][0]["stats"] || 0
                    )
                  : "0"}
                {alt_name}
              </p>
            </div>
            {/* <div className="foot d-flex align-items-center gap-2 flex-row-reverse">
              <IconsGen icon_name={"greenwaves"} />
              <p style={{ color: "rgba(52, 181, 58, 1)" }}>+5%</p>
            </div> */}
          </div>
          <div className={`${boxsSyles} ${styles.box1}`}>
            <div
              className="head d-flex justify-content-center flex-row align-items-center gap-2"
              dir={box_head_dir}
            >
              <IconsGen icon_name={"payReqIc"} />
              <p className="m-0">{_("panel.withdrawableearnings")}</p>
            </div>
            <div className="number">
              <p>
                {statsWallet["balance"]
                  ? currancyConvertor(statsWallet["balance"]["total"] || 0)
                  : 0}{" "}
                {alt_name}
              </p>
            </div>
            {/* <div className="foot d-flex align-items-center gap-2 flex-row-reverse">
              <IconsGen icon_name={"terquazwaves"} />
              <p style={{ color: "rgba(67, 57, 242, 1)" }}>+5%</p>
            </div> */}
          </div>
          <div className={`${boxsSyles} ${styles.box2}`}>
            <div
              className="head d-flex justify-content-center flex-row align-items-center gap-2"
              dir={box_head_dir}
            >
              <IconsGen icon_name={"statsIcon"} />
              <p className="m-0">{_("panel.expectedearnings")}</p>
            </div>
            <div className="number">
              <p>
                {statsWallet["total_expected_money"]
                  ? currancyConvertor(
                      statsWallet["total_expected_money"][0]["stats"] || 0
                    )
                  : 0}
                {alt_name}
              </p>
            </div>
            {/* <div className="foot d-flex align-items-center gap-2 flex-row-reverse">
              <IconsGen icon_name={"darkblueswaves"} />
              <p style={{ color: "rgba(0, 111, 158, 1)" }}>+5%</p>
            </div> */}
          </div>
          <div className={`${boxsSyles} ${styles.box2}`}>
            <div
              className="head d-flex justify-content-center flex-row align-items-center gap-2"
              dir={box_head_dir}
            >
              <IconsGen icon_name={"cubeIcon"} />
              <p className="m-0">{_("panel.totalorders")}</p>
            </div>
            <div className="number">
              <p>
                {statsWalletPrev["total_marketer_orders"]
                  ? currancyConvertor(
                      statsWalletPrev["total_marketer_orders"][0]["stats"] || 0
                    )
                  : 0}
              </p>
            </div>
            {/* <div className="foot d-flex align-items-center gap-2 flex-row-reverse">
              <IconsGen icon_name={"yellowwaves"} />
              <p style={{ color: "rgba(243, 168, 55, 1)" }}>+5%</p>
            </div> */}
          </div>
          <div className={`${boxsSyles} ${styles.box2}`}>
            <div
              className="head d-flex justify-content-center flex-row align-items-center gap-2"
              dir={box_head_dir}
            >
              <IconsGen icon_name={"checkedCube"} />
              <p className="m-0">{_("panel.deliveredorder")}</p>
            </div>
            <div className="number">
              <p>
                {statsWalletPrev["total_marketer_orders_delivered"]
                  ? currancyConvertor(
                      statsWalletPrev["total_marketer_orders_delivered"][0][
                        "stats"
                      ] || 0
                    )
                  : 0}
              </p>
            </div>
            {/* <div className="foot d-flex align-items-center gap-2 flex-row-reverse">
              <IconsGen icon_name={"graphic5"} />
  
              <p style={{ color: "rgba(155, 28, 255, 1)" }}>+5%</p>
            </div> */}
          </div>
          <div className={`${boxsSyles} ${styles.box1}`}>
            <div
              className="head d-flex justify-content-center flex-row align-items-center gap-2"
              dir={box_head_dir}
            >
              <IconsGen icon_name={"ordersShipped"} />
              <p className="m-0">{_("panel.shippedorders")}</p>
            </div>
            <div className="number">
              <p>
                {statsWalletPrev["total_marketer_orders_shipped"]
                  ? currancyConvertor(
                      statsWalletPrev["total_marketer_orders_shipped"][0][
                        "stats"
                      ] || 0
                    )
                  : 0}
              </p>
            </div>
            {/* <div className="foot d-flex align-items-center gap-2 flex-row-reverse">
              <IconsGen icon_name={"bluewaves"} />
  
              <p style={{ color: "rgba(0, 194, 255, 1)" }}>+5%</p>
            </div> */}
          </div>
          <div className={`${boxsSyles} ${styles.box1}`}>
            <div
              className="head d-flex justify-content-center flex-row align-items-center gap-2"
              dir={box_head_dir}
            >
              <IconsGen icon_name={"ordersPending"} />
              <p className="m-0">{_("panel.pendingorders")}</p>
            </div>
            <div className="number">
              <p>
                {statsWalletPrev["total_marketer_orders_prepared"]
                  ? currancyConvertor(
                      statsWalletPrev["total_marketer_orders_prepared"][0][
                        "stats"
                      ] || 0
                    )
                  : 0}
              </p>
            </div>
            {/* <div className="foot d-flex align-items-center gap-2 flex-row-reverse">
              <IconsGen icon_name={"redwaves"} />
  
              <p style={{ color: "rgba(225, 38, 22, 1)" }}>+5%</p>
            </div> */}
          </div>
          <div
            className="d-flex flex-column gap-1 align-items-center p-4 flex-grow-1 col-5 col-md-3"
            style={{ border: "none" }}
          ></div>
        </div>
      </div>
    );
  }
};

export default PreviewStats;
