import React, { useState } from "react";
import { Tabs } from "@mantine/core";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import YallaShop from "./YallaShop";
import YallaShopKeys from "./YallaShopKeys";

const YallaShopTabs = () => {
  const [_] = useTranslation("global");
  return (
    <Tabs defaultValue={"links"} className="w-100">
       <Helmet>
         <title>{_("panel.easyOrders")}</title>
       </Helmet>
       <Tabs.List>
         <Tabs.Tab value="links" className="fs-5 text-teal-500">
           {_("panel.link")}
         </Tabs.Tab>
         <Tabs.Tab value="keys" className="fs-5 text-teal-500">
           {_("panel.keys")}
         </Tabs.Tab>
       </Tabs.List>

       <Tabs.Panel value="links"> 
        <YallaShop />
       </Tabs.Panel>

       <Tabs.Panel value="keys">
         <YallaShopKeys />
       </Tabs.Panel>
     </Tabs>
  );
};

export default YallaShopTabs;
