import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AxiosController from "../authentication/AxiosController";
import { notifyError } from "../toastify/toastify";
import Loader from "../loader/Loader";

const CategorySchema = Yup.object().shape({
  english_name: Yup.string().required("English name is required"),
  arabic_name: Yup.string().required("Arabic name is required"),
  is_available: Yup.boolean(),
  is_banner: Yup.boolean(),
});

// New schema for image upload
const ImageSchema = Yup.object().shape({
  image: Yup.mixed().required("An image file is required"),
});

const CreateAndUpdateCategory = ({
  show,
  setShow,
  action,
  editCategory,
  fetchCategories,
  showImageModal,
  setShowImageModal,
}) => {
  const [_] = useTranslation("global");
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");

  // New state for image upload
  const [imageFile, setImageFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (values, { resetForm }) => {
    setLoading(true);
    // Determine the API URL based on action
    const url =
      action === "update"
        ? `api/${slug}/${lang}/categories/update/${editCategory.id}`
        : `api/${slug}/${lang}/categories/add-category`;

    // Determine the HTTP method
    const method = action === "update" ? "put" : "post";

    // Prepare headers
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    // Create a FormData object for file uploads only if creating
    let requestData;

    if (action === "create") {
      const formData = new FormData();

      // Append fields to formData
      formData.append("english_name", values.english_name);
      formData.append("arabic_name", values.arabic_name);
      formData.append("is_available", values.is_available);
      formData.append("is_banner", values.is_banner);

      // Append image file if it exists
      if (imageFile) {
        formData.append("image", imageFile);
      }

      requestData = formData; // Use formData for create
    } else {
      // For update, just send the plain object
      requestData = {
        english_name: values.english_name,
        arabic_name: values.arabic_name,
        is_available: values.is_available,
        is_banner: values.is_banner,
      };
    }

    // Make the Axios request
    AxiosController[method](url, requestData, { headers })
      .then(() => {
        setLoading(false);
        fetchCategories(); // Refresh the categories after submission
        setShow(false); // Close modal
        resetForm(); // Reset form fields
        setImageFile(null); // Reset image file state
        setPreview(null); // Reset image preview state
      })
      .catch((error) => {
        setLoading(false);
        if (error.response && typeof error.response.data === "object") {
          const errorMessages = Object.entries(error.response.data)
            .map(
              ([key, value]) =>
                `${key}: ${Array.isArray(value) ? value.join(", ") : value}`
            )
            .join(", ");

          notifyError(errorMessages); // Display keys and values as a string
        } else {
          notifyError(
            Array.isArray(error.response.data)
              ? error.response.data.join(", ")
              : error.response.data
          );
        }
      });
  };

  const handleImageUpdate = (values ) => {
 
    setLoading(true);
    const url = `api/${slug}/${lang}/categories/update-image/${editCategory.id}`;

    const formData = new FormData();
    formData.append("image", imageFile);

    AxiosController.post(url, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
      .then(() => {
        setLoading(false);
        fetchCategories(); // Refresh categories after image update
        handleCloseImageModal()
      })
      .catch((e) => {
        setLoading(false);
        console.error(e);
      });
  };

  const handleCloseImageModal = () => {
    setShowImageModal(false);
    setImageFile(null);
    setPreview(null);
  };

  const handleCloseCreate = () => {
    setShow(false);
    setImageFile(null);
    setPreview(null);
  };

  return (
    <div>
      {/* Modal for Create/Update */}
      <Modal show={show} onHide={handleCloseCreate}>
        <Loader active={loading} />
        <Modal.Header closeButton>
          <Modal.Title>
            {action === "update" ? _("panel.update") : _("panel.create")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              english_name: editCategory?.english_name || "",
              arabic_name: editCategory?.arabic_name || "",
              is_available: editCategory?.is_available || false,
              is_banner: editCategory?.is_banner || false,
            }}
            validationSchema={CategorySchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="form-group py-1">
                  <label className="mb-2">{_("panel.enproductName")}</label>
                  <Field name="english_name" className="form-control" />
                  <ErrorMessage
                    name="english_name"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="form-group py-1">
                  <label className="mb-2">{_("panel.productName")}</label>
                  <Field name="arabic_name" className="form-control" />
                  <ErrorMessage
                    name="arabic_name"
                    component="div"
                    className="text-danger"
                  />
                </div>

                {/* Image upload for Create action */}
                {action === "create" && (
                  <>
                    <div className="form-group py-1">
                      <Field
                        className="ms-2"
                        type="checkbox"
                        name="is_available"
                      />
                      <label>{_("panel.available")}</label>
                    </div>
                    <div className="form-group py-1">
                      <Field
                        className="ms-2"
                        type="checkbox"
                        name="is_banner"
                      />
                      <label>{_("panel.banners")}</label>
                    </div>
                    <div className="form-group py-1">
                      <input
                        type="file"
                        name="image"
                        accept="image/*"
                        onChange={(event) => {
                          const file = event.currentTarget.files[0];
                          setImageFile(file);
                          // Create a preview URL for the selected image
                          if (file) {
                            const imageUrl = URL.createObjectURL(file);
                            setPreview(imageUrl);
                          }
                        }}
                        className="form-control"
                      />
                      <ErrorMessage
                        name="image"
                        component="div"
                        className="text-danger"
                      />
                      {/* Image Preview */}
                      {preview && (
                        <div className="image-preview py-2">
                          <img
                            src={preview}
                            alt="Image Preview"
                            style={{ maxHeight: "200px", maxWidth: "100%" }}
                          />
                        </div>
                      )}
                    </div>
                  </>
                )}
                <Button
                  variant="primary"
                  style={{
                    background: "#59C6C9",
                    color: "white",
                    border: "none",
                  }}
                  type="submit"
                  disabled={loading}
                >
                  {_("panel.save")}
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

      {/* Modal for Updating Image */}
      <Modal show={showImageModal} onHide={handleCloseImageModal}>
        <Modal.Header closeButton>
          <Modal.Title>{_("panel.updateImage")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              image: null, // No initial image since this is for image upload only
            }}
            validationSchema={ImageSchema}
            onSubmit={(values, { setSubmitting }) => {
              // setSubmitting(false);
              handleImageUpdate(values.image);
            }}
          >
            {({ setFieldValue, isSubmitting }) => (
              <Form>
                <div className="form-group py-1">
                  <input
                    type="file"
                    name="image"
                    accept="image/*"
                    onChange={(event) => {
                      const file = event.currentTarget.files[0];
                      setImageFile(file);
                      setFieldValue("image", file);

                      // Create a preview URL for the selected image
                      if (file) {
                        const imageUrl = URL.createObjectURL(file);
                        setPreview(imageUrl);
                      }
                    }}
                    className="form-control"
                  />
                  <ErrorMessage
                    name="image"
                    component="div"
                    className="text-danger"
                  />
                </div>
                {(editCategory?.svg_icon_name || editCategory?.png_img) &&
                  !preview && (
                    <div className="image-preview py-2">
                      <img
                        src={editCategory.svg_icon_name || editCategory.png_img} // Use the existing icon's URL
                        alt="Current Image"
                        style={{ maxHeight: "200px", maxWidth: "100%" }}
                      />
                    </div>
                  )}

                {/* Image Preview */}
                {preview && (
                  <div className="image-preview py-2">
                    <img
                      src={preview}
                      alt="Image Preview"
                      style={{ maxHeight: "200px", maxWidth: "100%" }}
                    />
                  </div>
                )}

                <Button
                  variant="primary"
                  style={{
                    background: "#59C6C9",
                    color: "white",
                    border: "none",
                  }}
                  type="submit"
                  disabled={loading}
                >
                  {_("panel.save")}
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CreateAndUpdateCategory;
