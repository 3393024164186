import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import IconsGen from "../../icons_generator/IconsGen";
import AxiosController from "../../authentication/AxiosController";
import { notifyError, notifySuccess } from "../../toastify/toastify";
import ConfirmModal from "../../modals/ConfirmModal";
import FunnelServerUrl from "../../authentication/FunnelServerUrl";
import { UpdateLandingPageOffers } from "../../modals/UpdateLandingPageOffers";
import Loader from "../../loader/Loader";

const LandingPageOffers = () => {
  const { lang, slug, id } = useParams();
  const [_] = useTranslation("global");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [offerId, setOfferId] = useState(null);
  const [users_landing_id, setUsers_landing_id] = useState(null);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const [offers, setOffers] = useState([]);

  const handleConfirmDelete = () => {
    setLoading(true);
    AxiosController.post(
      `apiv2/${slug}/${lang}/builder/offers/delete`,
      { offer_id: offerId, users_landing_id },
      { headers: { Authorization: `Bearer ${token}` } }
    )
      .then(() => {
        fetchOffers();
        notifySuccess(_("panel.delete_successful"));
      })
      .catch((e) => notifyError(e.message))
      .finally(() => {
        setLoading(false);
        setShowConfirmModal(false);
      });
  };

  const handleShowDeleteModal = (id, users_landing_id) => {
    setShowConfirmModal(true);
    setOfferId(id);
    setUsers_landing_id(users_landing_id)
  };

  const handleShowUpdateModal = (offer) => {
    setShowUpdateModal(true);
    setSelectedOffer(offer);
  };

  const fetchOffers = () => {
    setLoading(true);
    AxiosController.get(`apiv2/${slug}/${lang}/builder/landings/${id}/offers`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => setOffers(res.data))
      .catch((e) => console.error("Error fetching offers:", e))
      .finally(() => setLoading(false));
  };


  useEffect(() => {
    fetchOffers();
  }, []);

  return (
    <div className="d-flex flex-column flex-lg-column my-2 gap-3 position-relative" style={{ width: "100%" }}>


      <Loader active={loading} />




      <div
        className={`header d-flex ${lang === "en" ? "flex-row" : "flex-row"
          } gap-3 align-items-center justify-content-between`}
      >
        <h3 className="fs-1" style={{ color: "rgba(89, 198, 201, 1)" }}>
          {_("panel.offers")}
        </h3>


      </div>
      <div
        style={{ borderRadius: "40px" }}
        className={`table-responsive border my-3 d-flex ${lang === "en" ? "flex-row" : "flex-row-reversed"
          } align-items-center`}
      >      <ConfirmModal
          showModal={showConfirmModal}
          setShowModal={setShowConfirmModal}
          action={handleConfirmDelete}
          modalBody={_("deleteSure")}
          modalHeader={_("deleteProduct")}
          confirmButton={_("confirm")}
        />

        <UpdateLandingPageOffers
          show={showUpdateModal}
          handleClose={() => setShowUpdateModal(false)}
          initialOfferData={selectedOffer}
          fetchOffers={() => fetchOffers()}
        />

        <table className="table table-borderless">
          <thead>
            <tr>
              <th>#</th>
              <th>{_("panel.date")}</th>
              <th>{_("panel.after_discount")}</th>
              <th>{_("panel.before_discount")}</th>
              <th>{_("panel.quantity")}</th>
              <th>{_("panel.edit")}</th>
              <th>{_("panel.delete")}</th>
            </tr>
          </thead>
          <tbody>
            {offers.map((offer, index) => (
              <tr key={offer?.id}>
                <td>{index + 1}</td>
                <td>{new Date(offer?.created_at).toLocaleDateString()}</td>
                <td>{offer?.price}</td>
                <td>{offer?.price_before}</td>
                <td>{offer?.quantity}</td>
                <td onClick={() => handleShowUpdateModal(offer)}>
                  <IconsGen icon_name={"edit"} style={{ cursor: 'pointer' }} />
                </td>
                <td onClick={() => handleShowDeleteModal(offer?.id, offer?.users_landing_id)}>
                  <IconsGen icon_name={"delete"} style={{ cursor: 'pointer' }} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LandingPageOffers;
