import React, { useEffect, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AxiosController from "../../../authentication/AxiosController";
import { format } from "date-fns";
import DateRangePickerComponent from "../../../date/DateRangePickerComponent";
const SearchComponent = () => {
  const [_] = useTranslation("global");
  const location = useLocation();
  const Navigate = useNavigate();
  const urlParams = new URLSearchParams(location.search);
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token"); 
  const [code, handleCode] = useState("");
  const [name, setName] = useState("");
  const [sysCommission, setSysCommission] = useState("");
  const [sellerCommission, setSellerCommission] = useState("");
  const [sku, setSku] = useState("");
  const [userId, handleUserId] = useState("");
  const [status, handleStatus] = useState("");
  const [startDate, handleStartDate] = useState("");
  const [endDate, handleEndDate] = useState("");
  const role = localStorage.getItem("role");
  const [loading, handleLoader] = useState(true);
  const [categories, handleCategories] = useState([]); 
  const [productCategory, handleProductCategory] = useState("");
 const [state, setState] = useState({
    selection: {
      startDate: new Date(),
      endDate:new Date(),
      key: 'selection'
    }
  });
  const changeParam = () => {
    urlParams.set("code", code);
    urlParams.set("user_id", userId);
    urlParams.set("start_date", startDate);
    urlParams.set("end_date", endDate);
    urlParams.set("status", status);
    urlParams.set("sysCommission", sysCommission);
    urlParams.set("sellerCommission", sellerCommission);
    urlParams.set("name", name);
    urlParams.set("category_id", productCategory);
    urlParams.set("sku", sku);
    Navigate(`?${urlParams.toString()}`);
  };

  const handleApply = (event, picker) => {
    const { startDate, endDate } = state.selection;
    const formattedStartDate = new Date(startDate.setHours(0, 0, 0, 0));
    const formattedEndDate = new Date(endDate.setHours(0, 0, 0, 0));

    // Format dates to YYYY-MM-DD for API
    const apiStartDate = format(formattedStartDate, "yyyy-MM-dd");
    const apiEndDate = format(formattedEndDate, "yyyy-MM-dd");
    // const startDate = picker.startDate.format("YYYY-MM-DD");
    // const endDate = picker.endDate.format("YYYY-MM-DD");
    handleStartDate(apiStartDate);
    handleEndDate(apiEndDate);
    // const startDate = picker.startDate.format("YYYY-MM-DD");
    // const endDate = picker.endDate.format("YYYY-MM-DD");
    // handleStartDate(startDate);
    // handleEndDate(endDate);
  };
  const resetHandler = () => {
    handleCode("");
    handleUserId("");
    handleStatus("");
    handleStartDate("");
    Navigate("");
    handleProductCategory("");
  };
  const fetchCategories = () => {
    AxiosController.get(`api/${slug}/${lang}/categories`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        handleCategories(res.data);
        handleLoader(false);
      })
      .catch((e) => {handleLoader(false);});
  };
  useEffect(() => { 
    fetchCategories()
  }, [slug, lang, token]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        changeParam();
      }}
    >
      <div className="w-100 d-flex flex-column gap-1 mt-2">
        <div className="inputs-row d-flex w-100 gap-2 my-3 flex-column  flex-md-row">
          <Col>
            <Form.Group
              className=" flex-grow-1"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Control
                type="text"
                placeholder={_("panel.code")}
                onChange={(e) => {
                  handleCode(e.target.value);
                }}
                value={code}
              />
            </Form.Group>
          </Col>
          <div className="mb-3">
        
            <Form.Select
              aria-label="Default select example"
              value={productCategory}
              onChange={(e) => {
                handleProductCategory(e.target.value);
              }}
            >
              <option value={0}>   {_("panel.category")}</option>
              {categories.length
                ? categories.map((cat) => {
                    return <option value={cat.id}>{cat.name}</option>;
                  })
                : ""}
            </Form.Select>
          </div>
          {role !== "seller" ? (
            <Col>
              <Form.Group
                className=" flex-grow-1"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Control
                  type="text"
                  placeholder={_("panel.code2")}
                  onChange={(e) => {
                    handleUserId(e.target.value);
                  }}
                  value={userId}
                />
              </Form.Group>
            </Col>
          ) : (
            ""
          )}
          <Col>
            <Form.Group className=" flex-grow-1">
              <Form.Select
                onChange={(e) => {
                  handleStatus(e.target.value);
                }}
                value={status}
              >
                <option value={""} disabled>
                  الحالة
                </option>
                <option selected={false} value={1}>
                  {_("panel.Active")}
                </option>
                <option selected={false} value={0}>
                  {_("panel.Inactive")}
                </option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              className=" flex-grow-1"
              controlId="exampleForm.ControlInput1"
            >
               <DateRangePickerComponent
                  state={state}
                  setState={setState}
                  applyDateRange={() => handleApply()}
                />
              {/* <DateRangePicker onApply={handleApply}>
                <input type="text" className="form-control" />
              </DateRangePicker> */}
            </Form.Group>
          </Col>
        </div>
        <div className="inputs-row d-flex w-100 gap-2 flex-column  flex-md-row">
          <Col>
            <Form.Group
              className=" flex-grow-1"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Control
                type="text"
                placeholder={_("panel.productName2")}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                value={name}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              className=" flex-grow-1"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Control
                type="text"
                placeholder={_("panel.sellerCommission")}
                onChange={(e) => {
                  setSellerCommission(e.target.value);
                }}
                value={sellerCommission}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              className=" flex-grow-1"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Control
                type="text"
                placeholder={_("panel.sysCommission")}
                onChange={(e) => {
                  setSysCommission(e.target.value);
                }}
                value={sysCommission}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              className=" flex-grow-1"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Control
                type="text"
                placeholder={'SKU'}
                onChange={(e) => {
                  setSku(e.target.value);
                }}
                value={sku}
              />
            </Form.Group>
          </Col>
          
        </div>
        <div className="inputs-row d-flex w-100 gap-2"></div>
        <div className="action-inputs">
          <input
            className=" px-5 align-self-end py-2"
            style={{
              backgroundColor: "rgb(243, 168, 55)",
              border: "none",
              borderRadius: "7px",
              color: "white",
              textAlign: "center",
            }}
            onClick={() => {
              changeParam();
            }}
            type="submit"
            value={_("panel.search")}
          />
          <Button
            style={{ backgroundColor: "rgb(23, 43, 77)" }}
            variant="dark"
            className="d-inline"
            onClick={resetHandler}
          >
            {_("panel.reset")}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default SearchComponent;
