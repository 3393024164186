import React, { useEffect, useState } from "react";
import Loader from "../../../loader/Loader";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useTranslation } from "react-i18next";
import AxiosController from "../../../authentication/AxiosController";
import { useParams } from "react-router-dom";
import { Pie } from "react-chartjs-2";
import { Table } from "react-bootstrap";
import { Helmet } from "react-helmet";
import DateRangePickerComponent from "../../../date/DateRangePickerComponent";
import { format } from "date-fns";

const Govs = () => {
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");

  const [_] = useTranslation("global");
  const [loading, handleLoader] = useState(false);
  const [govsOrders, setGovsOrders] = useState([]);
  const [govsProfit, setGovsProfit] = useState([]); 
  const [state, setState] = useState({
    selection: {
      startDate: new Date(),
      endDate:new Date(),                
      key: 'selection'
    }
  });
 
  const applyDateRange = () => {
    const { startDate, endDate } = state.selection; 
    const formattedStartDate = new Date(startDate.setHours(0, 0, 0, 0));
    const formattedEndDate = new Date(endDate.setHours(0, 0, 0, 0));
  
    // Format dates to YYYY-MM-DD for API
    const apiStartDate = format(formattedStartDate, 'yyyy-MM-dd');
    const apiEndDate = format(formattedEndDate, 'yyyy-MM-dd');
    get_couriers(apiStartDate, apiEndDate);
  }; 
  const get_couriers = (startDate, endDate) => {
    handleLoader(true);
    AxiosController.get(`api/${slug}/${lang}/reports/government-orders`, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    })
      .then((res) => {
        setGovsOrders(res.data.governments_orders);
        setGovsProfit(res.data.governments_profit);
        handleLoader(false);
      })
      .catch((e) => {handleLoader(false);});
  };


  useEffect(() => {
    const { startDate, endDate } = state.selection; 
    get_couriers(startDate, endDate);
  }, []);
  const totalDelivered = govsOrders.reduce(
    (acc, order) => acc + order.total_delivered,
    0
  );
  const totalMarketerCommission = govsProfit.reduce(
    (acc, profit) => acc + profit.marketer_commission,
    0
  );
  const totalSellerCommission = govsProfit.reduce(
    (acc, profit) => acc + profit.seller_commission,
    0
  );
  const totalSysCommission = govsProfit.reduce(
    (acc, profit) => acc + profit.sys_commission,
    0
  );
  const totalShippedCount = govsOrders.reduce((acc, item) => acc + item.shipped_count, 0);


  
  return (
    <div className="flex-grow-1 stats-component position-relative">
          <Helmet>
   <title>{_("home.province")}</title> 
</Helmet>
      <Loader active={loading} />
      <div className="reports-header d-flex flex-column flex-md-row py-2 justify-content-between flex-wrap">
        <h3 style={{ color: "rgba(23, 43, 77, 1)" }}>{_("home.provinces")}</h3>
        <div className="interval d-flex flex-column gap-2 flex-md-row-reverse">
        <DateRangePickerComponent 
                state={state} 
                setState={setState}    
                applyDateRange={()=>applyDateRange()}
              />
        </div>
      </div>
      <h2 className="text-center py-2">{_("panel.govsOrders")}</h2>
      <div className="chart-container row">
        <div className="col-2 col-5 m-auto">
          <Pie
            data={dataConstructor(govsOrders, "arabic_name")}
            className="text-center"
          />
        </div>
      </div>
      <hr />
      <h2 className="text-center py-2">{_("panel.govsProfit")}</h2>
      <div className="chart-container row">
        <div className="col-12 col-md-4">
          <h4 className="m-0 text-center py-2">
            {_("panel.نسب ارباح المسوقين")}
          </h4>
          <Pie
            data={dataConstructor(
              govsProfit,
              "arabic_name",
              "marketer_commission"
            )}
            className="text-center"
          />
        </div>
        <div className="col-12 col-md-4">
          <h4 className="m-0 text-center py-2">
            {_("panel.نسب ارباح التجار")}
          </h4>
          <Pie
            data={dataConstructor(
              govsProfit,
              "arabic_name",
              "seller_commission"
            )}
            className="text-center"
          />
        </div>
        <div className="col-12 col-md-4">
          <h4 className="m-0 text-center py-2">
            {_("panel.نسب ارباح الشركه")}
          </h4>
          <Pie
            data={dataConstructor(govsProfit, "arabic_name", "sys_commission")}
            className="text-center"
          />
        </div>
      </div>

      <div className="data-table mt-4">
        <hr className="mt-4 mb-4" />
        <Table>
          <thead>
            <tr className="text-center" style={{ fontSize: "16px" }}>
              <th>{_("home.provinces")}</th>
              <th>{_("panel.govsOrders")}</th>
              <th>
              {_("panel.shipped_orders")}   
            </th>
              <th> 
                 {_("panel.اجمالي ربحيه المسوقين")} 
           </th>

              <th> 
                {_("panel.اجمالي ربحيه التجار")}        
              </th>
              <th> 
                {_("panel.sysCommission")}
              </th>
            </tr>
          </thead>
          <tbody>
            {govsOrders.map((order, index) => (
              <tr
                key={index}
                className="text-center"
                style={{ fontSize: "16px" }}
              >
                <td>{order.arabic_name}</td>
                <td>{order.total_delivered.toLocaleString()}</td>
                <td>{order.shipped_count.toLocaleString()}</td>
                <td>
                <span className="fw-bold"> ({  (govsProfit[index]?.marketer_commission  /totalMarketerCommission *100).toLocaleString()} %)</span> 
 
    <br/> {govsProfit[index]?.marketer_commission.toLocaleString() ||
    "N/A"}
   
    

</td>

                <td>
         <span className="fw-bold"> ({  (govsProfit[index]?.seller_commission  /totalSellerCommission *100).toLocaleString()} %)</span>      
 
 <br/>
                  {govsProfit[index]?.seller_commission.toLocaleString() ||
                    "N/A"}
                </td>
                <td>
                <span className="fw-bold"> ({  (govsProfit[index]?.sys_commission  /totalSysCommission *100).toLocaleString()} %)</span>    
 
 <br/>
                  {govsProfit[index]?.sys_commission.toLocaleString() || "N/A"}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr className="text-center" style={{ fontSize: "16px" }}>
              <th>{_("panel.reportTotal")}</th>
              <th>{totalDelivered.toLocaleString()}</th> 
              <th>{totalShippedCount.toLocaleString()}</th>
              <th>{totalMarketerCommission.toLocaleString()}</th>
              <th>{totalSellerCommission.toLocaleString()}</th>
              <th>{totalSysCommission.toLocaleString()}</th>
            </tr>
          </tfoot>
        </Table>
      </div>
    </div>
  );
};

const dataConstructor = (
  dataArray,
  labelName,
  totalAreaOrders,
  valueName = "stats"
) => {
  return {
    labels: dataArray.map((data) => data[labelName]),
    datasets: [
      {
        label: "",
        data: dataArray.map(
          (data) => data[totalAreaOrders ?? "total_delivered"]
        ),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(201, 203, 207, 0.2)",
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(201, 203, 207, 0.2)",
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(201, 203, 207, 0.2)",
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(201, 203, 207, 0.2)",
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
        ],
      },
    ],
  };
};

export default Govs;
