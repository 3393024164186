import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AxiosController from "../../authentication/AxiosController";
import Loader from "../../loader/Loader";
import { Button, Table } from "react-bootstrap";
import FinanceSearchComponent from "./FinanceSearchComponent";

export default function FinanceSuppliersDetails() {
  const { slug, lang, id } = useParams();
  const { t: _ } = useTranslation("global");
  const box_head_dir = lang === "en" ? "ltr" : "rtl";
  const [loading, setLoading] = useState(false);
  const [supplierData, setSupplierData] = useState(null);
  const [search, setSearch] = useState(false); // Toggle for search panel
  const location = useLocation();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const handleSearch = (searchParams) => {
    const urlParams = new URLSearchParams(location.search);
    Object.entries(searchParams).forEach(([key, value]) => {
      if (value) {
        urlParams.set(key, value);
      } else {
        urlParams.delete(key);
      }
    });
    navigate(`?${urlParams.toString()}`);
  };

  const fetchSupplierDetails = useCallback(async () => {
    setLoading(true);
    try {
      const urlParams = new URLSearchParams(location.search);
      const params = Object.fromEntries(urlParams.entries()); // Include search params
      const res = await AxiosController.get(
        `api/${slug}/${lang}/suppliers/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
          params,
        }
      );
      setSupplierData(res?.data);
    } catch (error) {
      console.error("Error fetching supplier details:", error);
    } finally {
      setLoading(false);
    }
  }, [id, lang, slug, token, location.search]);

  useEffect(() => {
    fetchSupplierDetails();
  }, [fetchSupplierDetails]);

  return (
    <div className="flex-grow-1 stats-component">
      <Button
        className="fs-5 px-5 w-25 align-self-start"
        style={{
          backgroundColor: "rgb(89, 198, 201)",
          border: "none",
          borderRadius: "7px",
        }}
        onClick={() => setSearch(!search)}
      >
        {_("panel.search")}
      </Button>
      {search && <FinanceSearchComponent onSearch={handleSearch} />}

      <div className="info-group d-flex flex-md-row flex-column gap-2">
        <div className="info-group d-flex gap-2 align-items-center">
          <span className="user-key">
            <p>
              {_("panel.supplier")} :{" "}
              {supplierData && (
                <span>{"#" + supplierData?.id + " " + supplierData?.name}</span>
              )}
            </p>
          </span>
        </div>
      </div>
      <div className="reports-container my-3 d-flex flex-column gap-2 px-2 position-relative">
        <Loader active={loading} />
        <div className="reports-header d-flex flex-column flex-md-row py-2 justify-content-between flex-wrap">
          <h3 className="fs-2 mb-4" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.transactions")}
          </h3>
        </div>
        <div className="row" dir={box_head_dir}>
          <div
            className="col-12"
            style={{ position: "relative", overflowX: "auto", height: "100%" }}
          >
            <Table
              responsive
              dir={box_head_dir}
              className="reports-table text-nowrap text-center"
            >
              <thead>
                <tr className="text-center">
                  <th>#</th>
                  <th>{_("panel.date")}</th>
                  <th>{_("panel.productTitle")}</th>
                  <th>sku</th>
                  <th>{_("panel.quantity")}</th>
                  <th>{_("finance_search.is_cash")}</th>
                  <th>{_("panel.price")}</th>
                  <th>{_("panel.status")}</th>
                </tr>
              </thead>
              <tbody>
                {supplierData?.transactions &&
                  supplierData?.transactions.map((transaction, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        {new Intl.DateTimeFormat("en-GB", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: true,
                        })
                          .format(new Date(transaction.created_at))
                          .replace(",", "")}
                      </td>
                      <td>
                        #{transaction.product_id}
                        <br />
                        {transaction.product_name}
                      </td>
                      <td>{transaction.product_sku}</td>
                      <td>{transaction.quantity}</td>
                      <td>
                        {transaction.is_cash
                          ? _("finance_search.cash")
                          : _("finance_search.postpaid")}
                      </td>
                      <td>{transaction.piece_price}</td>
                      <td>{transaction.status}</td>
                     
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
}
