import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Loader from "../../../loader/Loader";
import AxiosController from "../../../authentication/AxiosController";
import { Table } from "react-bootstrap";
import { Helmet } from "react-helmet";
import DateRangePickerComponent from "../../../date/DateRangePickerComponent";
import { Chart } from "primereact/chart";
import { subYears, startOfYear, endOfYear, startOfMonth, endOfMonth, subMonths, format } from "date-fns"; 

const OrderByDay = () => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const box_head_dir = lang === "en" ? "ltr" : "rtl";
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]); 

  const [state, setState] = useState({
    selection: {
      startDate:new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  });
 
  const applyDateRange = () => {
    const { startDate, endDate } = state.selection;
    const formattedStartDate = new Date(startDate.setHours(0, 0, 0, 0));
    const formattedEndDate = new Date(endDate.setHours(0, 0, 0, 0));
  
    // Format dates to YYYY-MM-DD for API
    const apiStartDate = format(formattedStartDate, 'yyyy-MM-dd');
    const apiEndDate = format(formattedEndDate, 'yyyy-MM-dd');
    get_order_by_day_report(apiStartDate, apiEndDate);
  };
  const get_order_by_day_report = (startDate, endDate) => {
    setLoading(true);
    AxiosController.get(
      `/api/${slug}/${lang}/reports/orders-by-days`,
      {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          start_date: startDate,
          end_date: endDate
        },
      }
    )
      .then((res) => {
        setLoading(false);
        setOrders(res.data || []);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    get_order_by_day_report();
  }, []);
 
  const chartData = {
    labels: orders.map((order) => order.day),
    datasets: [
      {
        label: _("panel.shipped_orders"),
        data: orders.map((order) => order.shipped_count),
        backgroundColor: "rgba(89, 198, 201, 0.6)",
        borderColor: "rgba(89, 198, 201, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: _("panel.shipped_orders") ,
      },
    },
  }; 
  return (
    <div className="flex-grow-1 stats-component" dir={box_head_dir}>
      <Helmet>
        <title>{_("panel.orderByDay")}</title>
      </Helmet>
      <div className="reports-container my-3 d-flex flex-column gap-2 px-2 position-relative">
        <Loader active={loading} />

        <div className="reports-header d-flex flex-column flex-md-row py-2 justify-content-between flex-wrap">
          <h3 className="fs-2 mb-4" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.orderByDay")}
          </h3>
          <DateRangePickerComponent
            state={state}
            setState={setState} 
            applyDateRange={applyDateRange}
          />
        </div>

        <div className="row">
 
          <Chart
            type="bar"
            data={chartData}
            options={options}
            style={{ width: "100%" }}
          />
        </div>

        <div className="row" dir={box_head_dir} style={{ height: "1200px" }}>
          <div
            className="col-12"
            style={{ position: "relative", overflowX: "auto", height: "100%" }}
          >
            <Table
              responsive
              dir={box_head_dir}
              className="reports-table text-nowrap text-center">
              <thead>
                <tr className="text-center">
                  <th>{_("panel.date")}</th>
                  <th>{_("panel.shipped_orders")}</th>
                  <th>{_("panel.deliveredOrders")}</th>
                  <th>{_("panel.marketer_commission")}</th>
                  <th>{_("panel.sys_commission")}</th>
                  <th>{_("panel.seller_commission")}</th>
                </tr>
              </thead>
              <tbody>
                {orders.sort((a, b) => new Date(b.day) - new Date(a.day)).map((order, index) => (
                  <tr key={index} className="text-center">
                    <td>{order?.day}</td>
                    <td>{order?.shipped_count?.toLocaleString()} </td>
                    <td>{order?.delivered_count?.toLocaleString()} </td>
                    <td>{order?.marketer_commission?.toLocaleString()}</td>
                    <td>{order?.sys_commission?.toLocaleString()}</td>
                    <td>{order?.seller_commission?.toLocaleString()} </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderByDay;
