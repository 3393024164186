import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AxiosController from "../../../../authentication/AxiosController";
import { useParams } from "react-router-dom";
import { downloadPDF } from "./print";
import { FaFilePdf } from "react-icons/fa6";

const Manifest = ({ selectedOrders }) => {
  const tableRef = useRef(null);
  const { t } = useTranslation("global");
  const [selected, handleselected] = useState([]);
  const { slug, lang } = useParams();
  const [loading, handleLoader] = useState(false);
  const token = localStorage.getItem("token");

  const orderIdsQueryString = selectedOrders
    .map((order) => `order_ids[]=${order.id}`)
    .join("&");

  const get_selected_manifest = () => {
    handleLoader(true);
    return AxiosController.get(
      `api/${slug}/${lang}/orders/manifest?${orderIdsQueryString}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        handleselected(res.data);
        handleLoader(false);
        return res.data; // Return the data to allow chaining
      })
      .catch((e) => {
        handleLoader(false);
        console.log("Error fetching manifest:", e);
      });
  };

  const handleDownloadClick = () => {
    get_selected_manifest().then((data) => {
      if (data && data.length > 0) {
        downloadPDF(
          data,
          t("panel.total"),
          t("panel.orderContent"),
          t("panel.courierNumber"),
          t("panel.totalorders2")
        );
      } else {
        console.log("No data available to download.");
      }
    });
  };

  return (
    <>
      <button
        onClick={handleDownloadClick}
        className="btn  text-nowrap"
        style={{ border: "1px solid #172B4D", color: "#172B4D" }}
      >
        <FaFilePdf className="fs-3 px-1 text-danger" />
        {t("panel.Manifest")}
      </button>
    </>
  );
};

export default Manifest;
