import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form"; // Import Form for dropdown
import { useTranslation } from "react-i18next";

function ConfirmModalPaymentRequest({
  showModal,
  setShowModal,
  action,
  modalHeader,
  modalBody,
  confirmButton,
  accounts, // Added to receive accounts for the dropdown
  selectedAccount, 
  setSelectedAccount, 
}) { 
  const [_] = useTranslation("global");
 
  const handleClose = () => {
    setShowModal(false); 
  };
  
  const handleAction = () => {
    action();
    handleClose();
  };

  const handleAccountChange = (e) => {
    setSelectedAccount(e.target.value);
  };

  return (
    <>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{_(`panel.${modalHeader}`)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Main modal body content */}
          <p>{modalBody}</p>
          
          {/* Dropdown form for selecting account */}
          <Form.Group controlId="accountSelect">
            <Form.Label>{_("panel.thebank")}</Form.Label>
            <Form.Control
              as="select"
              value={selectedAccount} // Bind the current selected account
              onChange={handleAccountChange} // Update state when selection changes
            >
              <option value="">{_("panel.thebank")}</option>
              {accounts && accounts?.map((account) => (
                <option key={account?.id} value={account?.id}>
                  {account?.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button style={{ backgroundColor: "red" }} onClick={handleClose}>
            {_(`panel.close`)}
          </Button>
          <Button variant="primary" onClick={handleAction}>
            {_(`panel.${confirmButton}`)}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ConfirmModalPaymentRequest;
