import React, { useEffect, useState } from "react";
import AxiosController from "../../authentication/AxiosController";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Table } from "react-bootstrap";
import * as XLSX from "xlsx";
import IconsGen from "../../icons_generator/IconsGen";
import Loader from "../../loader/Loader";

const FinanceMarketerDetails = () => {
  const token = localStorage.getItem("token");
  const { slug, lang, id } = useParams();
  const [_] = useTranslation("global");
  const box_head_dir = lang === "en" ? "ltr" : "rtl";
  const [loading, handleLoader] = useState(false);
  const [marketerData, handleMarketerData] = useState({});

  const get_marketer_details = () => {
    AxiosController.get(
      `api/${slug}/${lang}/financials/marketer/${id}/overview`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        handleMarketerData(res.data);
        handleLoader(false);
      })
      .catch((e) => {
        handleLoader(false);
      });
  };

  useEffect(() => {
    get_marketer_details();
  }, []);

  const downloadExcel = () => {
    const formattedData = marketerData.map((item, index) => ({
      "#": index + 1,
      "Created At": item.created_at,
      Description: item.description,
      Type: item.type,
      Quantity: item.quantity,
      "Total Price": item.total_price,
      "Unit Price": item.unit_price,
      "Is In": item.is_in ? "In" : "Out",
    }));

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "finance_marketers");

    XLSX.writeFile(wb, "finance_marketers.xlsx");
  };

  return (
    <div>
      <div className="flex-grow-1 stats-component">
        <Helmet>
          <title>{_("panel.finance_marketers")}</title>
        </Helmet>

        <div className="reports-container my-3 d-flex flex-column gap-2 px-2 position-relative ">
          <Loader active={loading} />

          <div className="reports-header d-flex flex-column flex-md-row py-2 justify-content-between flex-wrap">
            <h3
              className="fs-2 mb-4"
              style={{ color: "rgba(89, 198, 201, 1)" }}
            >
              {_("panel.finance_marketers")}
            </h3>
            <button
              className="btn   text-nowrap"
              style={{ border: "1px solid #172B4D", color: "#172B4D" }}
              onClick={downloadExcel}
              disabled={loading}
            >
              <IconsGen icon_name={"excel"} /> {_("panel.downloadExcel")}
            </button>
          </div>
          <div className="row" dir={box_head_dir} style={{ height: "1200px" }}>
            <div
              className="col-12"
              style={{
                position: "relative",
                overflowX: "auto",
                height: "100%",
              }}
            >
              <Table
                responsive
                dir={box_head_dir}
                className="reports-table text-nowrap text-center"
              >
                <thead>
                  <tr className="text-center">
                    <th>#</th>
                    <th>{_("panel.date")}</th>
                    <th>{_("panel.description")}</th>
                    <th>{_("panel.type")}</th>
                    <th>{_("panel.marketer_commission")}</th>
                    <th>{_("panel.unit_price")}</th>
                    <th>{_("panel.price")}</th>
                    <th>{_("panel.is_in")}</th>
                  </tr>
                </thead>
                <tbody>
                  {marketerData && marketerData.length > 0 ? (
                    marketerData.map((item, index) => (
                      <tr key={index} className="text-center">
                        <td>{index + 1}</td>
                        <td>
                          {new Date(item?.created_at).toLocaleDateString(
                            "en-GB"
                          )}
                        </td>
                        <td>
                          {item?.id}
                          <br />
                          {item?.description}
                        </td>
                        <td>{item?.type}</td>
                        <td>{item?.total_marketer_commissions}</td>
                        <td>{parseInt(item?.unit_price).toLocaleString()}</td>
                        <td>{parseInt(item?.total_price).toLocaleString()}</td>
                        <td>{item?.is_in ? _("panel.in") : _("panel.out")}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8" className="text-center">
                        {_("panel.no_data")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinanceMarketerDetails;
