import React, { useEffect, useState } from "react";
import "./style.css";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Pagination } from "react-bootstrap";
import AxiosController from "../../authentication/AxiosController";
import Loader from "../../loader/Loader";
import MyLandpagesTableRow from "./MyLandpagesTableRow";
import { Helmet } from "react-helmet";

const fetchLanding = (
  slug,
  lang,
  token,
  handleLandingData,
  page,
  handleLoader
) => {
  AxiosController.get(`apiv2/${slug}/${lang}/builder/my-landpages`, {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      page: page,
      // include_theme_html_css: '',
      // include_user_html_css: ''
    },
  })
    .then((res) => {
       handleLandingData(res.data);

      handleLoader(false);
    })
    .catch((e) => {
      handleLoader(false);
    });
};
const MyLandpages = () => {
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");
  const [_] = useTranslation("global");
  const [landingData, handleLanding] = useState();
  const [page, handlePage] = useState(1);
  const [loading, handleLoader] = useState(false);
  
  const fetchHandler = () => {
    fetchLanding(slug, lang, token, handleLanding, page, handleLoader);

  }
  useEffect(() => {
    handleLoader(true);
    fetchHandler();
  }, [token, slug, lang, handleLanding, page, handleLoader]);

  return (
    <div
      className="d-flex flex-column flex-lg-column my-2 gap-3 position-relative"
      style={{ width: "100%" }}
    >
      <Helmet>
        <title>{_("panel.myLandpages")}</title>
      </Helmet>
      <Loader active={loading} />
      <div
        className="tables-container d-flex flex-column px-2 "
        style={{ position: "relative", flexGrow: 3 }}
      >
        <div
          className={`header d-flex ${
            lang === "en" ? "flex-row" : "flex-row"
          } gap-3 align-items-center justify-content-between`}
        >
          <h3 className="fs-1" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.myLandpages")}
          </h3>
        </div>

        <MyLandpagesTableRow landingData={landingData} fetchLanding={()=>fetchLanding(slug, lang, token, handleLanding,1,  handleLoader)} fetchHandler={fetchHandler} />

        {landingData  && (
       <Pagination className="m-auto flex-wrap" dir="ltr">
       <Pagination.First onClick={() => handlePage(1)} disabled={page === 1} />
       <Pagination.Prev onClick={() => handlePage(Math.max(page - 1, 1))} disabled={page === 1} />
     
       {/* Calculate page range */}
       {Array.from(
         { length: 5 },
         (_, index) => page - 2 + index
       )
         .filter((pageNum) => pageNum > 0 && pageNum <= landingData.last_page)
         .map((pageNum) => (
           <Pagination.Item
             key={pageNum}
             active={pageNum === page}
             onClick={() => handlePage(pageNum)}
           >
             {pageNum}
           </Pagination.Item>
         ))}
     
       <Pagination.Next
         onClick={() => handlePage(Math.min(page + 1, landingData.last_page))}
         disabled={page === landingData.last_page}
       />
       <Pagination.Last
         onClick={() => handlePage(landingData.last_page)}
         disabled={page === landingData.last_page}
       />
     </Pagination>
     
        )}
      </div>
    </div>
  );
};

export default MyLandpages;
