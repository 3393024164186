import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap';

import DoughnutChartDemo from './PieChart';
import LineCharts from './LineChart';
import Top from './Top';
const data = [
  {
    title: "طلبات في الشحن",
    value: 890,
    subtitle: "بقيمة",
    subtitleValue: 2030,
  },
  {
    title: "عدد الطلبات الشهري",
    value: 890,
    subtitle: "بقيمة",
    subtitleValue: 2030,
  },
  {
    title: "عدد الطلبات الموصله \n شهرن",
    value: 89,
    subtitle: "بقيمة",
    subtitleValue: 2030,
  },
  {
    title: "عدد المنتجات بالمخزن",
    value: 1234,
    subtitle: "",
    subtitleValue: 2030,
  },
  { title: "قيمة المخزون", value: 890, subtitle: "", subtitleValue: 2030 },
  { title: "عدد القطع", value: 890, subtitle: "", subtitleValue: 2030 },
  {
    title: "طلبات تم شحنها اليوم",
    value: 567,
    subtitle: "بقيمة",
    subtitleValue: 2030,
  },
  {
    title: "طلبات في البك اب",
    value: 1234,
    subtitle: "بقيمة",
    subtitleValue: 2030,
  },
  {
    title: "طلبات في المراجعه",
    value: 890,
    subtitle: "بقيمة",
    subtitleValue: 2030,
  },
  {
    title: "اجمالي النبيعات بالشحن",
    value: 123,
    subtitle: "",
    subtitleValue: 2030,
  },
  {
    title: "اجمالي المبيعات بدون شحن",
    value: 567,
    subtitle: "بقيمة",
    subtitleValue: 2030,
  },
  {
    title: "القابل للسحب مسوقين",
    value: 123,
    subtitle: "",
    subtitleValue: 2030,
  },
  { title: "القابل للسحب تجار", value: 567, subtitle: "", subtitleValue: 2030 },
  { title: "رصيد متوقع مسوقين", value: 890, subtitle: "", subtitleValue: 2030 },
  { title: "رصيد متوقع تجار", value: 890, subtitle: "", subtitleValue: 2030 },
  {
    title: "ايرادات الشركة من الشحن",
    value: 890,
    subtitle: "",
    subtitleValue: 2030,
  },
  { title: "دعم الشركة للشحن", value: 123, subtitle: "", subtitleValue: 2030 },
  {
    title: "ايرادات الشركة من المبيعات",
    value: 567,
    subtitle: "",
    subtitleValue: 2030,
  },
  {
    title: "اجمالي ايرادات الشركة",
    value: 1234,
    subtitle: "",
    subtitleValue: 2030,
  },
  { title: "اجمالي المصاريف", value: 890, subtitle: "", subtitleValue: 2030 },
  {
    title: "الرصيد الحالي للشركة",
    value: 890,
    subtitle: "",
    subtitleValue: 2030,
  },
  { title: "قيمة المخزون", value: 890, subtitle: "", subtitleValue: 2030 },
];

const CardItem = ({ item, index }) => {
  const { title, value, subtitle, subtitleValue } = item;
   const bg = index % 2 === 0 ? "rgba(235, 254, 255, 1)" : "rgba(255, 235, 205, 1)";

  return (
    <Col xs={12} sm={6} md={4} lg={2} xl={2} className=" p-2">
      <Card
        className={`shadow-sm p-1 rounded-4 border-0 `}
           style={{ background: bg }}
      >
        <Card.Body className="text-center p-1">
          <Card.Title
            className="mb-1 fs-6 text-end pb-1"
            style={{ height: "20px" }}
          >
            {title}
          </Card.Title>
          <Card.Text className="fs-4 fw-bold mb-0">{value}</Card.Text>

          <Card.Text className="fs-6 fw-normal d-flex justify-content-between align-items-center gap-1">
            <span>{subtitle && <span className="ms-2">{subtitle}</span>}</span>
            <span>{subtitleValue}</span>
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );
};
const AdminControlPanel = () => {
  return (
    <Container fluid className="mt-4">
    <Row className=" g-4 gy-4 justify-content-center">
      {data.map((item, index) => (
        <CardItem key={index + 1} index={index} item={item} />
      ))}
    </Row>

    <Row className='mt-5'>
      <Col md={5}>
      <DoughnutChartDemo/>
      </Col>
      <Col md={7}>
      <LineCharts/>
      </Col>
    </Row>
    <Row className='mt-5'>
<Top/>
    </Row>
  </Container>
  )
}

export default AdminControlPanel
