import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useParams } from "react-router-dom";

import "../style.css";
import { useTranslation } from "react-i18next";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Loader from "../../../loader/Loader";
import AxiosController from "../../../authentication/AxiosController";
import ProtectedSidebarRoute from "../../../authentication/ProtectedSidebarRoute";
import SellerStats from "./EarningsComponents/SellerStats";
import Roles from "../../../authentication/Roles";
import { currancyConvertor } from "../../../../utils/currancyConvertor";
import { Helmet } from "react-helmet";
import DateRangePickerComponent from "../../../date/DateRangePickerComponent";
import { format } from "date-fns";
const report_detailed_products = (
  slug,
  lang,
  token,
  handleStatsWallet,
  handleAltName,
  handleLoader,
  dates = {}
) => {
  AxiosController.get(`api/${slug}/${lang}/get-reports-detailed-products/`, {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      start: dates["start"] ? dates["start"] : "",
      end: dates["end"] ? dates["end"] : "",
    },
  })
    .then((res) => {
      handleStatsWallet(res.data.data.data.length ? res.data.data.data : []);
      handleAltName(res.data.alt_name.split(" ")[0]);
      handleLoader(false);
    })
    .catch((e) => {
      handleLoader(false);
    });
};
const ReportsTable = () => {
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const [reportDetailedProducts, reportDetailedProductsHandler] = useState([]);
  const [alt_name, handleAltName] = useState("");
  const [loading, handleLoader] = useState(false);

  const box_head_dir = lang === "en" ? "ltr" : "rtl";
  const [_] = useTranslation("global");

  const [state, setState] = useState({
    selection: {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  });
  const applyDateRange = () => {
    const { startDate, endDate } = state.selection;
    const formattedStartDate = new Date(startDate.setHours(0, 0, 0, 0));
    const formattedEndDate = new Date(endDate.setHours(0, 0, 0, 0));

    // Format dates to YYYY-MM-DD for API
    const apiStartDate = format(formattedStartDate, "yyyy-MM-dd");
    const apiEndDate = format(formattedEndDate, "yyyy-MM-dd");
    handleLoader(true);
    report_detailed_products(
      slug,
      lang,
      token,
      reportDetailedProductsHandler,
      handleAltName,
      handleLoader,
      { start: apiStartDate, end: apiEndDate }
    );
  };

  useEffect(() => {
    handleLoader(true);
    report_detailed_products(
      slug,
      lang,
      token,
      reportDetailedProductsHandler,
      handleAltName,
      handleLoader
    );
  }, [slug, lang, token]);

  return (
    <div className="position-relative" style={{ width: "100%" }}>
      <Helmet>
        <title>{_("panel.products")}</title>
      </Helmet>
      <Loader active={loading} />
      <ProtectedSidebarRoute
        component={SellerStats}
        role={role}
        roles={[Roles["SELLER"], Roles["FULFILLMENT"]]}
      />
      <div className="reports-header d-flex flex-column flex-md-row py-2 justify-content-between flex-wrap">
        <h3 style={{ color: "rgba(23, 43, 77, 1)" }}>
          {_("panel.topproducts")}
        </h3>
        <div className="interval d-flex flex-column gap-2 flex-md-row-reverse">
          <DateRangePickerComponent
            state={state}
            setState={setState}
            applyDateRange={() => applyDateRange()}
          />
        </div>
      </div>
      <Table
        responsive
        dir={box_head_dir}
        className="reports-table text-nowrap text-center"
      >
        <thead>
          <tr>
            <th>{_("panel.productTitle")}</th>
            <th>{_("panel.totalorders")}</th>
            <th>{_("panel.totalAlreadyShippedorders")}</th>
            <th>{_("panel.totalSoldOrders")}</th>
            <th>{_("panel.Soldquantity")}</th>
            <th>{_("home.commission")}</th>
          </tr>
        </thead>
        <tbody>
          {reportDetailedProducts.length
            ? reportDetailedProducts.map((product) => {
                return (
                  <tr>
                    <td>
                      <span className="fw-bold"> #{product["p_id"]}</span>{" "}
                      <br /> {product["sku"]} <br /> {product["title"]}
                    </td>
                    <td>{product["total_orders"].toLocaleString()} </td>
                    <td>{product["total_shipped_orders"].toLocaleString()} </td>
                    <td>
                      {product["total_sold_orders"]} (
                      {product["total_shipped_orders"]
                        ? Math.floor(
                            (product["total_sold_orders"] /
                              Number(product["total_shipped_orders"])) *
                              100
                          )
                        : 0}
                      %)
                    </td>
                    <td>{product["sold_quantity"]} </td>
                    <td>
                      {currancyConvertor(
                        (
                          (product["marketer_full_commission"] * 100) /
                          100
                        ).toLocaleString()
                      )}{" "}
                      {alt_name}{" "}
                    </td>
                  </tr>
                );
              })
            : ""}
        </tbody>
      </Table>
    </div>
  );
};

export default ReportsTable;
