import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const OffersWholesale = ({ slug, lang }) => {
  const [_] = useTranslation("global");
  const role = localStorage.getItem("role");
  return (
    <li>
      {role == "affiliate" ? (
        <NavLink to={`/${slug}/${lang}/panel/products/all-wholesale`}>
          <p className="d-inline ">{_("panel.offers_wholesale")}</p>
        </NavLink>
      ) : (
        <NavLink to={`/${slug}/${lang}/panel/products/wholesale/`}>
          <p className="d-inline ">{_("panel.offers_wholesale")}</p>
        </NavLink>
      )}
    </li>
  );
};

export default OffersWholesale;
