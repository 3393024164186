import React, { useEffect, useRef, useState } from "react";
import IconsGen from "../../../icons_generator/IconsGen";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Loader from "../../../loader/Loader";
import AxiosController from "../../../authentication/AxiosController";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import { currancyConvertor } from "../../../../utils/currancyConvertor";
import { Helmet } from "react-helmet";
import DateRangePickerComponent from "../../../date/DateRangePickerComponent";
import { format } from "date-fns";
const control_panel_stats_wallet = (
  slug,
  lang,
  token,
  handleStatsWallet,
  handleAltName,
  handleLoader,
  dates = {}
) => {
  AxiosController.get(`api/${slug}/${lang}/get-control-panel-stats-wallet/`, {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      start: dates["start"] ? dates["start"] : "",
      end: dates["end"] ? dates["end"] : "",
    },
  })
    .then((res) => {
      handleStatsWallet(res.data); 
      handleAltName(res.data.alt_name.split(" ")[0]);
      handleLoader(false);
    })
    .catch((e) => {});
};

const get_Earning = (
  slug,
  lang,
  token,  
  handleLoader,
  dates = {}
) => {
  AxiosController.get(`api/${slug}/${lang}/reports/system-earnings`, {
    headers: { Authorization: `Bearer ${token}` },
   
  })
    .then((res) => { 
       handleLoader(false);
    })
    .catch((e) => {});
}; 

const Earnings = () => {
  const { slug, lang } = useParams();

  const [_] = useTranslation("global");
  const box_head_dir = lang === "en" ? "ltr" : "rtl";
  const token = localStorage.getItem("token");
  const [loading, handleLoader] = useState(false);
  const [statsWallet, handleStatsWallet] = useState({});
  const [alt_name, handleAltName] = useState("");

 
  const [state, setState] = useState({
    selection: {
      startDate: new Date(),
      endDate:new Date(),
      key: 'selection'
    }
  });
  const applyDateRange = () => {
    const { startDate, endDate } = state.selection; 
    const formattedStartDate = new Date(startDate.setHours(0, 0, 0, 0));
    const formattedEndDate = new Date(endDate.setHours(0, 0, 0, 0));
  
    // Format dates to YYYY-MM-DD for API
    const apiStartDate = format(formattedStartDate, 'yyyy-MM-dd');
    const apiEndDate = format(formattedEndDate, 'yyyy-MM-dd');
    handleLoader(true);
    control_panel_stats_wallet(
      slug,
      lang,
      token,
      handleStatsWallet,
      handleAltName,
      handleLoader,
      { start: apiStartDate, end: apiEndDate }
    );
  };

  useEffect(() => {
    // handleLoader(true);
    get_Earning(  slug,
      lang,
      token,) 
    control_panel_stats_wallet(
      slug,
      lang,
      token,
      handleStatsWallet,
      handleAltName,
      // handleLoader
    );
  }, [slug, lang, token, handleStatsWallet, handleAltName]);

  return (
    <div className="flex-grow-1 stats-component">
                    <Helmet>
   <title>{_("panel.earningsreports")}</title> 
</Helmet>
      <div className="reports-container my-3 d-flex flex-column gap-2 px-2 position-relative ">
        <Loader active={loading} />
        <div className="reports-header d-flex flex-column flex-md-row py-2 justify-content-between flex-wrap">
          <h3 style={{ color: "rgba(23, 43, 77, 1)" }}>
            {_("panel.earningsreports")}
          </h3>
          <div className="interval d-flex flex-column gap-2 flex-md-row-reverse">
          <DateRangePickerComponent 
                state={state} 
                setState={setState}    
                applyDateRange={()=>applyDateRange()}
              />
          </div>
        </div>
        <div
          className="boxes-container d-flex flex-wrap gap-2"
          // style={{ direction: "rtl" }}
          dir={box_head_dir}
        >
          <div className="report-box col-12 col-lg-3 d-flex flex-column gap-2 flex-grow-1 align-items-center py-3">
            <div className="top d-flex align-items-center gap-2">
              <IconsGen icon_name={"dollarSign"} />
              <p className="flex-grow-1" style={{}}>
                {_("panel.totalearnings")}
              </p>
            </div>
            <div className="middle fs-2">
              <p>
                {statsWallet["total_earnings"]
                  ? currancyConvertor(statsWallet["total_earnings"])
                  : 0}{" "}
                {alt_name}
              </p>
            </div>
            {/* <div className="footer d-flex gap-2 flex-row-reverse align-items-center">
              <IconsGen icon_name={"bluewaves"} />

              <p>+5%</p>
            </div> */}
          </div>
          <div className="report-box col-12 col-lg-3 d-flex flex-column gap-2 flex-grow-1 align-items-center py-3">
            <div className="top d-flex align-items-center gap-2">
              <IconsGen icon_name={"bluecheck"} />
              <p className="flex-grow-1" style={{}}>
                {_("panel.confirmedearnings")}
              </p>
            </div>
            <div className="middle fs-2">
              <p>
                {statsWallet["total_confirmed_money"]
                  ? currancyConvertor(
                      statsWallet["total_confirmed_money"][0]["stats"]
                    )
                  : 0}{" "}
                {alt_name}
              </p>
            </div>
            {/* <div className="footer d-flex gap-2 flex-row-reverse align-items-center">
              <IconsGen icon_name={"bluewaves"} />

              <p>+5%</p>
            </div> */}
          </div>
          <div className="report-box col-12 col-lg-3 d-flex flex-column gap-2 flex-grow-1 align-items-center py-3">
            <div className="top d-flex align-items-center gap-2">
              <IconsGen icon_name={"bluetrans"} />

              <p className="flex-grow-1" style={{}}>
                {_("panel.withdrawableearnings")}
              </p>
            </div>
            <div className="middle fs-2">
              <p>
                {statsWallet["balance"]
                  ? currancyConvertor(statsWallet["balance"]["total"])
                  : 0}{" "}
                {alt_name}
              </p>
            </div>
            {/* <div className="footer d-flex gap-2 flex-row-reverse align-items-center">
              <IconsGen icon_name={"bluewaves"} />

              <p>+5%</p>
            </div> */}
          </div>
          <div className="report-box col-12 col-lg-3 d-flex flex-column gap-2 flex-grow-1 align-items-center py-3 orangify">
            <div className="top d-flex align-items-center gap-2">
              <IconsGen icon_name={"orangdash"} />

              <p className="flex-grow-1" style={{}}>
                {_("panel.expectedearnings")}
              </p>
            </div>
            <div className="middle fs-2">
              <p>
                {statsWallet["total_expected_money"]
                  ? currancyConvertor(
                      statsWallet["total_expected_money"][0]["stats"]
                    )
                  : 0}{" "}
                {alt_name}
              </p>
            </div>

            {/* <div className="footer d-flex gap-2 flex-row-reverse align-items-center">
              <IconsGen icon_name={"yellowwaves"} />

              <p>+5%</p>
            </div> */}
          </div>
          <div className="report-box col-12 col-lg-3 d-flex flex-column gap-2 flex-grow-1 align-items-center py-3 orangify">
            <div className="top d-flex align-items-center gap-2">
              <IconsGen icon_name={"orangdash"} />

              <p className="flex-grow-1" style={{}}>
                {_("panel.withdrawn")}
              </p>
            </div>
            <div className="middle fs-2">
              <p>
                {statsWallet["total_withdrawn_money"]
                  ? currancyConvertor(
                      statsWallet["total_withdrawn_money"][0]["stats"]
                    )
                  : 0}

                {alt_name}
              </p>
            </div>

            {/* <div className="footer d-flex gap-2 flex-row-reverse align-items-center">
              <IconsGen icon_name={"yellowwaves"} />

              <p>+5%</p>
            </div> */}
          </div>
          <div
            className="report-box col-5 col-md-3 d-flex flex-column gap-2 flex-grow-1 align-items-center py-3 orangify"
            style={{ background: "none" }}
          ></div>
          <div
            className="report-box col-5 col-md-3 d-flex flex-column gap-2 flex-grow-1 align-items-center py-3 orangify"
            style={{ background: "none" }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Earnings;

 