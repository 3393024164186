import React from "react";
import IconsGen from "./icons_generator/IconsGen";

// A simple button component for downloading a single XLSX file
const DownloadXLSXButton = ({ fileName, buttonLabel }) => {
  const handleDownload = () => {
    // Construct the file URL based on the public folder
    const fileUrl = `/${fileName}`;

    // Create a temporary anchor element to trigger the download
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };
  // .excel-download-btn {
//   background-color: #217346; 
//   color: #ffffff; 
//   padding: 10px 20px; 
//   border: none; 
//   border-radius: 5px; 
//   cursor: pointer;
//   font-size: 16px;
//   display: flex; 
//   align-items: center; 
//   gap: 10px; 
//   box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2); 
//   transition: background-color 0.3s ease; 
// }
  const style = `
.excel-download-btn:hover {
  background-color: #1b5e2e; 
}

.excel-download-btn:active {
  background-color: #144d22; 
}
`;
  return (
    <>
      <style>{style}</style>
      <button
        className="excel-download-btn"
        style={{
          backgroundColor: "transparent",
          color: "#000",
          padding: "7px 16px",
          border: "1px solid #217346",
          borderRadius: "5px",
          cursor: "pointer",
          fontSize: "16px", 
          display: "flex",
          alignItems: "center",
          gap: "10px",
        //   boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.2)",
        }}
        onClick={handleDownload}
      >
       <IconsGen icon_name={"excel"} />
        تحميل مثال 
        {/* {buttonLabel} */}
      </button>
    </>
  );
};

export default DownloadXLSXButton;
