import React from "react";
import IconsGen from "../../../icons_generator/IconsGen";
import { useTranslation } from "react-i18next";
import { currancyConvertor } from "../../../../utils/currancyConvertor";

const BlueRow = ({ values, slug, image, titles = [] }) => {
  const [_] = useTranslation("global");
  return (
    <div className="gap-2  justify-content-center d-flex flex-wrap">
      <div className="money-card flex-grow-1 py-4 col-12 col-md-3">
        <div className="money-card-container d-flex flex-column align-items-center">
          <div className="title d-flex flex-column flex-md-row-reverse align-items-center gap-2">
            <IconsGen icon_name={"bluemoney"} />
            <p>{titles[0]}</p>
          </div>
          <div className="money">
            <p>{currancyConvertor(values[0])}</p>
          </div>
          <div className="currency d-flex flex-column flex-md-row-reverse align-items-center gap-2">
            <img src={image} alt="" />
            <p>{slug}</p>
          </div>
        </div>
      </div>
      <div className="money-card flex-grow-1 py-4 col-12 col-md-3">
        <div className="money-card-container d-flex flex-column align-items-center">
          <div className="title d-flex flex-column flex-md-row-reverse align-items-center gap-2">
            <IconsGen icon_name={"bluemoney"} />
            <p>{titles[1]}</p>
          </div>
          <div className="money">
            <p>{currancyConvertor(values[1])}</p>
          </div>
          <div className="currency d-flex flex-column flex-md-row-reverse align-items-center gap-2">
            <img src={image} alt="" />
            <p>{slug}</p>
          </div>
        </div>
      </div>
      <div className="money-card flex-grow-1 py-4 col-12 col-md-3">
        <div className="money-card-container d-flex flex-column align-items-center">
          <div className="title d-flex flex-column flex-md-row-reverse align-items-center gap-2">
            <IconsGen icon_name={"bluemoney"} />

            <p>{titles[2]}</p>
          </div>
          <div className="money">
            <p>{currancyConvertor(values[2])}</p>
          </div>
          <div className="currency d-flex flex-column flex-md-row-reverse align-items-center gap-2">
            <img src={image} alt="" />
            <p>{slug}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlueRow;
