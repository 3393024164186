import React, { useState } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { Line, Bar, Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Tooltip,
  Legend,
  Title,
} from "chart.js";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { format } from "date-fns";
import DateRangePickerComponent from "../../date/DateRangePickerComponent";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Tooltip,
  Legend,
  Title
);

const LandingPageAnalysis = () => {
  const { lang } = useParams();
  const [_] = useTranslation("global");
  const [loading, handleLoader] = useState(false);

  const [state, setState] = useState({
    selection: {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  });

  const landing_analysis = () => {};
  const applyDateRange = () => {
    const { startDate, endDate } = state.selection;
    const formattedStartDate = new Date(startDate.setHours(0, 0, 0, 0));
    const formattedEndDate = new Date(endDate.setHours(0, 0, 0, 0));

    // Format dates to YYYY-MM-DD for API
    const apiStartDate = format(formattedStartDate, "yyyy-MM-dd");
    const apiEndDate = format(formattedEndDate, "yyyy-MM-dd");
    handleLoader(true);
    landing_analysis({ start: apiStartDate, end: apiEndDate });
  };

  // Sample data for charts
  const lineData = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May"],
    datasets: [
      {
        label: "Sales",
        data: [200, 400, 600, 800, 1000],
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        fill: true,
      },
    ],
  };

  const barData = {
    labels: ["Desktop", "Mobile"],
    datasets: [
      {
        label: "Device Usage",
        data: [50, 50],
        backgroundColor: ["rgba(54, 162, 235, 0.6)", "rgba(255, 99, 132, 0.6)"],
      },
    ],
  };

  const pieData = {
    labels: ["Returning", "First Time"],
    datasets: [
      {
        label: "Customer Rate",
        data: [4.25, 95.75],
        backgroundColor: ["rgba(54, 162, 235, 0.6)", "rgba(255, 99, 132, 0.6)"],
      },
    ],
  };

  return (
    <div
      dir={lang === "en" ? "ltr" : "rtl"}
      className="d-flex flex-column flex-lg-column my-2 gap-3 position-relative"
      style={{ width: "100%" }}
    >
      <Helmet>
        <title>{_("panel.landing_page_analysis")}</title>
      </Helmet>
      <div
        className={`header d-flex ${
          lang === "en" ? "flex-row" : "flex-row"
        } gap-3 align-items-center justify-content-between`}
      >
        <h3 className="fs-1" style={{ color: "rgba(89, 198, 201, 1)" }}>
          {_("panel.landing_page_analysis")}
        </h3>
        <DateRangePickerComponent
          state={state}
          setState={setState}
          applyDateRange={() => applyDateRange()}
        />
      </div>
      <Row>
        <Col className="gy-3" lg={4}>
          <Card>
            <Card.Body>
              <Card.Title>{_("panel.total_sales")}</Card.Title>
              <h2>EGP1,236.00</h2>
              <div
                style={{ height: "150px" }}
                className="d-flex justify-content-center"
              >
                <Line
                  data={lineData}
                  options={{
                    responsive: true,
                    plugins: { legend: { display: false } },
                  }}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col className="gy-3" lg={4}>
          <Card>
            <Card.Body>
              <Card.Title>{_("panel.total_orders")}</Card.Title>
              <h2>4</h2>
              <div
                style={{ height: "150px" }}
                className="d-flex justify-content-center"
              >
                <Bar
                  data={barData}
                  options={{
                    responsive: true,
                    plugins: { legend: { display: false } },
                  }}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col className="gy-3" lg={4}>
          <Card>
            <Card.Body>
              <Card.Title>{_("panel.returning_customer_rate")}</Card.Title>
              <h2>4.25</h2>
              <div
                style={{ height: "150px" }}
                className="d-flex justify-content-center"
              >
                <Pie
                  data={pieData}
                  options={{
                    responsive: true,
                    plugins: { legend: { position: "bottom" } },
                  }}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Additional Rows with Translations */}
      <Row className="mt-4">
        <Col className="gy-3" lg={4}>
          <Card>
            <Card.Body>
              <Card.Title>{_("panel.total_visits")}</Card.Title>
              <h2>17</h2>
              <div
                style={{ height: "150px" }}
                className="d-flex justify-content-center"
              >
                <Line
                  data={lineData}
                  options={{
                    responsive: true,
                    plugins: { legend: { display: false } },
                  }}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col className="gy-3" lg={4}>
          <Card>
            <Card.Body>
              <Card.Title>{_("panel.average_order_value")}</Card.Title>
              <h2>EGP309.00</h2>
              <div
                style={{ height: "150px" }}
                className="d-flex justify-content-center"
              >
                <Line
                  data={lineData}
                  options={{
                    responsive: true,
                    plugins: { legend: { display: false } },
                  }}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col className="gy-3" lg={4}>
          <Card>
            <Card.Body>
              <Card.Title>{_("panel.top_device_type")}</Card.Title>
              <p>{_("panel.desktop")} - 50%</p>
              <p>{_("panel.phone")} - 50%</p>
              <div
                style={{ height: "150px" }}
                className="d-flex justify-content-center"
              >
                <Bar
                  data={barData}
                  options={{
                    responsive: true,
                    plugins: { legend: { display: false } },
                  }}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col lg={6} className="gy-3">
          <Card>
            <Card.Body>
              <Card.Title>{_("panel.sales_by_traffic_source")}</Card.Title>
              <p>www.matjry.shop - 75%</p>
              <p>business.facebook.com - 25%</p>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={6} className="gy-3">
          <Card>
            <Card.Body>
              <Card.Title>{_("panel.top_products")}</Card.Title>
              <p>{_("panel.deleted")} - EGP946 (76.54%)</p>
              <p>{_("panel.deleted")} - EGP290 (23.46%)</p>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col lg={6} className="d-flex align-items-stretch gy-3">
          <Card className="w-100">
            <Card.Body>
              <Card.Title>{_("panel.top_selling_funnels")}</Card.Title>
              <p>{_("panel.no_items_available")}</p>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={6} className="d-flex align-items-stretch gy-3">
          <Card className="w-100">
            <Card.Body>
              <Card.Title>{_("panel.conversion_rates")}</Card.Title>
              <p>{_("panel.leads")} - 0%</p>
              <p>{_("panel.reached_checkout")} - 17.65%</p>
              <p>{_("panel.checkout")} - 0%</p>
              <p>{_("panel.purchased")} - 23.53%</p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default LandingPageAnalysis;
