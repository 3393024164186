import React, { useEffect, useState } from "react";
import { Stepper } from "@mantine/core";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import easyIcons from "../../../imgs/easy-icon.png";
import ecombo from "../../../imgs/Logo_Ecombo 1.png";
import link from "../../../imgs/link.png";
import linked from "../../../imgs/linked.jpg";
import Loader from "../../loader/Loader";
import AxiosController from "../../authentication/AxiosController";
const EasyOrders = () => {
  const { slug, lang } = useParams();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  const [_] = useTranslation("global");
  const token = localStorage.getItem("token");

  const [active, setActive] = useState(0);
  const [loading, setLoading] = useState(1);
  const [userId, setUserId] = useState(1);

  const getUserData = () => {
    setLoading(true);
    AxiosController.get(`api/${lang}/auth/userdata`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setUserId(res.data["id"]);
        setLoading(false);
      })
      .catch(() => {});
  };

  const hasKey = () => {
    AxiosController.get(`api/${slug}/${lang}/has-key/`, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        type: "easy_order",
      },
    })
      .then((res) => {
        setActive(urlParams.get("step") ? urlParams.get("step") - 1 : 2);
      })
      .catch(() => {});
  };
  const retrieveAreas = () => {
    setLoading(true);
    AxiosController.post(
      `api/${slug}/${lang}/easy-orders/shipping`,
      {
        currency_id: slug == "ae" ? 2 : 3,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        setActive(2);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getUserData();
    hasKey();
    setActive(urlParams.get("step") ? urlParams.get("step") - 1 : 0);
  }, []);
  return (
    <div className="w-100 pt-3 position-relative">
      <Loader active={loading} />
      <div className="d-flex align-items-center justify-content-center gap-4 flex-wrap">
        <img src={easyIcons} width={"80px"} />
        <img src={ecombo} width={"180px"} />
      </div>
      <div>
        <Stepper active={active} onStepClick={setActive} color="#59C6C9">
          <Stepper.Step
            label={_("panel.First step")}
            description={_("panel.linkAccount")}
            disabled
          >
            <div className="d-flex flex-column align-items-center gap-3">
              <div>
                <p className="fs-5">
                  {slug == "ae" ? _("panel.inAE") : _("panel.inSA")}{" "}
                  {_("panel.First step")} {_("panel.easyOrdersLink")}{" "}
                  <a
                    href={`http://seller.easy-orders.net/#/install-app?app_description=%D8%B1%D8%A8%D8%B7%20%D8%A7%D9%8A%D9%83%D9%88%D9%85%D8%A8%D9%88%20%D8%A8%D8%A5%D9%8A%D8%B2%D9%8A%20%D8%A7%D9%88%D8%B1%D8%AF%D8%B1%D8%B2&app_icon=https%3A%2F%2Fi.ibb.co%2FcrPmftv%2FLogo-Ecombo-1.png&app_name=Ecombo&callback_url=https://api.ecombo.co/api/easy-orders/auth/${slug}/${userId}&orders_webhook=https://api.ecombo.co/api/${slug}/${lang}/easy-orders/webhook/${userId}&permissions=shipping_areas,products:create&redirect_url=https://ecombo.co/${slug}/${lang}/panel/outside-integrations/easy-orders?step=2`}
                    style={{ color: "rgb(243, 168, 55)" }}
                  >
                    {_("panel.Click here")}
                  </a>
                </p>
              </div>
              <div>
                <img src={link} width={"600px"} className="w-100" />
              </div>
            </div>
          </Stepper.Step>
          <Stepper.Step
            label={_("panel.Second step")}
            description={_("panel.RetrieveMaps")}
            disabled
          >
            <div className="d-flex align-items-center flex-column w-100">
              <p className="flex-grow-1 w-75 fs-5 w-100">
                {_("panel.Second step")} {_("panel.easyOrdersLink2")}
              </p>
            </div>
            <div className="w-100 d-flex justify-content-center align-items-center mt-3">
              <button
                className="btn w-25"
                style={{ backgroundColor: "rgb(243, 168, 55)", color: "white" }}
                onClick={retrieveAreas}
              >
                {_("panel.retrieve")}
              </button>
            </div>
          </Stepper.Step>

          <Stepper.Completed>
            <p className="flex-grow-1 w-75 fs-5 w-100 text-center">{_("panel.linked")}</p>
            <div
              className="img d-flex justify-content-center flex-column w-100 align-items-center gap-5"
              style={{ color: "rgb(243, 168, 55)" }}
            >
              <img src={linked} width={"500px"} />
              <h3>{_("panel.easyOrdersLinked")}</h3>
              <button
                className="btn w-25"
                style={{ backgroundColor: "rgb(243, 168, 55)", color: "white" }}
                onClick={() => {
                  setActive(0)
                }}
              >
                {_("panel.newLink")}
              </button>
            </div>
          </Stepper.Completed>
        </Stepper>
      </div>
    </div>
  );
};

export default EasyOrders;
