import React from "react";
import { useTranslation } from "react-i18next";

const StoreTableLabels = () => {
  const [_] = useTranslation("global");
  const role = localStorage.getItem("role");

  const roles_labels = {
    affiliate: [
      _("panel.storeDomain"),
      _("panel.visit"),
      _("panel.favicon"),
      _("panel.icon"),
      _("panel.edit"),
      _("panel.delete"),
    ],
  };

  return (
    <tr>
      {roles_labels[role].map((label) => {
        return <th>{label}</th>;
      })}
    </tr>
  );
};

export default StoreTableLabels;
