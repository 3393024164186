import React, { useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import AxiosController from "../../authentication/AxiosController";
import { useTranslation } from "react-i18next";
import Loader from "../../loader/Loader";
import { Helmet } from "react-helmet";
import CreateLandingPage from "../../modals/CreateLandingPage";

const fetchThemes = (slug, lang, token, handleThemeData, handleLoader) => {
  AxiosController.get(`apiv2/${slug}/${lang}/builder/themes`, {
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      handleThemeData(res.data);
      handleLoader(false);
    })
    .catch((e) => {
      handleLoader(false);
    });
};

const CreateLandingpage = () => {
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");
  const [_] = useTranslation("global");
  const [themeData, handleThemeData] = useState([]);
  const [loading, handleLoader] = useState(false);
  const [themeId, setThemeId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [hasStore, setHasStore] = useState(false);

  const havingStore = () => {
    handleLoader(true);
    AxiosController.get(`apiv2/${slug}/${lang}/builder/my-stores`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        if (res?.data?.length) {
          setHasStore(true);
        }
        handleLoader(false);
      })
      .catch((e) => {
        handleLoader(false);
      });
  }

  useEffect(() => {
    handleLoader(true);
    fetchThemes(slug, lang, token, handleThemeData, handleLoader);
    havingStore();
  }, [token, slug, lang, handleThemeData, handleLoader]);

  return (
    <Container className="position-relative">
      <Loader active={loading} />
      <Helmet>
        <title>{_("panel.createLandpage")}</title>
      </Helmet>
      <CreateLandingPage
        setShowModal={setShowModal}
        showModal={showModal}
        themeId={themeId}
      />
      <div className="add-product-container d-flex flex-column gap-2 my-3 px-4">
        <div className="add-header" dir={lang === "en" ? "ltr" : "rtl"}>
          <h2 className="mb-3" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.createLandpage")}
          </h2>
        </div>
        <div
          className="d-flex gap-5 flex-column-reverse flex-md-row"
          dir={lang === "en" ? "ltr" : "rtl"}
        >
          <div className="row py-4">
            {themeData.length && hasStore ? (
              themeData.map((theme) => (
                <div className="col-xl-3 col-lg-4 gy-3 col-12 mb-3  " key={theme.id}>
                  <div className="card d-flex flex-column h-100">
                    <img
                      src={theme.thumbnail}
                      alt={theme.name}
                      className="card-img-top"
                      style={{ width: "100%", height: "auto" }}
                    />
                    <div className="card-body flex-grow-1 d-flex flex-column">
                      <h5 className="card-title">{theme.name}</h5>
                      <button
                        style={{ backgroundColor: "rgba(89, 198, 201, 1)" }}
                        className="btn border-0 w-100 mt-auto mb-2 py-2"
                        onClick={() => {
                          setShowModal(true);
                          setThemeId(theme.id);
                        }}
                      >
                        {_("panel.create")}
                      </button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="w-100">
                <h3 className="ecombo-secondary text-center">{_('panel.createAStorePlease')}</h3>
              </div>
            )}
          </div>

        </div>
      </div>
    </Container>
  );
};

export default CreateLandingpage;
