import React, { useState } from "react";
import StatusLogsBox from "./StatusLogsBox";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import AxiosController from "../../authentication/AxiosController";
import IconsGen from "../../icons_generator/IconsGen";
import { notifyError, notifySuccess } from "../../toastify/toastify";
import ConfirmModal from "../../modals/ConfirmModal";

const StatusLogs = ({ status_logs_list, get_order_handler }) => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const token = localStorage.getItem("token");
  const [statusLogsIdToDelete, setStatusLogsIdToDelete] = useState(null);
  const employee_type = localStorage.getItem("employee_type");
  const role = localStorage.getItem("role");
  const [statusId, setStatusId] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);





  const handleCloseModal = () => {
    setShowConfirmModal(false);
    setStatusLogsIdToDelete(null);
  };

  const handleConfirmDelete = () => {

    AxiosController.delete(
      `api/${slug}/${lang}/orders/${statusLogsIdToDelete}/status`,
      {
        headers: { Authorization: `Bearer ${token}` },
        data: { status_id: statusId },
      }
    )
      .then((res) => {
        get_order_handler()
        notifySuccess(_("panel.delete_successful"));

        handleCloseModal();
      })
      .catch((e) => {
        notifyError(e.message);
        handleCloseModal();
      });
  };

  const handleShowModal = (order_id, status_id) => {
    setShowConfirmModal(true);
    setStatusId(status_id);
    setStatusLogsIdToDelete(order_id);
  };



  return (
    <>
      <ConfirmModal
        showModal={showConfirmModal}
        setShowModal={setShowConfirmModal}
        action={handleConfirmDelete}
        modalBody={`deleteSure`}
        modalHeader="deleteProduct"
        confirmButton="confirm"
      />

      <div className="d-flex flex-column gap-3">
        <h3 className="mb-4">{_("panel.statusLogs")}</h3>
        {status_logs_list.map((log) => {
          return (
            <div key={log["order_id"]}>
              {role === "admin" || (employee_type === "operation employee") ? (
                <div
                  className="align-middle px-3 d-flex justify-content-end "
                  style={{ marginBottom: "-25px" }}
                  onClick={() => handleShowModal(log["order_id"], log["id"])}
                >
                  {<IconsGen icon_name={"delete"} />}
                </div>
              ) : ""}

              <StatusLogsBox
                content={_(`panel.${log.status}`)}
                date={log.created_at_formatted}
                user_id={log.user_id}
                addedBy={log.first_name + " " + log.last_name}
                photo={log.status}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default StatusLogs;
